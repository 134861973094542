import React, {useState, useEffect} from "react"
import { makeStyles } from "@material-ui/core/styles";
import {Grid, TextField, IconButton, Tooltip, MenuItem, Button} from '@material-ui/core'
import PropTypes from "prop-types"
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {Close, AddCircle} from "@material-ui/icons";
import Checkbox from '@material-ui/core/Checkbox';

import styles from 'assets/jss/material-dashboard-react/components/tasksStyle.js';

const useStyles = makeStyles(styles);

export default function TVideo(props) {
  const {getJson,noQuestions, answers } = props;
  const classes = useStyles();
  const [nextQuestions, setNextQuestions] = useState([])
  const [specify, setSpecify] = useState(false)
  const categoryId = 1
  const type = 'TVideo'

  useEffect(() => {
    setNextQuestions(noQuestions)
  },[noQuestions])

  useEffect(() => {
    if(answers.length > 0){
      answers.map(item => {
        if(item.specify) setSpecify(true)
        return item;
      });
    }else{
      getJson(asJSON([{option: 'Opción 1'}]));
    }
  }, [answers, getJson])

  function asJSON(newAnswers){
    newAnswers.map((item, index) => {
      item['inciso'] =  (index+10).toString(36)
      item['selected'] = false
      return item
    });
    return {categoryId,type,answers: newAnswers}
  }

  function handleAddOption(){
    let newAnswers = [...answers]
    if(specify){
      newAnswers.pop();
      newAnswers.push({option: 'Opción '+(newAnswers.length+1)});
      newAnswers.push({option: 'Especificar...', specify: true});
    }else{
      newAnswers.push({option: 'Opción '+(newAnswers.length+1)});
    }
    getJson(asJSON(newAnswers));
  }
  function handleAddSpecify(){
    setSpecify(true)
    let newAnswers = [...answers,{option: 'Otro...', specify: true}];
    getJson(asJSON(newAnswers));
  }
  function handleRemoveOption(index){
    let newAnswers = [...answers];
    newAnswers.splice(index,1);
    getJson(asJSON(newAnswers));
  }

  function handleChangeOption(optionText, index){
    let newAnswers = [...answers];
    if(optionText.trim().length > 0)
      newAnswers[index].option = optionText
    else
      newAnswers[index].option = 'Opción '+(index+1)
    getJson(asJSON(newAnswers));
  }

  function handleChangeNext(index,value){
    let newAnswers = [...answers];
    newAnswers[index]['next'] = value
    getJson(asJSON(newAnswers));
  }

  function handleRemoveNext(index){
    let newAnswers = [...answers];
    if(newAnswers[index].next){
      delete newAnswers[index]['next']
      getJson(asJSON(newAnswers));
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={3} justify="flex-end">
        <Grid item md={4}>
          Saltar a
        </Grid>
      </Grid>
      {answers.map((option, index) => {
        return (
          <Grid key={index} item container spacing={3}>
            <Grid item container md={8} alignItems="center">
              <Grid item md={1}>
                <RadioButtonUncheckedIcon />
              </Grid>
              <Grid item md={10}>
                {option.specify 
                  ? (<TextField fullWidth
                      disabled defaultValue={option.option}/>)
                  : (<TextField fullWidth  
                      defaultValue={option.option}
                      InputProps={{onBlur: (event) => handleChangeOption(event.target.value, index) }} />)
                }
              </Grid>
              <Grid item md={1}>
                {answers.length > 1 && (
                  <Tooltip
                    title="Eliminar opción"
                    placement="top"
                    classes={{tooltip:classes.tooltip}}>
                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={()=> handleRemoveOption(index) }>
                      <Close className={classes.tableActionButtonIcon + " " + classes.close}/>
                    </IconButton>
                  </Tooltip>
                  )
                }
              </Grid>
            </Grid>
            <Grid item md={4}>
            {nextQuestions.length > 0 && (
              <Grid container spacing={3}>
                <Grid item>
                  <Checkbox
                    checked={option.next ? true : false}
                    onChange={() => handleRemoveNext(index)}
                    color="primary" />
                </Grid>
                <Grid item md={5}>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    label="Pregunta"
                    value={option.next ? option.next : ''}
                    onChange={(event) => handleChangeNext(index,event.target.value)}
                    variant="outlined">
                    {nextQuestions.map((questionNumber,index) => (
                      <MenuItem key={index} value={questionNumber}>
                        {questionNumber}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
            </Grid>
          </Grid>
        )
      })}
      <Grid item md={12}>
        <Tooltip
          title="Agregar opción"
          placement="top"
          classes={{tooltip:classes.tooltip}}>
          <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => handleAddOption()}>
            <AddCircle className={classes.edit}/>
          </IconButton>
        </Tooltip>
        {!specify && (
          <React.Fragment>
            <span> ó  </span>
            <Tooltip
              title="Agregar opcion 'Especificar'"
              placement="top"
              classes={{tooltip:classes.tooltip}}>
              <Button 
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => handleAddSpecify()}>Especificar
              </Button>
            </Tooltip>
          </React.Fragment>
          )}
      </Grid>
    </Grid>
  );
}

TVideo.propTypes = {
  getJson: PropTypes.func,
};
