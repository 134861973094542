import Client from './Client'

export default class ResourceService {
  public static async list(page: number, page_size: number, companyId: number) {
    try {
      let url  = `/resources`
      if(page !== 0 && page_size !== 0)
        url = `/resources?page=${page}&page_size=${page_size}`
      if(companyId && companyId !== 0)
        url += `&companyId=${companyId}`
      const { data } = await Client.get(url)
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async listAll(campaignId: number, filter: string) {
    try {
      let url  = `/resources?`
      if (campaignId) url += `campaignId=${campaignId}`
      if (filter) url += `&resource_type=${filter}`
      const { data } = await Client.get(url)
      return data
    } catch (e) {
      throw e;
    }
  }
  
  public static async getResource(resourceId: number) {
    try {
      const { data } = await Client.get(`/resources/${resourceId}`)
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async createResource(name: string, file: File, companyId: number) {
    try {
      let formData = new FormData();
      formData.append('name', name);
      formData.append('resource', file);
      if(companyId) formData.append('companyId', companyId+'')
      const { data } = await Client.post('/resources', 
                                         formData, 
                                         { headers: {'Content-Type': 'multipart/form-data' }
                                       })
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async deleteResources(resources: Array<number>) {
    try {
      const { data } = await Client.post('/resources/remove',{resources} )
      return data
    } catch (e) {
      throw e;
    }
  }

}