import React, { useRef, useState, useEffect } from 'react';
import {Grid, List, ListItem, ListItemText, ListItemSecondaryAction,
  ListItemAvatar, Avatar, IconButton, Divider } from '@material-ui/core'
import {Attachment, DeleteForever} from '@material-ui/icons'
import CircularProgress from 'components/Progress/CircularProgress'

import Button from 'components/CustomButtons/Button'
import {notify} from 'components/Snackbar/Notifier'

import Client from 'Services/Client'

import './Dropzone.css';

const Dropzone = (props) => {
    const fileInputRef = useRef();
    const modalImageRef = useRef();
    const modalRef = useRef();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);
    const [progress, setProgress] = useState([]);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
      let filteredArr = selectedFiles.reduce((acc, current) => {
        const x = acc.find(item => item.name === current.name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setValidFiles([...filteredArr]);
    }, [selectedFiles]);

    const preventDefault = (e) => {
      e.preventDefault();
      // e.stopPropagation();
    }

    const dragOver = (e) => {
      preventDefault(e);
    }

    const dragEnter = (e) => {
      preventDefault(e);
    }

    const dragLeave = (e) => {
      preventDefault(e);
    }

    const fileDrop = (e) => {
      preventDefault(e);
      const files = e.dataTransfer.files;
      if (files.length) {
        handleFiles(files);
      }
    }

    const filesSelected = () => {
      if (fileInputRef.current.files.length) {
        handleFiles(fileInputRef.current.files);
      }
    }

    const fileInputClicked = () => {
      fileInputRef.current.click();
    }

    const handleFiles = (files) => {
      for(let i = 0; i < files.length; i++) {
        if (validateFile(files[i])) {
          setSelectedFiles(prevArray => [...prevArray, files[i]]);
          setProgress(prevProgress => [...prevProgress, 0])
        }else notify('danger', `${files[i].name} - Archivo no permitido o muy pesado.`)
      }
    }

    const validateFile = (file) => {
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon', 'video/mp4', 'video/ogg','video/webm'];
      if (validTypes.indexOf(file.type) === -1 || file.size > 50000000 )
        return false;
      if (file.type.includes('image') && file.size > 2000000)
        return false;
      return true;
    }

    const fileSize = (size) => {
        if (size === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const removeFile = (name) => {
        const index = validFiles.findIndex(e => e.name === name);
        const index2 = selectedFiles.findIndex(e => e.name === name);
        validFiles.splice(index, 1);
        selectedFiles.splice(index2, 1);
        setValidFiles([...validFiles]);
        setSelectedFiles([...selectedFiles]);
    }

    const openImageModal = (file) => {
        const reader = new FileReader();
        modalRef.current.style.display = "block";
        reader.readAsDataURL(file);
        reader.onload = function(e) {
          modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
        }
    }

    const closeModal = () => {
      modalRef.current.style.display = "none";
      modalImageRef.current.style.backgroundImage = 'none';
    }
    
    const uploadFiles = async () => {
      setUploading(true);
      for (let i = 0; i < validFiles.length; i++) {
        let formData = new FormData();
        formData.append('resource', validFiles[i]);
        formData.append('name', validFiles[i].name);
        formData.append('companyId', props.companyId);

        await Client.post('/resources', formData, {
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
            console.log('progreso:: ', uploadPercentage);
            progress[i] = uploadPercentage;
            setProgress([...progress]);
          },
        })
        .catch((e) => {
            console.log('error: ', e);
            if(e.error) notify('danger',e.error.toString());
            else notify('danger','Error al subir el archivo');
        })
      }
      
      props.onFinish(true);
      setUploading(false);
      setValidFiles([]);
      setSelectedFiles([]);
      setProgress([]);
      notify('success','Archivos subidos Correctamente.')
    }

    return (
      <>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12}>
          <div className="drop-box"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
          >
            <p>Arrastre y suelte archivos aquí o haga clic para seleccionar</p>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="list-items">
          <List dense={false}>
          {validFiles.map((data, i) => 
            <div key={i}>
              <ListItem>
                <ListItemAvatar onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}>
                  <Avatar>
                    <Attachment />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<p>{data.name}</p>}
                  secondary={fileSize(data.size)}
                />
                <ListItemSecondaryAction>
                  <IconButton disabled>
                    <CircularProgress value={progress[i]} />
                  </IconButton>
                  <IconButton onClick={() => removeFile(data.name)} disabled={uploading}>
                    <DeleteForever color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              </div>
          )}
            </List>
          </div>
          {validFiles.length ? <Button color="info" disabled={uploading} onClick={() => uploadFiles()}>Sub{uploading ? 'iendo' : 'ir'} Archivos</Button> : null}
        </Grid>
      </Grid>

        <div className="modal" ref={modalRef}>
          <div className="overlay"></div>
          <span className="close" onClick={(() => closeModal())}>X</span>
          <div className="modal-image" ref={modalImageRef}></div>
        </div>

      </>
    );
}

export default Dropzone;