const iconList = [
  'pencil',
  'address-book',
  'address-book-o',
  'address-card',
  'address-card-o',
  'adjust',
  'american-sign-language-interpreting',
  'anchor',
  'archive',
  'area-chart',
  'heart',
  'heartbeat',
  'balance-scale',
  'bar-chart',
  'book',
  'bookmark',
  'braille',
  'bullhorn',
  'calculator',
  'calendar',
  'calendar-check-o',
  'camera',
  'cart-plus',
  'cc-mastercard',
  'check',
  'cloud-upload',
  'cloud-download',
  'clipboard',
  'coffee',
  'cog',
  'cogs',
  'comment-o',
  'comment',
  'comments-o',
  'comments',
  'connectdevelop',
  'copy',
  'cubes',
  'desktop',
  'eye',
  'envelope-open-o',
  'envelope',
  'files-o',
  'flag',
  'file-word-o',
  'file-text',
  'graduation-cap',
  'home',
  'map',
  'money',
  'newspaper-o',
  'music',
  'photo',
  'university',
  'tv'
];
export default iconList;