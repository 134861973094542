/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import {Person, LibraryBooks, Apartment, Loyalty, MonetizationOn, AccountBox, PhotoLibrary } from "@material-ui/icons";

import Campaigns from 'pages/Campaigns/Campaigns'
import Users from 'pages/Users/Users'
import Companies from 'pages/Companies/Companies'
import CompanyInfo from 'pages/Companies/CompanyInfo'
import Coupons from 'pages/Coupons/Coupons'
import Payments from 'pages/Payments/Payments'
import Resources from 'pages/Resources/Resources'

export const Routes = [
    {
        path: "/campaigns",
        name: "Campañas",
        icon: LibraryBooks,
        component: Campaigns,
        layout: "/admin"
    },
    {
        path: "/users",
        name: "Usuarios",
        icon: Person,
        component: Users,
        layout: "/admin"
    },
     {
        path: "/companies",
        name: "Empresas",
        icon: Apartment,
        component: Companies,
        layout: "/admin",
        permission: "SUPER"
    },
     {
        path: "/details",
        name: "Cuenta",
        icon: AccountBox,
        component: CompanyInfo,
        layout: "/admin",
        permission: "ADMON"
    },
    {
      path: "/coupons",
      name: "Cupones",
      icon: Loyalty,
      component: Coupons,
      layout: "/admin",
      permission: "SUPER"
    },
    {
      path: "/payments",
      name: "Pagos",
      icon: MonetizationOn,
      component: Payments,
      layout: "/admin",
    },
    {
      path: "/resources",
      name: "Biblioteca",
      icon: PhotoLibrary,
      component: Resources,
      layout: "/admin",
    }

]


export default Routes;
