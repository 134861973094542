import React,{useEffect, useState} from "react"
//import PropTypes from "prop-types"
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {IconButton, Grid} from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

export default function TDate(props) {
  const { answers, required, register, errors} = props;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if(Date.parse(answers[0].selected)) 
      setSelectedDate(new Date(answers[0].selected))
  },[answers])

  const handleDateChange = (date) => {
    answers[0].selected = Date.parse(date) ?  date.toISOString() : '';
    setSelectedDate(date);
  }

  const handleReset = () => {
    answers[0].selected = '';
    setSelectedDate(null);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <Grid item xs={11}>
          <DatePicker 
            fullWidth
            inputVariant="outlined"
            format="dd/MM/yyyy"
            name="answer"
            value={selectedDate}
            onChange={handleDateChange}
            inputRef={register({ required: required })}
            error={errors.answer ? true : false}
            helperText={errors.answer ? 'Seleccione una fecha' : ''}
          />
        </Grid>
        <Grid item xs={1}>
          {answers[0].selected !== '' && (
            <IconButton color="secondary" component="span" onClick={handleReset}>
              <CancelPresentationIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
/*
TDate.propTypes = {
  setAnswer: PropTypes.func,
};
*/

