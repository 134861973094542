import Joi from '@hapi/joi';
const msg = {'string.empty': 'Este campo es obligatorio.'}

const attributes_create = {
  name: Joi.string().required().messages({...msg}),
}

const attributes_edit = {
  ...attributes_create
}
export const schema_create = Joi.object(attributes_create)
export const schema_edit = Joi.object(attributes_edit)