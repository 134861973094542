import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {useForm} from 'react-hook-form';
import { Typography, makeStyles, Container, Grid, Box,
        TextField, Button, Link, Avatar } from '@material-ui/core';
import {LockOutlined, AddAlert} from '@material-ui/icons';
import SnackbarContent from 'components/Snackbar/SnackbarContent'

import {LoginService} from "../../Services/LoginService";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://karimnot.com/">
                Karimnot Inc
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    }
}));

export default function SignIn() {
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState('')
    const history = useHistory();
    

    const onFormSubmit = (values: any) => {
      login(values.email, values.password);
    };

    async function login(email: string, password: string) {
      try {
        await LoginService.signIn(email.trim(), password)
        history.push("/admin");
      }catch (e) {
        if(e.status === 401) setError("El correo o la contraseña son incorrectos.");
        if(e.status === 403) setError("No esta autorizado para ingresar.");
      }
    }
    if(LoginService.isAuthenticated()){
        history.push("/admin");
    }
    return (
      <Container component="main" maxWidth="xs">
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box display="flex"  justifyContent="center" mt={8}>
              <Avatar className={classes.avatar}>
                <LockOutlined/>
              </Avatar>
            </Box>
            <Box display="flex"  justifyContent="center" mt={2}>
              <Typography component="h1" variant="h5">
                Iniciar sesión
              </Typography>
            </Box>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                { error &&
                  <SnackbarContent message={error} close color="danger" icon={AddAlert}/>
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="email"
                  label="Correo Electrónico"
                  autoComplete="email"
                  autoFocus
                  defaultValue=""
                  inputRef={register({ required: true })}
                />
                {errors.email && (
                  <div className="text-danger">
                    El correo electrónico es requerido.
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Contraseña"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  defaultValue=""
                  inputRef={register({ required: true })}
                />
                {errors.password && (
                  <div className="text-danger">
                    La contraseña es requerida.
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit">
                  Ingresar
                </Button>
              </Grid>
            
            </Grid>
          </form>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Link href="#" variant="body2">
              Olvidé mi contraseña
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2">
              Regístrate
            </Link>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Copyright/>
        </Box>
      </Container>
    );
}
