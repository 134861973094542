import React,{useEffect} from "react"
import {Grid, TextField} from '@material-ui/core'
import PropTypes from "prop-types"

export default function TLocation(props) {
  const {answers, getJson } = props;
  const categoryId = 1;
  const type = 'TLocation';

  useEffect(() => {
    if(answers.length <= 0){
      getJson({categoryId,type,answers: [{selected: ''}]})
    }
  }, [answers, getJson])

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <TextField
          disabled
          fullWidth
          type="text"
          label="latitude"
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          disabled
          fullWidth
          type="text"
          label="longitude"
        />
      </Grid>
    </Grid>
  );
}

TLocation.propTypes = {
  getJson: PropTypes.func,
};
