import Client from './Client'
import Survey from 'Class/Survey'
import { format } from 'date-fns'
const resource = '/surveys'

type Filter = {
  startDate: Date | null,
  endDate: Date | null,
  userId: number
}

export default class SurveyService {
  public static async getSurveys(page: number, size: number){
    try {
      const { data } = await Client.get(`${resource}`)
      return data
    } catch (e) {
      throw e
    }
  }
  public static async getSurvey(surveyId: number){
    try {
      const { data } = await Client.get(`${resource}/${surveyId}?edit=true`)
      return new Survey(data.data)
    } catch (e) {
      throw e
    }
  }
  public static async getSurveyByUuid(uuid: string){
    try {
      const { data } = await Client.get(`${resource}/survey_web/${uuid}`)
      return new Survey(data.data)
    } catch (e) {
      if(e.error) throw e.error
      if(e.detail) throw e.detail
      throw e
    }
  }
  public static async saveSurvey(survey: Survey, campaignId: number){
    try {
      if(survey.id === 0){
        survey.campaignId = campaignId
        const created  = await Client.post(`${resource}`, survey.asJSON())
        return created.data
      } else{
        const updated = await Client.put(`${resource}/${survey.id}`, survey.asJSON())
        return updated.data
      }
    } catch (e){
      throw e
    }
  }
  public static async getMembersEmail(surveyId: number){
    try {
      const { data } = await Client.get(`${resource}/${surveyId}/members_email`)
      return data
    } catch (e) {
      throw e
    }
  }

  public static async getSurveyEmails(surveyId: number, page: number, size: number){
    try {
      let url = `${resource}/${surveyId}/survey_emails?page=${page}&page_size=${size}`
      const { data }  = await Client.get(url)
      return data
    } catch (e){
      throw e
    }
  }

  public static async sendEmails(surveyId: number, message: string, emails: Array<string>){
    try {
      const emails_data = {message, emails}
      const send  = await Client.post(`${resource}/${surveyId}/send_emails`,emails_data)
      return send
    } catch (e){
      throw e
    }
  }
  public static async exportCSV(surveyId: number, filters: Filter = {startDate: null, endDate: null, userId: 0} ) {
    try {
      let url = this.createUrl(surveyId,'export_csv',filters)
      const response = await Client.get(url,{responseType: 'arraybuffer'})
      return response.data
    } catch (e) {
      throw e
    }
  }
  public static async download(surveyId: number, filters: Filter = {startDate: null, endDate: null, userId: 0}){
    try {
      let url = this.createUrl(surveyId,'download',filters)
      const response = await Client.get(url,{ responseType: 'arraybuffer' })
      return response.data
    } catch (e) {
      throw e
    }
  }
  public static async removeSurvey(surveyId: number){
    try {
      await Client.delete(`${resource}/${surveyId}`)
    } catch (e) {
      throw e
    }
  }

  private static createUrl(surveyId: number, destiny: string, filters: Filter) {
    let url = `${resource}/${surveyId}/${destiny}`
    let filterDate = false
    if(filters.startDate && filters.endDate) {
      url += `?start_date='${format(filters.startDate,'yyyy/MM/dd')}'&end_date='${format(filters.endDate,'yyyy/MM/dd')}'`
      filterDate = true
    }
    if(filters.userId) {
      url +=  `${filterDate ? '&' : '?'}user=${filters.userId}`
    }
    return url
  }
}