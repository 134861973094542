import React from "react"
import {TextField} from '@material-ui/core'
//import PropTypes from "prop-types"
import Joi from '@hapi/joi';

let phone_schema;

export default function TPhone(props) {
  const { answers, required, register, errors} = props;

  phone_schema = Joi.object({
    answer: required ? Joi.string().pattern(/^[0-9]{10}$/, 'numbers') : Joi.string().pattern(/^[0-9]{10}$/, 'numbers').allow(''),
  });

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      name="answer"
      defaultValue={answers[0].selected}
      placeholder="9876543210"
      error={errors.answer ? true : false}
      helperText={errors.answer ? 'Ingrese un numero a 10 digitos' : ''}
      inputRef={register}
    />
  );
}

export function phoneSchema() {
  return phone_schema;
}
/* pattern: /^[0-9]{10}$/  */
/*
TPhone.propTypes = {
  setAnswer: PropTypes.func,
};
*/

