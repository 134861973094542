import React,{ useState } from 'react'
import Button from 'components/CustomButtons/Button';
import TTextField from 'components/AnswerComponents/TTextField'
import TComment from 'components/AnswerComponents/TComment'
import TRadioButton from 'components/AnswerComponents/TRadioButton'
import TDate from 'components/AnswerComponents/TDate'
import TPhone,{phoneSchema} from 'components/AnswerComponents/TPhone'
import TEmail,{emailSchema} from 'components/AnswerComponents/TEmail'
import TCheckBox from 'components/AnswerComponents/TCheckBox'
import TImage from 'components/AnswerComponents/TImage'
import TNumber,{numberSchema} from 'components/AnswerComponents/TNumber'
import TLocation from 'components/AnswerComponents/TLocation'
import TVideo from 'components/AnswerComponents/TVideo'
import TImageOption from 'components/AnswerComponents/TImageOption'
import TSignature from 'components/AnswerComponents/TSignature'

import { Grid } from '@material-ui/core'

import {useForm} from 'react-hook-form';
import { joiResolver } from "@hookform/resolvers";

export default function AnswerFactory(props) {
  const {questionJson,handleBack, handleActive, handleSend, total} = props
  const questionsComponents = {
    TTextField,TRadioButton,TDate,TLocation,/*TPhoto,*/TSignature,
    TComment,TPhone,TEmail,TCheckBox,TVideo,TImageOption,/*TVideoText,*/TImage,
    TNumber,/*TCover,TOptionFilter*/
  }

  const getSchema = (type) => {
    switch(type) {
      case 'TPhone': 
        return joiResolver(phoneSchema());
      case 'TEmail': 
        return joiResolver(emailSchema());
      case 'TNumber': 
        return joiResolver(numberSchema());
      default: 
        return undefined;
    }
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: getSchema(questionJson.type),
  });
  const [error, setError] = useState('');

  const handleNext = () => {
    if(questionJson.type === 'TRadioButton') {
      let selected = questionJson.answers.filter(answer => answer.selected);
      if(selected.length > 0 && selected[0].next) {
        handleActive(selected[0].next - 1);
      } else
        handleActive(questionJson.noQuestion);
    } else
      handleActive(questionJson.noQuestion);
  }

  const onFormSubmit = (values) => {
    console.log('values in onSubmit: ', values)
    switch(questionJson.type) {
      case 'TRadioButton':
      case 'TCheckBox':
      case 'TVideo':
      case 'TImageOption':
        let selected = questionJson.answers.filter(answer => answer.selected)
        if(questionJson.required && selected.length === 0) {
          setError('Seleccione una respuesta');
          return;
        }
        break;
      case 'TDate':
      case 'TSignature':
      case 'TPhoto':
        break;
      default:
        questionJson.answers[0].selected = values.answer;
    }
    if(questionJson.noQuestion === total) {
      handleSend();
    }else{
      handleNext();
    }
  }

  function QuestionPreview() {
    let QuestionComponent = questionsComponents[questionJson.type];
    switch(questionJson.type) {
      case 'TRadioButton':
      case 'TCheckBox':
      case 'TVideo':
      case 'TImageOption':
        return <QuestionComponent
                answers={questionJson.answers}
                error={error} />
      case 'TImage':
        return <TImage 
                noQuestion={questionJson.noQuestion}
                image={questionJson.imagen}
                answers={questionJson.answers}
                required={questionJson.required} />
      default:
        return <QuestionComponent 
                answers={questionJson.answers}
                required={questionJson.required}
                register={register}
                errors={errors} />
    }
  }

  return ( 
    <React.Fragment>
      <h4>{questionJson.noQuestion} {questionJson.question}</h4>
      <h5>{questionJson.instructions}</h5>
      <form >
        <QuestionPreview />
        <Grid container spacing={3} justify={"space-between"}>
          <Grid item>
            <Button onClick={handleBack}>
              Pregunta Anterior
            </Button>
          </Grid>
          <Grid item>
            {`${questionJson.noQuestion}/${total}`}
          </Grid>
          <Grid item>
            <Button color='info' onClick={handleSubmit(onFormSubmit)}>
              {questionJson.noQuestion === total ? "Enviar" : "Siguiente Pregunta"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  )
}