import React, {useEffect, useState,useRef} from "react"
import { format } from 'date-fns'
import { Container, Grid, TableContainer, Table, TableHead, TableBody,
         TablePagination, TableRow, TableCell, IconButton } from "@material-ui/core"
import { Card, CardHeader, CardBody } from "components/Card"
import Dialog from 'components/Dialog/Dialog'
import Backdrop from 'components/Backdrop/Backdrop'
import {notify} from 'components/Snackbar/Notifier'
import Confirm from 'components/AlertDialog/AlertDialog'
import Button from 'components/CustomButtons/Button'
import Tooltip from 'components/Tooltip/Tooltip'
// @material-ui/icons
import { Edit, Delete } from "@material-ui/icons"
import CouponService from "Services/CouponService"
import Coupon from "Class/Coupon"
import CouponForm from './CouponForm'
import Error from '../Error/404'

// @ts-ignore
export default function () {
  const formRef = useRef<any>(null)
  const [companies, setCoupons] = useState(new Array<Coupon>(0))
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(25)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(200)

  const initialDialog = {
    open: false,
    title: '',
    body: <div></div>
  }

  const [dialog, setDialog] = useState(initialDialog)
  const initialConfirm = {
    open: false,
    title:'',
    body:'',
    action: () =>{}
  }
  const [confirm,setConfirm] = useState(initialConfirm)

  useEffect(() => {
    getCoupons(page,rows)
  },[page, rows]);

  function handleOpenConfirm(confirmState: any){
    setConfirm({open: true,...confirmState})
  }
  function handleOpenDialog(dialogState: any){
    setDialog({open: true, ...dialogState})
  }

  const handleSubmitForm = () => {
    if(formRef && formRef !== null){
      formRef.current.formSubmit();
    }
  }

  function couponForm(coupon: Coupon) {
    return (
      <Container  component="main" maxWidth="md">
        <CouponForm
          ref={formRef}
          coupon={coupon}
          onSubmit={handleCouponSave}
        />
      </Container>
    );
  }

  async function getCoupons(page: number, rows: number) {
    setLoading(true)
    try {
      const response = await CouponService.listAllCoupons(page,rows)
      setCoupons(response.data)
      setPage(response.pagination.current_page)
      setTotal(response.pagination.total_elements)
    } catch (e) {
      setStatus(e.status)
      //if(e.detail) notify('danger',e.detail)
      //else notify('danger',e.error)
    }
    setLoading(false)
  }

  async function handleGetCoupon(id: number){
    try {
      const response = await CouponService.getCoupon(id)
      handleOpenDialog({title: 'Editando Cupón',body: couponForm(response)})
    } catch (e) {
      if(e.detail) notify('danger',e.detail)
      else notify('danger',e.error)
    }
  }

  async function handleCouponSave(coupon: Coupon) {
    setLoading(true)
    try {
      await (coupon.id !== 0
        ? CouponService.updateCoupon(coupon)
        : CouponService.createCoupon(coupon))
      getCoupons(page,rows)
      setDialog(initialDialog)
      notify('success','Cupón guardado con exito')
    } catch (e) {
      if(e.detail) notify('danger',e.detail)
      else notify('danger',e.error)
    }
    setLoading(false)
  }

  async function handleRemoveCoupon(couponId: number){
    try{
      await CouponService.removeCoupon(couponId)
      getCoupons(page, rows)
      setConfirm(initialConfirm)
      notify('success','Cupón eliminado con exito')
    }catch (e) {
      if(e.detail) notify('danger',e.detail)
      else notify('danger',e.error)
    }
  }
  function generateCells() {
    return companies.map(coupon => {
      return <TableRow hover={true} key={coupon.id}>
          <TableCell>{coupon.code}</TableCell>
          <TableCell>{coupon.activations}</TableCell>
          <TableCell>{coupon.usersNumber}</TableCell>
          <TableCell>{coupon.validity !== null ? format(new Date(coupon.validity), 'MM/dd/yyyy') : ''}</TableCell>
          <TableCell>{coupon.cost}</TableCell>
          <TableCell>
            <Tooltip title="Editar Cupón" >
              <IconButton onClick={() => handleGetCoupon(coupon.id) }>
                <Edit color="primary" style={{fontSize: 18}}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar Cupón" >
              <IconButton
                onClick={() => handleOpenConfirm({title: 'Eliminar Cupón',
                                                 body:'¿Esta seguro de eliminar este Cupón?',
                                                 action: () => handleRemoveCoupon(coupon.id)})  }>
                <Delete color="error" style={{fontSize: 18}}/>
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
    })
  }

  if(status !== 200) return <Error status={status} />

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Grid >
        <Dialog
          title={dialog.title}
          body={dialog.body}
          isOpen={dialog.open}
          onSubmit={handleSubmitForm}
          onClose={() => setDialog(initialDialog)}
        />
        <Confirm
          open={confirm.open}
          title={confirm.title}
          body={<p>{confirm.body}</p>}
          onClose={() => setConfirm(initialConfirm) }
          onSubmit={confirm.action}
        />
      </Grid>
      <Card>
        <CardHeader color="primary" stats>
          <h3>Cupones</h3>
          <p>Lista de Cupones</p>
        </CardHeader>
        <CardBody>
          <Button color="info" onClick={() => handleOpenDialog({title:'Nuevo Cupón',body: couponForm(new Coupon())})}>Nuevo Cupón</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Num. Activaciones</TableCell>
                  <TableCell>Num. Usuarios</TableCell>
                  <TableCell>Fecha de Vigencia</TableCell>
                  <TableCell>Costo</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generateCells()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination 
            count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1)}
            page={page - 1}
            labelRowsPerPage={'Empresas por página'}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value)}/>
        </CardBody>
      </Card>
    </Grid>
  )
}
