import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {Grid, GridList, GridListTile} from '@material-ui/core'
import Popover from '@material-ui/core/Popover';
import MButton from '@material-ui/core/Button';

import {Image, OndemandVideo, Audiotrack} from '@material-ui/icons'

export default function ResourceSelect(props) {
  const {title, resource, resources, onChange, size} = props;
  const [type, setType] = useState(null);
  const [source, setSource] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if(resource.includes('image')){
      setSource(resource);
      setType('image');
    }else{
      resources.forEach(r => {
        if(r.key === resource){
          setSource(r.source);
          setType(r.resource_type);
        }
      });
    }
  },[resource, resources])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const icons = {'Imagen': Image, 'Video': OndemandVideo , 'Audio': Audiotrack }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (key,source,type) => {
    setType(type);
    setSource(source);
    onChange(key);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIcon = (name) => {
    const Icon = icons[name];
    return <Icon />
  }

  const preview = () => {
    return (
      <GridList cellHeight={160} cols={3}>
        <GridListTile cols={size}>
        {type !== null && source !== null && type.includes('image') && 
          ( <img style={{width: '100%', height: '100%'}} src={source} alt={type} />)
        }
        {type !== null && source !== null && type.includes('video') && (
          <video key={source} controls style={{width: '100%', height: '100%'}}>
            <source src={source} type={type} />
            Tu navegador no implementa el elemento <code>video</code>.
          </video>
          )
        }
        </GridListTile>
      </GridList>
    )
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item md={12}>
        <MButton aria-describedby={id}
          variant="outlined"
          color="primary"
          onClick={handleClick}
          startIcon={getIcon(title)}>
          Seleccionar {title}
        </MButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{maxWidth: '500px', maxHeigth: '300px'}}>
              <GridList cellHeight={160} cols={3}>
                {resources.map((resource, index) => (
                  <GridListTile key={index} cols={1} onClick={() => { handleChange(resource.key,resource.source,resource.resource_type); handleClose(); }}>
                  {resource.resource_type.includes('image') && 
                    ( <img style={{width: '100%', height: '100%'}} src={resource.source} alt={resource.name} />)
                  }
                  {resource.resource_type.includes('video') && 
                    (
                     <video style={{width: '100%', height: '100%'}}>
                      <source src={resource.source} type={resource.resource_type} />
                      Tu navegador no implementa el elemento <code>video</code>.
                    </video>
                    )
                  }
                  </GridListTile>
                ))}
                {resources.length === 0 && (
                  <GridListTile cols={3}>
                    <p>No hay archivos disponibles en su biblioteca</p>
                  </GridListTile>
                )}
              </GridList>
          </div>
        </Popover>
      </Grid>
      <Grid item md={12}>
        {preview()}
      </Grid>
    </Grid>
  )
}

ResourceSelect.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.string,
  resources: PropTypes.array,
  onChange: PropTypes.func,
  size: PropTypes.number
}
