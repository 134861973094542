import React, {useState, useEffect} from "react"
import {Grid, TextField, IconButton, MenuItem, FormControlLabel, Switch} from '@material-ui/core'
import { Card, CardHeader, CardBody, CardFooter, CardIcon } from 'components/Card'
import Tooltip from 'components/Tooltip/Tooltip'
import ResourceSelect  from 'components/ResourceSelect/ResourceSelect'

import TTextField from 'components/SurveyComponents/TTextField'
import TDate from 'components/SurveyComponents/TDate'
import TLocation from 'components/SurveyComponents/TLocation'
import TPhoto from 'components/SurveyComponents/TPhoto'
import TSignature from 'components/SurveyComponents/TSignature'
import TRadioButton from 'components/SurveyComponents/TRadioButton'
import TComment from 'components/SurveyComponents/TComment'
import TPhone from 'components/SurveyComponents/TPhone'
import TEmail from 'components/SurveyComponents/TEmail'
import TCheckBox from 'components/SurveyComponents/TCheckBox'
import TVideo from 'components/SurveyComponents/TVideo'
import TImageOption from 'components/SurveyComponents/TImageOption'
import TVideoText from 'components/SurveyComponents/TVideoText'
import TImage from 'components/SurveyComponents/TImage'
import TNumber from 'components/SurveyComponents/TNumber'
import TCover from 'components/SurveyComponents/TCover'
import TOptionFilter from 'components/SurveyComponents/TOptionFilter'

import {DeleteForever} from '@material-ui/icons'

export default function QuestionsFactory(props) {
  const {images, videos, questionJson, noQuestions, 
        removeQuestion, moveQuestion,
        updateJson, questionsFilter, getOptionsFilter} = props;

  const questionsTypes = [
    {type: 'TTextField', name: 'Respuesta Corta'},{type: 'TComment',name: 'Comentario'},
    {type: 'TNumber', name: 'Numérico'},
    {type: 'TDate', name: 'Fecha'},{type: 'TLocation', name: 'Localización'},
    {type: 'TPhoto', name: 'Fotografia'},{type: 'TSignature', name: 'Firma'},
    {type: 'TRadioButton', name: 'Selección unica'},{type: 'TCheckBox', name: 'Selección Multiple'},
    {type: 'TPhone', name: 'Número Telefónico'},{type: 'TEmail', name: 'Correo Electrónico'},
    {type: 'TVideo', name: 'Video'},{type: 'TImageOption', name: 'Selección con imagenes'},
    {type: 'TVideoText', name: 'Video con Comentario'}, {type: 'TImage', name: 'Imagen'},
    {type: 'TCover', name: 'Carátula'}, {type: 'TOptionFilter', name: 'Selección con Filtro'}
  ]
  const questionsComponents = {
    TTextField,TRadioButton,TDate,TLocation,TPhoto,TSignature,
    TComment,TPhone,TEmail,TCheckBox,TVideo,TImageOption,TVideoText,TImage,
    TNumber,TCover,TOptionFilter
  }
  
  const [currentQuestion, setCurrentQuestion] = useState(questionJson.type)
  const [question, setQuestion] = useState(questionJson.question)
  const [instructions, setInstructions] = useState(questionJson.instructions)
  const [required, setRequired] = useState(true)
  const [source, setSource] = useState("")
  const [predecessor, setPredecessor] = useState('')

  useEffect(() => {
    setQuestion(questionJson.question || '')
    setInstructions(questionJson.instructions || '')
    setCurrentQuestion(questionJson.type || 'TTextField')
    if(questionJson.required !== undefined) setRequired(questionJson.required)
    if(questionJson.source !== undefined) setSource(questionJson.source)
  },[questionJson])

  useEffect(() => {
    if(questionJson.predecessor)  setPredecessor(questionJson.predecessor)
  },[questionJson.predecessor])

  const handleChangeTypeQuestion = (type) => {
    setCurrentQuestion(type)
    if(type === 'TOptionFilter')
      updateJson(questionJson.id, {type,required,predecessor,answers: []})
    else
      updateJson(questionJson.id, {type,required, answers: []})
  }

  const hanldeChangeRequired = (required) => {
    setRequired(required)
    updateJson(questionJson.id, {required})
  }
  const handleChangeSource = (source) => {
    setSource(source)
    updateJson(questionJson.id, {source})
  }
  
  const handleChangePredecessor = (predecessor) => {
    setPredecessor(predecessor)
    updateJson(questionJson.id,{predecessor})
  }

  function QuestionPreview(){
    let newNoQuestions = [];
    newNoQuestions = [...noQuestions].slice(questionJson.noQuestion,noQuestions.length);

    const QuestionComponent = questionsComponents[currentQuestion]
    if(currentQuestion === 'TOptionFilter'){
      let newOptions = [];
      if(predecessor !== '') newOptions = getOptionsFilter(predecessor);
      return <QuestionComponent answers={questionJson.answers} getJson={getJson} optionsFilter={newOptions} />
    }
    return <QuestionComponent images={images} answers={questionJson.answers} noQuestions={newNoQuestions} getJson={getJson} />
  }

  function getJson(json){
    updateJson(questionJson.id, {categoryId: json.categoryId, answers: json.answers})
    //setCategory(json.categoryId)
  }

  return (

    <Card>
      <CardHeader icon>
        <CardIcon color="primary">
          {questionJson.id}
        </CardIcon>
      </CardHeader>
      <CardBody>
        <Grid container spacing={3}>
          <Grid item container md={8} spacing={2} >
            <Grid item md={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                type="text"
                name="question"
                label={currentQuestion === 'TCover' ? 'Título' : 'Pregunta'}
                value={question}
                InputProps={{onBlur: (event) => updateJson(questionJson.id, {question})}}
                onChange={(event) => setQuestion(event.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                multiline
                value={instructions}
                variant="outlined"
                type="text"
                label={currentQuestion === 'TCover' ? 'Subtítulo' : 'Instrucciones'}
                InputProps={{onBlur: (event) =>  updateJson(questionJson.id, {instructions}) }}
                onChange={(event) => setInstructions(event.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {(currentQuestion === 'TVideo' || currentQuestion === 'TVideoText') && (
                <ResourceSelect size={2} title="Video" resource={source} resources={videos} onChange={(source) => handleChangeSource(source)} />
              )}
              {currentQuestion === 'TImage' && (
                <ResourceSelect size={2} title="Imagen" resource={source} resources={images} onChange={(source) => handleChangeSource(source)} />
              )}
            </Grid>
          </Grid>
          <Grid item container md={4} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Tipo de Pregunta"
                value={currentQuestion}
                onChange={(event) => handleChangeTypeQuestion(event.target.value)}
                variant="outlined" >
                  {questionsTypes.map((option,index) => {
                    if(option.type !== 'TVideoText'){
                      return (
                        <MenuItem key={option.type} value={option.type} disabled={option.type === 'TOptionFilter' && questionsFilter.length === 0 ? true : false}>
                          {option.name}
                        </MenuItem>
                      )
                    }else{
                      return null;
                    }
                  })}
              </TextField>
            </Grid>
            {currentQuestion === 'TOptionFilter' && questionsFilter.length > 0 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Seleccionar Pregunta Filtro"
                  value={predecessor}
                  onChange={(event) => handleChangePredecessor(event.target.value)}
                  variant="outlined" >
                  {questionsFilter.map((option,index) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Grid>
        <QuestionPreview  type={currentQuestion} noQuestion={questionJson.noQuestion} noQuestions={noQuestions}/>
      </CardBody>
      <CardFooter>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center">
          <Grid item md={2} xs={12}>
            <TextField
              select
              size="small"
              fullWidth
              label="Mover a"
              value={''}
              onChange={(event) => moveQuestion(questionJson.id,event.target.value)}
              variant="outlined">
              {noQuestions.map((questionNumber,index) => {
                if(questionNumber !== questionJson.id) {
                  return (
                    <MenuItem key={index} value={questionNumber}>
                      {questionNumber}
                    </MenuItem>
                  )
                }else{
                  return null;
                }
              }
              )}
            </TextField>
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              control={<Switch 
                        color="primary"
                        checked={required}
                        onChange={(event) => hanldeChangeRequired(event.target.checked)} />}
              label="Requerido"
              labelPlacement="start"
            />
          </Grid>
          <Grid item>
            <Tooltip title="Eliminar Pregunta" >
              <IconButton onClick={() => removeQuestion(questionJson.id)}>
                <DeleteForever color="error"/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardFooter>
    </Card>
  );
}