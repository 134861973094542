import axios from 'axios'
import {environment} from "Enviroments"

const baseURL = environment.url

const QService = axios.create({
  baseURL,
  timeout: 300000
});


QService.interceptors.request.use(
   config => {
        const AUTH = localStorage.getItem("currentUser")
        if(AUTH){
          const AUTH_TOKEN =  JSON.parse(AUTH).token
          config.headers['Authorization'] = AUTH_TOKEN
        }
       config.headers['Content-Type'] = 'application/json';
       return config;
   },
   error => {
       Promise.reject(error)
   });


//QService.defaults.headers.post['Content-Type'] = 'application/json'

QService.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if(error.response) {
      let status = error.response.status
      if(status === 401) {
        localStorage.removeItem('currentUser');
      }
      return Promise.reject(error.response.data);
    }else{
      return Promise.reject({status: 500, message: 'network error'});
    }
});

export default QService
