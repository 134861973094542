import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react'
import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import { Grid, TextField, MenuItem } from '@material-ui/core'

import Campaign from 'Class/Campaign'
import Company from 'Class/Company'
import { joiResolver } from "@hookform/resolvers";
import {schema_create} from 'Validations/Campaigns'

import {isSuper} from 'Services/Auth'
import CompanyService from 'Services/CompanyService'

type CampaignProps = {
  campaign: Campaign,
  onSubmit: (campaign: Campaign) => void
}

const CampaignForm = forwardRef(({ campaign, onSubmit }: CampaignProps, ref) => {

  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema_create),
  });
  const formRef = useRef<HTMLFormElement>(null);

  const CompanyClass: Company[] = []
  const [companies, setCompanies] = useState(CompanyClass)
  const [companyId, setCompanyId] = useState(campaign.company.id)
  const [companyError, setCompanyError] = useState("")

  useEffect(() => {
    if(isSuper())
      getCompanies()
  }, [])

   useEffect(() => {
    if(companyId > 0) {
      setCompanyError("")
    }
  },[companyId])

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  async function getCompanies() {
    try {
      const response = await CompanyService.listAllCompanies(0,0)
      setCompanies(response.data)
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const onFormSubmit = (values: any) => {
    if(isSuper() && companyId === 0){
      setCompanyError("Seleccione una Empresa")
      return
    }
    const company = new Company({id: companyId})
    onSubmit(new Campaign({id: campaign.id, company: company, ...values}))
  };
  return (
    <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            name="name"
            label="Nombre"
            defaultValue={campaign.name}
            inputRef={register}
            placeholder="Ingrese el nombre de la campaña"
            error={errors.name ? true : false}
            helperText={errors.name?.message}
          />
        </Grid>
        { isSuper() && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label="Empresa a la que pertence"
              name="companyId"
              value={companyId > 0 ? companyId : ""}
              onChange={(event) => setCompanyId(+event.target.value)}
              variant="outlined"
              error={companyError !== '' ? true : false}
              helperText={companyError}
            >
              {companies.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>
    </form>
  );
});

CampaignForm.propTypes = {
  campaign: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CampaignForm
