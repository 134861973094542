import React,{useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {List, ListItem, FormControlLabel} from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';

import {useForm} from 'react-hook-form'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 500,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);
type CampaignProps = {
  users: Array<any>,
  onSubmit: (members: Array<number>) => void
}

const MemberForm = forwardRef(({ users, onSubmit }: CampaignProps, ref) => {

  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const { handleSubmit } = useForm();
  const [checked, setChecked] = useState(new Array<number>(0));
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if(allSelected) {
      let all = users.map((user) => user.id)
      setChecked(all)
    }else{
      setChecked([])
    }
  }, [allSelected, users]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  const onFormSubmit = () => {
    onSubmit(checked)
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
    {users.length > 0 &&
      <FormControlLabel
        control={
          <Checkbox checked={allSelected} onChange={(event) => setAllSelected(event.target.checked)} />
        }
        label={`Seleccionar todos (${users.length} usuarios)`}
      />
    }
      <List dense className={classes.root}>
        {users.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.id}`;
          return (
            <ListItem key={value.id} button>
              <ListItemAvatar>
                <Avatar>
                  {value.name.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText id={labelId} primary={value.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleToggle(value.id)}
                  checked={checked.indexOf(value.id) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </form>
  );
});

export default MemberForm;
