import React,{useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {List, ListItem, FormControlLabel} from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';

import {Chip, Grid, TextField} from '@material-ui/core';
import Button from 'components/CustomButtons/Button'

import Joi from '@hapi/joi';
import {useForm} from 'react-hook-form'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 500,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);
type CampaignProps = {
  surveyId: number,
  users: Array<any>,
  onSubmit: (surveyId: number, message: string, emails: Array<string>) => void
}

const MemberForm = forwardRef(({ surveyId, users, onSubmit }: CampaignProps, ref) => {

  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);
  //const { register, handleSubmit, errors } = useForm();
  const { handleSubmit } = useForm();
  const [checked, setChecked] = useState(new Array<string>(0));
  const [allSelected, setAllSelected] = useState(false);
  const [newEmail, setNewEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')

  const [chipData, setChipData] = useState(new Array<string>(0));

  const schema = Joi.object({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false }}).required().messages({
    'string.empty': 'Ingrese un correo electrónico.',
    'string.email': 'Correo electrónico inválido'
  })
  });

  const handleDelete = (chipToDelete: string) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const addEmail = async () => {
    try {
      await schema.validateAsync({ email: newEmail });
      if(!chipData.includes(newEmail) && !checked.includes(newEmail)) {
        setChipData([...chipData,newEmail])
        setErrorEmail('')
      } else{
        setErrorEmail('El correo electrónico ya se encuentra en la lista.')
      }
      setNewEmail('')
    } catch (err) {
      setErrorEmail(err.message)
    }
  }

  useEffect(() => {
    if(allSelected) {
      let all = users.map((user) => user.email)
      setChecked(all)
    }else{
      setChecked([])
    }
  }, [allSelected, users]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  const onFormSubmit = () => {
    const emails = checked.concat(chipData);
    onSubmit(surveyId,'Puede contestar esta encuesta?',emails)
  };

  return (
    <React.Fragment>
    <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}></form>
    <Grid container spacing={3} alignItems="center">
      <Grid item md={8} xs={12}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          type="text"
          name="email"
          label="Correo Electrónico"
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
          error={errorEmail.length > 0 ? true : false}
          helperText={errorEmail}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Button color="info" onClick={addEmail} disabled={!(newEmail.trim().length > 0)} >Agregar</Button>
      </Grid>
      <Grid item xs={12}>
        {chipData.map((data, index) => {
          return (
            <Chip
              key={index}
              label={data}
              onDelete={handleDelete(data)}
            />
          );
        })}
      </Grid>
    

    {users.length > 0 &&
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={allSelected} onChange={(event) => setAllSelected(event.target.checked)} />
          }
          label={`Seleccionar todos (${users.length} usuarios)`}
        />
      </Grid>
    }
    <Grid item xs={12}>
      <List dense className={classes.root}>
        {users.map((value,index) => {
          return (
            <ListItem key={index} button>
              <ListItemAvatar>
                <Avatar>
                  {value.name.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={value.name} secondary={value.email} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleToggle(value.email)}
                  checked={checked.indexOf(value.email) !== -1}
                  inputProps={{ 'aria-labelledby': 'e_'+index }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  </Grid>
  </React.Fragment>
  );
});

export default MemberForm;
