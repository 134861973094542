import Client from "./Client";
import { format } from "date-fns";

const resource = "/surveys";

export default class AnswersService {
  public static async getAnswers(
    surveyId: number,
    page: number,
    size: number,
    filters: any
  ) {
    try {
      let url = `${resource}/${surveyId}/answers?page=${page}&page_size=${size}`;
      if (filters.startDate !== null && filters.endDate !== null) {
        url += `&start_date='${format(
          filters.startDate,
          "yyyy/MM/dd"
        )}'&end_date='${format(filters.endDate, "yyyy/MM/dd")}'`;
      }
      if (filters.userId !== 0) url += `&user=${filters.userId}`;
      const { data } = await Client.get(url);
      return data;
    } catch (e) {
      throw e;
    }
  }
  public static async getUsers(surveyId: number) {
    try {
      const { data } = await Client.get(`${resource}/${surveyId}/users`);
      return data;
    } catch (e) {
      throw e;
    }
  }
  public static async getMarkers(surveyId: number, filters: any) {
    try {
      let url = `${resource}/${+surveyId}/answers_location?`;
      if (filters.startDate !== null && filters.endDate !== null) {
        url += `&start_date='${filters.startDate}'&end_date='${filters.endDate}'`;
      }
      if (filters.userId !== 0) url += `&user=${filters.userId}`;
      const { data } = await Client.get(url);
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getAnswersReports(surveyId: number, filters: any) {
    try {
      let url = `${resource}/${+surveyId}/answers_report?`;
      if (filters.userId && filters.userId !== 0)
        url += `&user=${filters.userId}`;
      if (filters.year && filters.year !== 0) url += `&year=${filters.year}`;
      if (filters.month && filters.month !== 0)
        url += `&month=${filters.month}`;

      if (filters.day) {
        const date = filters.day;

        const init = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          0
        );

        const end = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          23,
          59
        );

        url += `&init='${init}'`;
        url += `&end='${end}'`;
      }

      const { data } = await Client.get(url);
      return data;
    } catch (e) {
      throw e;
    }
  }
  public static async deleteAnswer(answerId: number) {
    try {
      if (answerId !== 0) await Client.delete(`/answers/${answerId}`);
    } catch (e) {
      throw e;
    }
  }

  public static async getImage(key: string) {
    try {
      const { data } = await Client.get(`/answers/download_image/${key}`);
      return data.data;
    } catch (e) {
      throw e;
    }
  }

  public static async saveAnswer(answers: Object) {
    try {
      const { data } = await Client.post("/answers/answers_web", answers);
      //console.log(data)
      return data.data;
    } catch (e) {
      throw e;
    }
  }
}
