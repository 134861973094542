import React from "react"
import {Grid, Container, Typography} from "@material-ui/core"
import { ErrorOutline, Home, KeyboardBackspace } from "@material-ui/icons"
import Button from 'components/CustomButtons/Button'
import Quote from 'components/Typography/Quote'

export default function (props: any) {
  const {status, url} = props
  const goBack = () => {
    url.goBack();
  }
  return (
    <Container maxWidth="sm" style={{background: 'rgba(255,0,0,0.1)'}}>
      <Grid container spacing={2}>
        <Grid item>
          <div className={"text-center"}>
            <ErrorOutline color="error" className="icon-6x"/>
            <Typography gutterBottom variant="h4">
              Error {status !== 500 ? 404 : 500}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Quote
                text={status === 500 ? "Ocurrio un problema al conectarse con el servidor." : "Pagina no encontrada" }
                author={status === 500 ? "Pongase en contacto con el administrador" : "La página o el elemento al que quiere acceder no existe o no esta disponible." }
              />
            </Grid>
            <Grid item>
              {url && 
                <Button onClick={goBack}><KeyboardBackspace /> Regresar</Button>
              }
              <Button color="primary" href="/admin"> <Home /> Inicio</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
