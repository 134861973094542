import React, {useEffect} from "react"
import { makeStyles } from "@material-ui/core/styles";
import {Grid, TextField, IconButton, Tooltip, MenuItem} from '@material-ui/core'
import PropTypes from "prop-types"
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Close from "@material-ui/icons/Close";
import AddCircle from '@material-ui/icons/AddCircle'

import styles from 'assets/jss/material-dashboard-react/components/tasksStyle.js';

const useStyles = makeStyles(styles);

export default function TOptionFilter(props) {
  const {getJson, answers, optionsFilter } = props;
  const classes = useStyles();
  const categoryId = 1
  const type = 'TOptionFilter'

  useEffect(() => {
    if(answers.length <= 0){
      getJson(asJSON([{option: 'Opción 1', predecessor: ''}]));
    }
  }, [answers, getJson])

  function asJSON(newAnswers){
    newAnswers.map((item, index) => {
      item['inciso'] =  (index+10).toString(36)
      item['selected'] = false
      return item
    });
    return {categoryId,type,answers: newAnswers,predecessor: ''};
  }

  function handleAddOption(){
    let newAnswers = [...answers]
    newAnswers.push({option: 'Opción '+(newAnswers.length+1), predecessor: ''});
    getJson(asJSON(newAnswers));
  }

  function handleRemoveOption(index){
    let newAnswers = [...answers];
    newAnswers.splice(index,1);
    getJson(asJSON(newAnswers));
  }

  function handleChangeOption(optionText, index){
    let newAnswers = [...answers];
    if(optionText.trim().length > 0)
      newAnswers[index].option = optionText
    else
      newAnswers[index].option = 'Opción '+(index+1)
    getJson(asJSON(newAnswers));
  }

  function handleChangePredecesor(index,value){
    let newAnswers = [...answers];
    newAnswers[index]['predecessor'] = value
    getJson(asJSON(newAnswers));
  }

  return (
    <Grid container spacing={1}>
      {answers.map((option, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item md={1}>
              <RadioButtonUncheckedIcon />
            </Grid>
            <Grid item md={8}>
              <TextField fullWidth  
                defaultValue={option.option}
                InputProps={{onBlur: (event) => handleChangeOption(event.target.value, index) }} />
            </Grid>
            <Grid item md={1}>
              {answers.length > 1 && (
                <Tooltip
                  title="Eliminar opción"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                  <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={()=> handleRemoveOption(index) }>
                    <Close className={classes.tableActionButtonIcon + " " + classes.close}/>
                  </IconButton>
                </Tooltip>
                )
              }
            </Grid>
            <Grid item md={2}>
              {optionsFilter.length > 0 && (
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Pertenece a"
                  value={option.predecessor}
                  onChange={(event) => handleChangePredecesor(index,event.target.value)}
                  variant="outlined">
                  {optionsFilter.map((questionNumber,index) => (
                    <MenuItem key={index} value={questionNumber}>
                      {questionNumber}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </React.Fragment>
        )
      })}
      <Grid item md={12}>
        <Tooltip
          title="Agregar opción"
          placement="top"
          classes={{tooltip:classes.tooltip}}>
          <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => handleAddOption()}>
            <AddCircle className={classes.edit}/>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

TOptionFilter.propTypes = {
  getJson: PropTypes.func,
};
