import React,{useRef, useImperativeHandle, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import Coupon from 'Class/Coupon';
import { joiResolver } from "@hookform/resolvers";
import {schema_create} from 'Validations/Coupons'
import { Grid, TextField } from '@material-ui/core'

import { DatePicker } from '@material-ui/pickers';

type CouponProps = {
  coupon: Coupon,
  onSubmit: (coupon: Coupon) => void
}

type RefProp = {
  formSubmit: () => void
}

const CouponForm = forwardRef(({ coupon, onSubmit }: CouponProps, ref) => {

  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema_create),
  });
  const formRef = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    coupon.validity
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const onFormSubmit = (values: any) => {
    values.validity = selectedDate
    onSubmit(new Coupon({ id: coupon.id, ...values }));
  };

  return (
      <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="text"
              name="code"
              label="Código"
              defaultValue={coupon.code}
              inputRef={register({ required: true })}
              inputProps={{className: "uppercase"}}
              placeholder="Ingrese el código de cupón"
              helperText={errors.code?.message}
              error={errors.code ? true : false}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="number"
              name="cost"
              label="Costo"
              defaultValue={coupon.cost}
              inputRef={register({ required: true })}
              placeholder="Ingrese el costo del cupón"
              helperText={errors.cost?.message}
              error={errors.cost ? true:false}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="number"
              name="activations"
              label="Número de Activaciones"
              defaultValue={coupon.activations}
              inputRef={register({ required: true })}
              placeholder="Ingrese el numero de Activaciones"
              helperText={errors.activations?.message}
              error={errors.activations ? true:false}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="number"
              name="usersNumber"
              label="Número de Usuarios"
              defaultValue={coupon.usersNumber}
              inputRef={register({ required: true })}
              placeholder="Ingrese el numero de usuarios"
              helperText={errors.usersNumber?.message}
              error={errors.usersNumber ? true:false}
            />
          </Grid>
          <Grid item md={4}>
            <DatePicker 
              fullWidth
              margin="normal"
              label="Fecha de Vigencia"
              inputVariant="outlined"
              format="yyyy/MM/dd"
              name="validity"
              value={selectedDate}
              onChange={handleDateChange}
              inputRef={register({ required: true })}
              minDate={coupon.minValidity()}
              helperText={errors.validity?.message}
              error={errors.validity ? true:false}
            />
          </Grid>
        </Grid>
      </form>
  );
});

CouponForm.propTypes = {
  coupon: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CouponForm
