import React from 'react'
import imageVision from 'assets/img/banner-2.jpg'

export default function Content(){
  return (
    <div id="content" className="p-0 bg-white">
      <div id="about">
        <div className="py-4 pt-md-6 py-md-5">
          <div className="container text-center w-100 w-lg-70">
            <h2 className="h1 font-weight-bold my-0 text-primary ">
              Características
            </h2>
            <div className="row text-center mx-auto mt-3 mt-md-5 ">
              <div className="col-md-4 py-2">
                <ion-icon name="bulb-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Muy fácil de usar
                </h4>
                <p className="op-7">No requiere mucha capacitación</p>
              </div>
              <div className="col-md-4 py-2">
                <ion-icon name="bar-chart-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Obten datos de control
                </h4>
                <p className="op-7">Registra la localización donde se realizó cada cuestionario</p>
              </div>
              <div className="col-md-4 py-2">
                <ion-icon name="cloud-download-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Descarga tus datos fácil
                </h4>
                <p className="op-7">Por medio del portal web descarga el concentrado de datos de los cuestionarios</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 pt-md-6 py-md-5 py-md-5 bg-op-5 bg-white ">
        <div className="container w-100 w-lg-70">
          <div className="row my-2">
            <div className="col-lg-6 d-sm-flex ">
              <img className="card-img-top img-fluid" src={imageVision} alt="img" />
            </div>
            <div className="col-lg-6 d-sm-flex align-content-center d-flex align-items-center ">
              <div className="mr-auto py-0 pl-lg-5 my-3 my-md-0 ">
                <h2 className="display-4 roboto-thin mt-3 mt-lg-0">
                  ¿Cómo funciona Encuesta App?
                </h2>
                <p className="line-height-30 py-md-2 op-7">
                  Cuantas veces has impreso cuestionarios para obtener información a través de <b>encuestadores</b>. 
                  Pero estos formularios contestados se quedan en el escritorio porque no los capturan y toda 
                  ese esfuerzo y dinero se va a la <b>basura</b>.
                </p>
                <p className="py-md-2 op-7"><b>Encuesta App</b> es una aplicación que se instala en los teléfonos o 
                tabletas para que por ese medio las personas salgan a levantar cuestionarios, encuestas, 
                estudios de mercado <b>SIN NECESIDAD DE DATOS CELULARES</b></p>
                <p className="py-md-2 op-7">Además en cada cuestionario recabado se adjunta la <b>geolocalización</b> 
                y la <b>hora exacta</b> de donde fue levantado de tal manera que te aseguraras que los encuestadores 
                están haciendo bien su trabajo. </p>
                <p className="py-md-2 op-7">Y lo mejor de todo, toda la información obtenida de los encuestadores 
                la obtienes fácilmente en un Excel. Por tanto <b> NO NECESITAS CAPTURISTAS</b></p>
                <p className="py-md-2 op-7"><b>¿Más fácil? ¡Imposible! </b></p>
                <a href="/login" className="mb-1 py-2 px-4 btn btn-primary btn-shadow btn-flat btn-sm btn-bold text-uppercase text-letter-spacing rounded-0">Comienza ahora</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}