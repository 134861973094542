import Client from './Client'
import Coupon from "Class/Coupon";

const resource = '/coupons'

export default class CouponService {
  public static async listAllCoupons(page: number, page_size: number) {
    try {
      let url  = `${resource}`
      if(page !== 0 && page_size !== 0)
        url = `${resource}?page=${page}&page_size=${page_size}`
      const { data } = await Client.get(url)
      return data
    } catch (e) {
      throw e;
    }
  }
  
  public static async searchCoupon(code: string) {
    try {
      const { data } = await Client.get(`${resource}/search/${code}`)
      return new Coupon(data.data)
    } catch (e) {
      throw e;
    }
  }

  public static async getCoupon(couponId: number) {
    try {
      const { data } = await Client.get(`${resource}/${couponId}`)
      const comp = new Coupon(data.data)
      return comp
    } catch (e) {
      throw e;
    }
  }

  public static async createCoupon(coupon: Coupon) {
    try {
      const { data } = await Client.post(`${resource}`, coupon.asJSON())
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async updateCoupon(coupon: Coupon) {
    try {
      const { data } = await Client.put(`${resource}/${coupon.id}`, coupon.asJSON())
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async removeCoupon(couponId: number) {
    try {
      const { data } = await Client.delete(`${resource}/${couponId}`)
      return data
    } catch (e) {
      throw e;
    }
  }
}