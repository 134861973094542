import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import { Card, CardHeader, CardBody } from "components/Card"
import Bar from 'components/Chart/Bar'
import Button from 'components/CustomButtons/Button'
import Backdrop from 'components/Backdrop/Backdrop'
import { Grid, TextField, MenuItem } from "@material-ui/core"
import { DatePicker } from '@material-ui/pickers'
import Utils from 'variables/reports'
import Error from '../Error/404'
import AnswersService from "Services/AnswersService"

// @ts-ignore
export default function () {
  const { surveyId, filterDay } = useParams()
  const [answers, setAnswers] = useState(new Array<any>(0))
  const [users,setUsers] = useState([{id: 0, name: 'Todos'}])
  const [userId, setUserId] = useState(0)
  
  const [dataYears, setDataYears] = useState([{}])
  const [years, setYears] = useState(new Array<number>(0))
  const [months, setMonths] = useState([{id: 0, name: 'Todos'}])
  const [year, setYear] = useState(0)
  const [month, setMonth] = useState(0)
  const [reportType, setReportType] = useState('month')
  const [day, setDay] = useState<Date | null>(new Date())
  const [status, setStatus] = useState(100)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    async function getInit(surveyId: number,filters: any){
      try {
        const response = await AnswersService.getAnswersReports(surveyId,filters)
        const yearsAvailible = new Array<number>(0)
        response.data.forEach((item: any) => {
          if(!yearsAvailible.includes(+item.year)) yearsAvailible.push(+item.year)
        })
        setDataYears(response.data)
        setYears(yearsAvailible)
        setYear(yearsAvailible[0])
        await getAnswers(surveyId,{year: yearsAvailible[0]})
        setMonths(Utils.getMonths(response.data,yearsAvailible[0]))
      } catch (e) {
        setStatus(e.status)
      }
    }
    if(status === 100) getUsers(surveyId)
    if(!filterDay && status === 200) getInit(surveyId, {})
  }, [surveyId, filterDay, status])

  useEffect(() => {
    if(filterDay && users.length > 1) getAnswers(surveyId,{day: new Date()},users)
  }, [filterDay,surveyId,users])

  function updateReport() {
    let filters = { year, month, userId}
    getAnswers(surveyId,filters)
  }

  function changeMonths(year: number) {
    setYear(year)
    setMonth(0)
    setUserId(0)
    setMonths(Utils.getMonths(dataYears,year))
  }

  const handleChangeDay = (date: Date | null) => {
    setDay(date);
    getAnswers(surveyId,{day: date},users)
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    setUserId(+event.target.value)
  }

  async function getAnswers(surveyId: number, filters: any, users: Array<any> = []) {
    setLoading(true)
    try {
      const response = await AnswersService.getAnswersReports(surveyId,filters)
      setReportType(response.type)
      if(response.type === 'year') {
        setAnswers(Utils.generateMonth(response.data))
      }
      if(response.type === 'byMonth') {
        setAnswers(Utils.generateDay(response.data,filters.year,filters.month))
      }
      if(response.type === 'byUser') {
        setAnswers(Utils.generateDay(response.data,filters.year,filters.month))
      }
      if(response.type === 'byDay') {
        setAnswers(Utils.generateUsers(response.data, users))
      }
    } catch (e) {
      setStatus(e.status)
    }
  setLoading(false)
  }

  async function getUsers(surveyId: number) {
    try {
      const response = await AnswersService.getUsers(surveyId)
      response.data.unshift({id: 0, name: 'Todos'})
      setUsers(response.data)
      setStatus(200)
    } catch (e) {
      setStatus(e.status)
    }
  }

  if(![100,200].includes(status)) return <Error status={status} />

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Card>
        <CardHeader color="primary" stats>
          <h3>Reportes</h3>
          <p>Reporte de Productividad</p>
        </CardHeader>
        <CardBody>
          <Grid container spacing={3} justify="center">
          {filterDay ? (
            <Grid item md={4}>
              <DatePicker
                fullWidth
                label="Seleccione el día"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                name="day"
                value={day}
                onChange={handleChangeDay}
                maxDate={new Date()}
              />
            </Grid>
          ): (
          <React.Fragment>
            <Grid item md={3}>
              <TextField
                fullWidth
                select
                label="Año"
                value={year !== 0 ? year : ""}
                onChange={(event) => changeMonths(+event.target.value)}
                variant="outlined" >
                {years.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <TextField
                fullWidth
                select
                label="Mes"
                value={month}
                onChange={(event) => setMonth(+event.target.value)}
                variant="outlined" >
                {months.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <TextField
                fullWidth
                select
                label="Usuario"
                value={userId}
                onChange={handleChangeUser}
                variant="outlined" >
                  {users.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
            </Grid>
            <Grid item md={3}>
              <Button color="primary" onClick={updateReport} onMouseDown={handleMouseDown}>
                Actualizar Gráfica
              </Button>
            </Grid>
          </React.Fragment>
          )}
            <Grid item xs={12} >
            </Grid>
          </Grid>
          <Bar answers={answers} type={reportType} />
        </CardBody>
      </Card>
    </Grid>
  )
}
