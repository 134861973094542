import React, {useState, useEffect} from 'react';
import {Bar, HorizontalBar} from 'react-chartjs-2';

export default function ChartBar(props){
  const { answers } = props
  const [labels, setLabels] = useState([])
  const [totals, setTotals] = useState([])
  const [prom, setProm] = useState([])

  useEffect(() => {
    function initial() {
      const newLabels  = answers.map(answer => answer.label)
      const newTotals = answers.map(answer => answer.total)
      const newProms = answers.map(answer => answer.prom)
      setLabels(newLabels)
      setTotals(newTotals)
      setProm(newProms)
    }
    initial()
  },[answers])
  
  const data = {
  datasets: [{
      label: 'Promedio',
      type:'line',
      data: prom,
      fill: false,
      borderColor: '#263238',
      backgroundColor: '#263238',
      pointBorderColor: '#212121',
      pointBackgroundColor: '#212121',
      pointHoverBackgroundColor: '#212121',
      pointHoverBorderColor: '#212121',
      yAxisID: 'y-axis-1'
    },{
      type: 'bar',
      label: 'Encuestas',
      barPercentage: 1.0,
      categoryPercentage: 0.5 ,
      data: totals,
      fill: false,
      backgroundColor: 'rgba(54, 162, 235, 0.7)',
      borderColor: 'rgba(54, 162, 235, 1)',
      hoverBackgroundColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
      yAxisID: 'y-axis-1'
    }]
};
const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
        labels: labels
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        labels: {
          show: true
        },
        ticks: {
          beginAtZero:true,
          min: 0
        }
      }
    ]
  }
};
  const dataHorizontal = {
    labels: labels,
    datasets: [
      {
        label: 'Encuestas',
        barPercentage: 1.0,
        categoryPercentage: 0.5 ,
        data: totals,
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
        borderColor: 'rgba(54, 162, 235, 1)',
        hoverBackgroundColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
      }
    ]
  };
  return (
    <div>
      {props.type === 'byDay' 
        ? (<HorizontalBar data={dataHorizontal} />)
        : (<Bar data={data} options={options} />)
      }
    </div>
  );
}