import React,{useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Radio, RadioGroup,FormControl, FormControlLabel,FormHelperText, TextField} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';

export default function TVideo(props) {
  const { noQuestion, video, answers, setAnswers, required, handleNext} = props;
  const [error, setError] = useState('')
  const [selected, setSelected] = useState('')
  const [type, setType] = useState('video/mp4')

  useEffect(() => {
    let s = answers.filter(answer => answer.selected)
    if(s.length > 0) setSelected(s[0].option)
  }, [answers])

  useEffect(() => {
    ['mp4','ogg','webm'].forEach(type => {
      if(video.includes(type)){
        setType(`video/${type}`)
        return;
      }
    });
  },[video])

  const handleChange = () => {
    let newAnswers = [];
    answers.forEach(answer => {
      if(answer.option === selected){
        newAnswers.push({inciso: answer.inciso, selected: true})
      }else{
        newAnswers.push({inciso: answer.inciso, selected: false})
      }
    })
    if(required && selected === '') {
      setError('Seleccione una respuesta');
      return;
    }
    setAnswers(noQuestion, newAnswers)
    handleNext()
  }

  return (
    <React.Fragment>
      <div>
        <video key={video} controls style={{width: '100%', height: '100%'}}>
          <source src={video} type={type} />
          Tu navegador no implementa el elemento <code>video</code>.
        </video>
      </div>
      <FormControl error={(error !== '')}>
        <RadioGroup aria-label="quiz" value={selected} onChange={(event) => setSelected(event.target.value)}>
          {answers.map(answer => 
            answer.specify 
            ? (<TextField key={answer.inciso} fullWidth />)
            : (<FormControlLabel key={answer.inciso} value={answer.option} control={<Radio />} label={answer.option} />)
            )}
          <FormHelperText>{error}</FormHelperText>
        </RadioGroup>
      </FormControl>
      <div >
        <div>
          <Button
            color="primary"
            onClick={handleChange}
          >
            {'Siguiente Pregunta'}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

TVideo.propTypes = {
  noQuestion: PropTypes.number,
  video: PropTypes.string,
  answers: PropTypes.array,
  setAnswers: PropTypes.func,
  required: PropTypes.bool,
  handleNext: PropTypes.func
};


