import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { format, add, isAfter } from "date-fns";
import { Grid, Box, Chip, TextField, MenuItem } from "@material-ui/core";
// @material-ui/icons
import { Person, EventAvailable, AddAlert } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { notify } from "components/Snackbar/Notifier";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardIcon,
} from "components/Card";
import Button from "components/CustomButtons/Button";
import PaypalButton from "components/Paypal/PaypalButton";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import CompanyService from "Services/CompanyService";
import PaymentService from "Services/PaymentService";
import Company from "Class/Company";
import Payment from "Class/Payment";
import Error from "../Error/404";

// @ts-ignore
export default function () {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();
  const formRef = useRef<HTMLFormElement>(null);
  const [company, setCompany] = useState(new Company());
  const [updatePayment, setUpdatePayment] = useState(false);
  const [showPaypal, setShowPaypal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(200);
  const [month, setMonth] = useState(1);
  const [usersNumber, setUsersNumber] = useState(1);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [status, setStatus] = useState(200);
  const [proration, setProration] = useState("");

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    setUsersNumber(company.usersNumber);
    calculateTotal(company.usersNumber, month);
  }, [company]);

  async function getCompany() {
    try {
      const response = await CompanyService.getDetails();
      setCompany(response);
      setUsersNumber(response.usersNumber);
    } catch (e) {
      setStatus(e.status);
    }
  }
  const onFormSubmit = (values: any) => {
    setShowPaypal(true);
  };

  const onSubmit = () => {
    if (usersNumber < company.usersNumber && today.getTime() <= new Date(`${company.validity}`).getTime()) {
      notify(
        "danger",
        "No puedes reducir el numero de usuarios (espera a que termine tu periodo de pago)"
      );
      return;
    }

    let el = formRef.current;
    if (el && el !== null) {
      el.dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  const handleRegister = async (approved: boolean, orderId: string) => {
    if (approved) {
      try {
        let payment = new Payment({
          company: {
            usersNumber,
            validity: getValidity(company.validity, true),
          },
          orderId,
        });
        await PaymentService.savePayment(payment);
        notify("success", "Su pago se registró con exito");
        history.push("/admin/payments");
      } catch (e) {
        if (e.detail) notify("danger", e.detail);
        else notify("danger", e.error);
      }
    } else {
      notify(
        "danger",
        "Ocurrio un error al procesar el pago, intente de nuevo"
      );
    }
  };

  const handleChangeMonth = (value: number) => {
    setMonth(value);
    calculateTotal(usersNumber, value);
  };

  const today = new Date();

  const handleChangeUsers = (usersNumber: number) => {
    if (usersNumber < 0) return;

    setUsersNumber(usersNumber);
    calculateTotal(usersNumber, month);
  };

  const calculateTotal = (usersNumber: number, months: number) => {
    let total = 0;
    if (usersNumber > company.usersNumber) {
      const validity = new Date(`${company.validity}`);
      const usersDiff = usersNumber - company.usersNumber;

      const nextValidity = new Date(
        today.getFullYear(),
        today.getMonth() + (today.getDate() >= validity.getDate() ? 1 : 0),
        validity.getDate()
      );

      const pendingWeeks = Math.ceil(
        (nextValidity.getTime() - today.getTime()) / 1000 / 60 / 60 / 24 / 7
      );

      let monthsDiff =
        validity.getFullYear() * 12 +
        validity.getMonth() -
        (today.getFullYear() * 12 + today.getMonth());

      let pendingMonth = 0;
      let pendingMonthPrice = 0;

      if (pendingWeeks < 4) {
        switch (pendingWeeks) {
          case 1:
            pendingMonthPrice = 50;
            break;
          case 2:
            pendingMonthPrice = 100;
            break;
          case 3:
            pendingMonthPrice = 150;
            break;
        }

        pendingMonth = usersDiff * pendingMonthPrice;
      }

      const subtotal = usersDiff * monthsDiff * 200;
      total = subtotal + pendingMonth + usersNumber * months * 200;

      setProration(`
        ${pendingMonth > 0 ? `
        <div class="h4 font-weight-light">
          <span class="font-weight-bold">
            ${usersDiff}
          </span> 
          Usuarios nuevos hasta proximo mes = 
          <span class="font-weight-bold"> 
            $${pendingMonth} + 
          </span> 
        </div>
        <hr />
        ` : ""}
        ${monthsDiff > 0 ? `
        <div class="h4 font-weight-light">
          <span class="font-weight-bold">
            ${usersDiff}
          </span> 
          Usuarios nuevos por
          <span class="font-weight-bold"> 
            ${monthsDiff}
          </span> meses = 
          <span class="font-weight-bold"> 
            $${subtotal} + 
          </span> 
        </div>
        <hr />
        ` : ""}
        <div class="h4 font-weight-light">
          <span class="font-weight-bold">
            ${usersNumber}
          </span> 
          Usuarios por
          <span class="font-weight-bold"> 
            ${months}
          </span> meses = 
          <span class="font-weight-bold"> 
            $${usersNumber * months * 200} 
          </span> 
        </div>
        <hr />

        <div class="h3 font-weight-light">
          Total: 
          <span class="font-weight-bold"> 
            $${total}
          </span>
        </div>
        `);
    } else {
      total = usersNumber * months * 200;
      setProration(`
        <div class="h4 font-weight-light">
          <span class="font-weight-bold">
            ${usersNumber}
          </span> 
          Usuarios por
          <span class="font-weight-bold"> 
            ${months}
          </span> meses = 
          <span class="font-weight-bold"> 
            $${total} 
          </span> 
        </div>
        <hr />
        <div class="h3 font-weight-light">
          Total: 
          <span class="font-weight-bold"> 
            $${total}
          </span>
        </div>
      `);
    }

    setTotalAmount(total);
  };

  const handleReset = () => {
    setShowPaypal(false);
    setUpdatePayment(false);
  };

  const getValidity = (validity: Date | null, forSave = false) => {
    let newValidity = new Date();
    if (validity !== null && isAfter(new Date(validity), newValidity)) {
      newValidity = new Date(validity);
    }
    newValidity = add(newValidity, { months: month });
    newValidity = new Date(newValidity.getFullYear(), newValidity.getMonth(), newValidity.getDate(), 23, 59, 0);
    return forSave
      ? newValidity
      : format(newValidity, "dd/MM/yyyy");
  };

  if (status !== 200) return <Error status={status} />;

  return (
    <Grid container>
      <Card>
        <CardHeader color="primary" stats>
          <h3>Información</h3>
          <p>Detalles</p>
        </CardHeader>
        <CardBody>
          <Grid container spacing={10}>
            <Grid item md={6} xs={12}>
              <Box bgcolor="text.disabled" color="background.paper" p={2}>
                <CardIcon color="info">
                  <Person />
                </CardIcon>
                Numero de usuarios Permitidos
                <Grid container justify="center" alignItems="center">
                  <Chip
                    color="primary"
                    label={
                      company.usersNumber > 0
                        ? company.usersNumber
                        : "Indefinido"
                    }
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box bgcolor="text.disabled" color="background.paper" p={2}>
                <CardIcon color="info">
                  <EventAvailable />
                </CardIcon>
                Vigencia
                <Grid container justify="center" alignItems="center">
                  <Chip
                    color="primary"
                    label={
                      company.validity !== null
                        ? format(new Date(company.validity), "dd/MM/yyyy")
                        : "Indefinida"
                    }
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {company.validity !== null &&
                (new Date() > new Date(company.validity) ? (
                  <SnackbarContent
                    message="¡Su cuenta ah vencido!. Para poder seguir utilizando la App Encuesta, actualice la Vigencia"
                    color="danger"
                    icon={AddAlert}
                  />
                ) : null)}
            </Grid>
          </Grid>
          {!updatePayment && (
            <Grid container spacing={10} justify="center" alignItems="center">
              <Grid item>
                <Button color="warning" onClick={() => setUpdatePayment(true)}>
                  Actualizar Vigencia
                </Button>
              </Grid>
            </Grid>
          )}
        </CardBody>
      </Card>
      {updatePayment && (
        <React.Fragment>
          <Card>
            <CardBody>
              <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="number"
                      name="usersNumber"
                      label="Número de Usuarios"
                      value={usersNumber}
                      onChange={(event) =>
                        handleChangeUsers(+event.target.value)
                      }
                      inputRef={register({ required: true, min: 1 })}
                      placeholder="Ingrese la Cantidad de usuarios"
                      helperText={
                        errors.usersNumber ? "Solo números mayores a 1" : ""
                      }
                      error={errors.usersNumber ? true : false}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      select
                      fullWidth
                      name="monthsNumber"
                      label="Cantidad de Meses"
                      value={month}
                      inputRef={register}
                      placeholder="Ingrese la cantidad de meses"
                      onChange={(event) =>
                        handleChangeMonth(+event.target.value)
                      }
                    >
                      {months.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="text"
                      label="Vigencia"
                      value={getValidity(company.validity)}
                      placeholder="Vigencia"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="number"
                      name="cost"
                      label="Costo"
                      value={totalAmount}
                      placeholder="Costo total"
                    />
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="primary" stats>
              <h3>Detalles de compra</h3>
            </CardHeader>
            <CardBody>
              <div className='text-left' dangerouslySetInnerHTML={{ __html: proration }} />
              {showPaypal && (
                <Grid container spacing={3} justify="center">
                  <Grid item md={6}>
                    <PaypalButton
                      amount={totalAmount}
                      onRegister={handleRegister}
                    />
                  </Grid>
                </Grid>
              )}
            </CardBody>
            <CardFooter>
              <Grid container justify="flex-end">
                {!showPaypal && (
                  <Button color="info" type="submit" onClick={onSubmit}>
                    Confirmar
                  </Button>
                )}
                <Button onClick={() => handleReset()}>Cancelar</Button>
              </Grid>
            </CardFooter>
          </Card>
        </React.Fragment>
      )}
    </Grid>
  );
}
