import React, {useEffect} from "react"
import { makeStyles } from "@material-ui/core/styles";
import {Grid, TextField, IconButton, Tooltip} from '@material-ui/core'
import PropTypes from "prop-types"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Close from "@material-ui/icons/Close";
import AddCircle from '@material-ui/icons/AddCircle'

import styles from 'assets/jss/material-dashboard-react/components/tasksStyle.js';

const useStyles = makeStyles(styles);

export default function TCheckBox(props) {
  const {getJson, answers } = props;
  const classes = useStyles();
  const categoryId = 1
  const type = 'TCheckBox'

  useEffect(() => {
    if(answers.length <= 0){
      getJson(asJSON([{option: 'Opción 1'}]));
    }
  }, [answers, getJson])

  const asJSON = (newAnswers) => {
    newAnswers.map((item, index) => {
      item['inciso'] =  (index+10).toString(36)
      item['selected'] = false
      return item
    });
    return {categoryId,type,answers: newAnswers} ;
  }

  async function handleAddOption(){
    getJson(asJSON([...answers,{option: 'Opción '+(answers.length+1)}]));
  }
  function handleRemoveOption(index){
    let newAnswers = [...answers];
    newAnswers.splice(index,1);
    getJson(asJSON(newAnswers));
  }

  function handleChangeOption(optionText, index){
    let newAnswers = [...answers];
    if(optionText.trim().length > 0)
      newAnswers[index].option = optionText
    else
      newAnswers[index].option = 'Opción '+(index+1)
    getJson(asJSON(newAnswers));
  }

  return (
    <Grid container spacing={1}
      justify="center"
      alignItems="center">

      {answers.map((option, index) => {
        return (
         <Grid container key={index} spacing={1}>
            <Grid item>
              <CheckBoxOutlineBlankIcon />
            </Grid>
            <Grid item md={8}>
              <TextField 
                fullWidth
                defaultValue={option.option}
                InputProps={{onBlur: (event) => handleChangeOption(event.target.value, index) }} />
            </Grid>
            <Grid item md={2}>
            {answers.length > 1 && (
              <Tooltip
                title="Eliminar opción"
                placement="top"
                classes={{tooltip:classes.tooltip}}>
                <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={()=> handleRemoveOption(index) }>
                  <Close className={classes.tableActionButtonIcon + " " + classes.close}/>
                </IconButton>
              </Tooltip>
              )
            }
            </Grid>
          </Grid>
        )
      })}
      <Grid item md={12}>
        <Tooltip
          title="Agregar opción"
          placement="top"
          classes={{tooltip:classes.tooltip}}>
          <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => handleAddOption()}>
            <AddCircle className={classes.tableActionButtonIcon + " " + classes.edit}/>
          </IconButton>
        </Tooltip>
      </Grid>

    </Grid>
  );
}

TCheckBox.propTypes = {
  getJson: PropTypes.func,
};
