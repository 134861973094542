import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import { Route, Router, Switch} from "react-router-dom";
// core components
import Admin from "layouts/Admin.js";
import Public from 'layouts/Public'


//import "assets/css/material-dashboard-react.css";
import './assets/css/bootstrap.css'
import './assets/css/theme-style.css'
import './assets/css/colour-blue.css'

//import 'assets/css/custom.css'

import SignIn from "pages/Login/Login";
import Register from 'pages/Login/Register';
import Privacy from 'pages/Public/Privacy';
import SurveyShow from 'pages/Surveys/SurveyShow';
const hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/login" component={SignIn}/>
            <Route path="/admin" component={Admin}/>
            <Route path="/register" component={Register} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/survey_web/:uuid" component={SurveyShow} />
            <Route path="/" component={Public} />
        </Switch>
    </Router>,
    document.getElementById("root")
);
