import React, {useEffect, useState} from "react"
import { format, parseISO } from 'date-fns'
import { Container, Grid, TableContainer, Table, TableHead, TableBody,
         TablePagination, TableRow, TableCell, IconButton } from "@material-ui/core"
         // @material-ui/icons
import {Visibility} from "@material-ui/icons"
import {notify} from 'components/Snackbar/Notifier'
import { Card, CardHeader, CardBody } from "components/Card"
import Backdrop from 'components/Backdrop/Backdrop'
import Dialog from 'components/Dialog/Dialog'
import Tooltip from 'components/Tooltip/Tooltip'
import Chip from 'components/Chip/Chip'
//import Confirm from 'components/AlertDialog/AlertDialog'
import Payment from 'Class/Payment'
import PaymentService from "Services/PaymentService"
import {isSuper} from 'Services/Auth'
import Error from '../Error/404'

// @ts-ignore
export default function () {
  const [payments, setPayments] = useState(new Array<Payment>(0))
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(10)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(200)
  const initialDialog = {
    open: false,
    title: '',
    body: <div></div>
  }
  const [dialog, setDialog] = useState(initialDialog)
  /*
  const initialConfirm = {
    open: false,
    title:'',
    body:'',
    action: () =>{}
  }
  const [confirm,setConfirm] = useState(initialConfirm)
  */
  useEffect(() => {
    getPayments(page, rows)
  }, [page,rows])

  function paymentDetails(payment: Payment) {
    return (
      <Container component="main" maxWidth="md">
        <Card>
          <CardBody>
            <h4>Información proporcionada por PAYPAL</h4>
            <p>Fecha y hora: {format(parseISO(payment.dataPaypal.create_time), 'dd/MM/yyyy hh:mm:ss') }</p>
            <p>Identificador de Pago: {payment.dataPaypal.id}</p>
            <p>Status: {payment.dataPaypal.status}</p>
            <p>Correo Electrónico: {payment.dataPaypal.payer.email_address}</p>
            <p>Nombre Completo: {payment.dataPaypal.payer.name.given_name} {payment.dataPaypal.payer.name.surname}</p>
          </CardBody>
        </Card>
      </Container>
    );
  }
 /*
  function handleOpenConfirm(confirmState: any){
    setConfirm({open: true,...confirmState})
  }
 */
  function handleOpenDialog(dialogState: any){
    setDialog({open: true, ...dialogState})
  }


  async function getPayments(page: number, elements: number) {
    setLoading(true)
    try {
      const response = await PaymentService.getPayments(page, elements)
      const newPayments = response.data.map((item: Object) => new Payment(item))
      setPayments(newPayments)
      setPage(+response.pagination.current_page)
      setTotal(+response.pagination.total_elements)
    } catch (e) {
      setStatus(e.status)
    }
    setLoading(false)
  }

  async function handleGetPayment(id: number){
    try {
      const response = await PaymentService.getPayment(id)
      handleOpenDialog({title: 'Detalle de Pago',body: paymentDetails(response)})
    } catch (e) {
      if(e.status !== 400) setStatus(e.status)
      else notify('danger',e.error)
    }
  }

  function generateCells() {
    return payments.map(payment => {
      return <TableRow hover={true} key={payment.id}>
        <TableCell>{`$ ${payment.amount}`}</TableCell>
        <TableCell>
          <Chip size="small" color="info" label={payment.status}/>
        </TableCell>
        <TableCell>{format(new Date(payment.createdAt), 'dd/MM/yyyy')}</TableCell>
        { isSuper() && 
          <TableCell>{payment.company?.name}</TableCell>
        }
        <TableCell>{payment.user !== null ? payment.user.fullName() : ''}</TableCell>
        <TableCell>
          <Tooltip title="Mostrar más Detalles" >
            <IconButton onClick={() => handleGetPayment(payment.id) }>
              <Visibility color="secondary" style={{fontSize: 18}}/>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    })
  }

  if(status !== 200) return <Error status={status} />

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Grid>
        <Dialog
          title={dialog.title}
          body={dialog.body}
          isOpen={dialog.open}
          onClose={() => setDialog(initialDialog)}
        />
      </Grid>
      <Card>
        <CardHeader color="primary" stats>
          <h3>Pagos</h3>
          <p>Lista de Pagos Realizados</p>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Monto Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Fecha</TableCell>
                  { isSuper() && <TableCell>Empresa</TableCell> }
                  <TableCell>Usuario</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generateCells()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1) }
            page={page - 1}
            labelRowsPerPage={'Pagos por pagina'}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value) } />
        </CardBody>
      </Card>
    </Grid>
  )
}
