import React,{useEffect} from "react"
import {TextField} from '@material-ui/core'
import PropTypes from "prop-types"

export default function TComment(props) {
  const {answers, getJson} = props;
  const categoryId = 1;
  const type = 'TComment';

  useEffect(() => {
    if(answers.length <= 0){
      getJson({categoryId,type,answers: [{selected: ''}]})
    }
  }, [answers, getJson])

  return (
    <TextField
      disabled
      multiline
      rows={2}
      fullWidth
      type="text"
      label="Texto de respuesta larga"
    />
  );
}

TComment.propTypes = {
  getJson: PropTypes.func,
};
