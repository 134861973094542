import React, {useState, useEffect} from 'react';
//import Button from '@material-ui/core/Button';
import {Dialog,DialogTitle, DialogActions,DialogContent, CircularProgress } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'

export default function AlertDialog(props) {
  const { open,title,body,onSubmit,onClose } = props
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!open)
  },[open])

  const handleClick = () => {
    setLoading(true)
    onSubmit()
  }

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {body}
          {loading && <CircularProgress value={100} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="danger">
            Aceptar
          </Button>
          <Button onClick={onClose} >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}