import React from "react"
import {TextField} from '@material-ui/core'
//import PropTypes from "prop-types"
import Joi from '@hapi/joi';

let email_schema;

export default function TEmail(props) {
  const { answers, required, register, errors} = props;

  email_schema = Joi.object({
    answer: required ? Joi.string().email({ minDomainSegments: 2, tlds: { allow: false }}).messages({
      'string.empty': 'Su correo electrónico es obligatorio.',
      'string.email': 'Correo electrónico inválido'
    }) : Joi.string().email({ minDomainSegments: 2, tlds: { allow: false }}).allow('').messages({
      'string.email': 'Correo electrónico inválido'
    })
  });

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      name="answer"
      error={errors.answer ? true : false}
      helperText={errors.answer?.message}
      defaultValue={answers[0].selected}
      inputRef={register}
    />
  );
}

export function emailSchema() {
  return email_schema;
}
/*
TEmail.propTypes = {
  setAnswer: PropTypes.func,
};
*/

