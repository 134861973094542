import React from "react";
import {Redirect, Switch} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import {makeStyles, ThemeProvider } from "@material-ui/core/styles";

// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Notifier from 'components/Snackbar/Notifier';
import Theme from 'assets/jss/theme'

import Routes from "../routes/routes.js";
import PrivateRoute from '../routes/PrivateRoute'

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";
import 'assets/css/material-icons.css';

import CampaignsInfo from "../pages/Campaigns/CampaignsInfo";

import Maps from "views/Maps/Maps";
import Answers from "pages/Answers/Answers";
import Reports from 'pages/Answers/Reports'
import Survey from 'pages/Surveys/SurveyEdit'
import Emails from 'pages/Surveys/Emails'

let ps;

const switchRoutes = (
    <Switch>
        <PrivateRoute path={"/admin/campaigns/:campaignId/addSurvey"} component={Survey}/>
        <PrivateRoute path={"/admin/campaigns/:campaignId"} component={CampaignsInfo}/>
        <PrivateRoute path={"/admin/map/:options?"} component={Maps}/>
        <PrivateRoute path={"/admin/survey/:surveyId/reports/:filterDay?"} component={Reports}/>
        <PrivateRoute path={"/admin/survey/:surveyId/answers"} component={Answers}/>
        <PrivateRoute path={"/admin/survey/:surveyId/emails"} component={Emails}/>
        <PrivateRoute path={"/admin/survey/:surveyId"} component={Survey}/>
        {Routes.map((prop, key) => {
          if (prop.layout === "/admin") {
            
              return (
                <PrivateRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
        return null;
        })}
        <Redirect from="/admin" to="/admin/campaigns"/>
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({...rest}) {
    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    
    // states and functions
    //const [image, setImage] = React.useState(bgImage);
    //const [color, setColor] = React.useState("blue");
    //const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
       <ThemeProvider theme={Theme}>
       <Notifier />
        <div className={classes.wrapper}>
            <Sidebar
                routes={Routes}
                logoText={"Encuestas"}
                logo={logo}
                image={bgImage}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={"blue"}
                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar
                    routes={Routes}
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                />
                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <div className={classes.content + ' bg-primary-faded bg-op-1'}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                )}
                {getRoute() ? <Footer/> : null}

            </div>
        </div>
         </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
}
