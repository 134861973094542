import React, {useEffect} from 'react';
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from "components/CustomButtons/Button";

export default function ScrollDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const { buttonName,title,body,isOpen, onSubmit, onClose } = props

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      {buttonName && 
        <Button color="primary" onClick={handleClickOpen('paper')}>{buttonName}</Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullWidth={true}
        maxWidth={"md"}
        disableBackdropClick={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <Grid container justify='center'>
            {body}
          </Grid>
        </DialogContent>
        <DialogActions>
          {onSubmit && (
            <Button
              color="info"
              type="submit"
              onClick={onSubmit}>
              Guardar
            </Button>
          )}
          <Button onClick={handleClose}>
            {onSubmit ? 'Cancelar' : 'Cerrar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
