import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom"

import {Container} from '@material-ui/core'
import { Card, CardHeader, CardBody } from 'components/Card'
import Backdrop from 'components/Backdrop/Backdrop'

import SurveyService from 'Services/SurveyService'
import AnswersService from 'Services/AnswersService'
import Survey from 'Class/Survey'

import AnswerFactory from 'components/AnswerComponents/AnswerFactory'

// @ts-ignore
export default function SurveyShow() {
  const {uuid} = useParams()
  const [survey, setSurvey] = useState(new Survey())
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const [activeStep, setActiveStep] = useState(0)
  const [backList, setBackList] = useState([0])

  useEffect(() => {
    handleGetSurvey(uuid)
  },[uuid])

  const handleActive = (active: number) => {
    setBackList([...backList,activeStep])
    if(active) setActiveStep(active)
    else setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    let b_tmp = backList.pop();
    
    if(b_tmp){
      setActiveStep(b_tmp)
    }else {
      setActiveStep(0)
    }
  }

  async function handleGetSurvey(uuid: string) {
    setLoading(true);
    try {
      const response = await SurveyService.getSurveyByUuid(uuid)
      setSurvey(response)
    } catch (e) {
      console.log('error: ', e)
      setError(e)
    }
    setLoading(false);
  }

  const handleSend = async () => {
    setLoading(true);
    try{
      let answers_send: any = {uuid, surveyId: survey.id}
      let jsonR = [...survey.questionsJson]
      jsonR.forEach(questionJson => {
        if(questionJson.type === 'TImageOption') {
          questionJson.answers.forEach((answer: any) => {
            answer['option'] = ''
          })
        }
        if(questionJson.type === 'TImage') {
          questionJson.imagen = ''
        }
      })
      answers_send["answersJson"] = jsonR
      console.log(answers_send)
      const response = await AnswersService.saveAnswer(answers_send)
      console.log(response)
      setError('Gracias por responder esta Encuesta')
    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  }

   return (
    <React.Fragment>
      {isLoading && <Backdrop open={isLoading} />}
      <div className="py-4 pt-md-6 py-md-5 bg-primary-faded bg-op-1">
        <Container maxWidth="md">
          <Card>
            <CardHeader color="success">
              <h4>{survey.name}</h4>
              <h3>{survey.description}</h3>
            </CardHeader>
            <CardBody>
              {error !== '' ? (<p className="danger-text">{error}</p>)
                : (
              <div>
                {survey.questionsJson.length > 0 
                  && <AnswerFactory 
                      questionJson={survey.questionsJson[activeStep]}
                      handleBack={handleBack}
                      handleActive={handleActive}
                      handleSend={handleSend}
                      total={survey.questionsJson.length} />}
              </div>
              )
            }
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
    );
}