import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import { Card, CardHeader, CardBody } from "components/Card";
import Backdrop from "components/Backdrop/Backdrop";
import { notify } from "components/Snackbar/Notifier";
import Dialog from "components/Dialog/Dialog";
import Confirm from "components/AlertDialog/AlertDialog";
import Button from "components/CustomButtons/Button";
import Tooltip from "components/Tooltip/Tooltip";
// @material-ui/icons
import { Edit, Visibility, Delete } from "@material-ui/icons";

import Campaign from "Class/Campaign";
import CampaignService from "Services/CampaignService";
import { isSuper, isSuperAdmin } from "Services/Auth";
import CampaignForm from "./CampaignForm";
import CompanyFilter from "pages/Companies/CompanyFilter";
import Error from "../Error/404";
import AdminFilter from "pages/Users/AdminFilter";

// @ts-ignore
export default function () {
  const history = useHistory();
  const formRef = useRef<any>(null);

  const [campaigns, setCampaigns] = useState(new Array<Campaign>(0));
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(0);
  const [adminId, setAdminId] = useState(0);

  const initialDialog = {
    open: false,
    title: "",
    body: <div></div>,
  };
  const [dialog, setDialog] = useState(initialDialog);
  const initialConfirm = {
    open: false,
    title: "",
    body: "",
    action: () => {},
  };
  const [confirm, setConfirm] = useState(initialConfirm);
  const [status, setStatus] = useState(200);

  useEffect(() => {
    getCampaigns(page, rows, companyId, adminId);
  }, [page, rows, companyId, adminId]);

  function campaignForm(campaign: Campaign) {
    return (
      <Container component="main" maxWidth="md">
        <CampaignForm
          ref={formRef}
          campaign={campaign}
          onSubmit={handleCampaignSave}
        />
      </Container>
    );
  }

  function handleOpenConfirm(confirmState: any) {
    setConfirm({ open: true, ...confirmState });
  }

  function handleOpenDialog(dialogState: any) {
    setDialog({ open: true, ...dialogState });
  }

  const handleSubmitForm = () => {
    if (formRef && formRef !== null) {
      formRef.current.formSubmit();
    }
  };

  async function getCampaigns(
    page: number,
    elements: number,
    companyId: number,
    adminId: number
  ) {
    setLoading(true);
    try {
      const response = await CampaignService.getCampaigns(
        page,
        elements,
        companyId,
        adminId
      );
      setCampaigns(response.data);
      setPage(+response.pagination.current_page);
      setTotal(+response.pagination.total_elements);
    } catch (e) {
      setStatus(e.status);
    }
    setLoading(false);
  }

  async function handleGetCampaign(id: number) {
    try {
      const response = await CampaignService.getCampaign(id);
      handleOpenDialog({
        title: "Editando Campaña",
        body: campaignForm(response),
      });
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function handleCampaignSave(campaign: Campaign) {
    try {
      await CampaignService.saveCampaign(campaign);
      getCampaigns(page, rows, companyId, adminId);
      setDialog(initialDialog);
      notify("success", "Campaña guardada con exito");
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function removeCampaign(campaignId: number) {
    try {
      await CampaignService.removeCampaign(campaignId);
      getCampaigns(page, rows, companyId, adminId);
      setConfirm(initialConfirm);
      notify("success", "Campaña eliminada con exito");
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  function generateCells() {
    return campaigns.map((campaign) => {
      return (
        <TableRow hover={true} key={campaign.id}>
          <TableCell>{campaign.name}</TableCell>
          <TableCell>
            {format(new Date(campaign.createdAt), "dd/MM/yyyy")}
          </TableCell>
          {isSuper() && <TableCell>{campaign.company?.name}</TableCell>}
          <TableCell>
            <Tooltip title="Mostrar Encuestas">
              <IconButton
                size="small"
                onClick={() =>
                  history.push(`${history.location.pathname}/${campaign.id}`)
                }
              >
                <Visibility color="secondary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar Campaña">
              <IconButton
                size="small"
                onClick={() => handleGetCampaign(campaign.id)}
              >
                <Edit color="primary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar Campaña">
              <IconButton
                size="small"
                onClick={() =>
                  handleOpenConfirm({
                    title: "Eliminar Campaña",
                    body:
                      "Esta acción eliminará todas las encuestas realizadas que pertenecen a esta campaña. ¿Está seguro de eliminar esta Campaña?",
                    action: () => removeCampaign(campaign.id),
                  })
                }
              >
                <Delete color="error" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  }

  if (status !== 200) return <Error status={status} />;

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Grid>
        <Dialog
          title={dialog.title}
          body={dialog.body}
          isOpen={dialog.open}
          onSubmit={handleSubmitForm}
          onClose={() => setDialog(initialDialog)}
        />
        <Confirm
          open={confirm.open}
          title={confirm.title}
          body={<p>{confirm.body}</p>}
          onClose={() => setConfirm(initialConfirm)}
          onSubmit={confirm.action}
        />
      </Grid>
      <Card>
        <CardHeader color="primary" stats>
          <h3>Campañas</h3>
          <p>Lista de campañas disponibles</p>
        </CardHeader>
        <CardBody>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                color="info"
                onClick={() =>
                  handleOpenDialog({
                    title: "Nueva Campaña",
                    body: campaignForm(new Campaign()),
                  })
                }
              >
                Nueva Campaña
              </Button>
            </Grid>
            {isSuperAdmin() && (
              <Grid item xs={12} md={4}>
                <AdminFilter onChange={(id) => setAdminId(id)} isUsersView={false} />
              </Grid>
            )}
            {isSuper() && (
              <Grid item xs={6}>
                <CompanyFilter onChange={(id) => setCompanyId(id)} />
              </Grid>
            )}
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha</TableCell>
                  {isSuper() && <TableCell>Empresa</TableCell>}
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{generateCells()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1)}
            page={page - 1}
            labelRowsPerPage={"Campañas por pagina"}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value)}
          />
        </CardBody>
      </Card>
    </Grid>
  );
}
