import React, { useState, useEffect } from 'react'
import { TextField, MenuItem } from '@material-ui/core'
import { ICompany } from 'Class/Company'
import CompanyService from 'Services/CompanyService'
import { isSuper } from 'Services/Auth'

type CompanyFilterProps = {
  onChange: (id: number) => void
}

const CompanyFilter = ({ onChange }: CompanyFilterProps) => {
  const [companyId, setCompanyId] = useState(0)
  const [companies, setCompanies] = useState(new Array<ICompany>({ id: 0, name: 'Todas'}))

  useEffect(() => {
    if (isSuper()) getCompanies()
  }, [])

  const getCompanies = async () => {
    try {
      const response = await CompanyService.listAllCompanies(0,0)
      const companies = response.data
      companies.unshift({ id: 0, name: 'Todas'})
      setCompanies(companies)
    } catch (e) {
      console.log('Error getting companies: ', e)
    }
  }

  const handleChangeCompany = (id: number) => {
    setCompanyId(id)
    onChange(id)
  }

  if (!isSuper()) return <div></div>

  return (
    <TextField
      fullWidth
      select
      label="Filtro por Empresa"
      value={companyId}
      onChange={(event) => handleChangeCompany(+event.target.value)}
      variant="outlined"
    >
      {companies.map((company) => (
        <MenuItem key={company.id} value={company.id}>
          {company.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default CompanyFilter