import Role from './Rol'
import Campaign from './Campaign'
import Company from './Company'

export interface IUser {
  id: number,
  name: string
}

export class UserName {
  firstName: string = ''
  lastName: string = ''
  motherLastName: string = ''
  constructor(properties: any = {}){
    if(properties !== null) {
      this.firstName = properties.firstName 
      this.lastName = properties.lastName
      this.motherLastName = properties.motherLastName
    }
  }
}

export default class User extends UserName {
  id: number
  email: string
  blocked: boolean
  role: Role
  currentCampaign: Campaign
  company: Company
  token: string = ''
  password: string
  usersNumber: number
  constructor(properties: any = {}) {
    super({
      firstName: properties.firstName,
      lastName: properties.lastName,
      motherLastName: properties.motherLastName
    })
    this.id = properties.id || 0
    this.email = properties.email || ''
    this.blocked = properties.blocked || false
    this.role = properties.role || new Role()
    this.currentCampaign = properties.currentCampaign || new Campaign()
    this.company = properties.company || new Company()
    this.password = properties.password || ''
    this.usersNumber = properties.usersNumber || 0
  }

  fullName(): string{
    return `${this.firstName} ${this.lastName} ${this.motherLastName || ''}`;
  }
  asJSON(){
    let data: any = {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      motherLastName: this.motherLastName,
      role: this.role.priority,
      companyId: this.company.id
    }
    if(this.password.length > 0){
      data["password"] = this.password
    } 
    return data
  }
  isSuper(){
    return this.role.priority === 2
  }
  isAdmin(){
    return this.role.priority === 3
  }
  isSuperAdmin(){
    return this.role.priority === 4
  }
}
