import Client from './Client'
import User from 'Class/User'
import {isSuper, isSuperAdmin} from 'Services/Auth'

const resource = '/users'

export default class UserService {
  public static async getUsers(page: number, size: number, search: string = '', companyId: number, adminId: number, onlyAdmins: boolean){
    try {
      let url = `${resource}?page=${page}&page_size=${size}`;
      if(search.trim().length > 0) url += `&search=${search}`
      if (companyId) url += `&companyId=${companyId}`
      if (adminId) url += `&adminId=${adminId}`
      if (onlyAdmins) url += `&onlyAdmins=${onlyAdmins}`
      const { data } = await Client.get(url)
      return data
    } catch(e){
      throw e
    }
  }

  public static async getUser(id: number) {
    try {
      const { data } = await Client.get(`${resource}/${id}`)
      return new User(data.data)
    } catch(e){
      throw e
    }
  }

  public static async setUsers(id: number, total: number) {
    try {
      const { data } = await Client.put(`${resource}/${id}/users_number`, { total })
      return data
    } catch(e){
      throw e
    }
  }

  public static async getAdmins() {
    try {
      const { data } = await Client.get(`${resource}/admins`)
      return data.data
    } catch(e){
      throw e
    }
  }

  public static async getUserByEmail(email: string) {
    try {
      const { data } = await Client.get(`${resource}/search?email=${email}`)
      return data
    } catch(e){
      throw e
    }
  }

   public static async createUser(user: User) {
    try {
      const { data } = await Client.post(`${resource}`, user.asJSON())
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async updateUser(user: User) {
    try {
      const { data } = await Client.put(`${resource}/${user.id}`, user.asJSON())
      if(isSuper() || (isSuperAdmin() && [1,3,4].includes(user.role.priority)))
        await Client.put(`${resource}/update_role`, {"userId": user.id, "role": user.role.priority })
      return data
    } catch (e) {
      throw e;
    }
  }

   public static async removeFromCampaign(campaignId: number, userId: number) {
    try {
      const { data } = await Client.post(`${resource}/remove_from_campaign`, {campaignId: campaignId, userId: userId})
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async blockUser(userId: number, blocked: boolean){
    try{
      if(blocked){
        await Client.put(`${resource}/${userId}/unblock`)
        return 'Usuario desbloqueado con exito'
      }else{
        await Client.put(`${resource}/${userId}/block`)
        return 'Usuario bloqueado con exito'
      }
    } catch (e){
      throw e
    }
  }

  public static async deleteUser(userId: number) {
    try {
      const { data } = await Client.delete(`${resource}/${userId}`)
      return data.data.message
    } catch (e) {
      throw e;
    }
  }

}
