import Company from './Company'

export default class Campaign {
  id: number
  name: string
  company: Company
  createdAt: Date
  constructor(properties: any = {}){
    this.id = properties.id || 0
    this.name = properties.name || ''
    this.company = properties.company || new Company()
    this.createdAt = properties.createdAt || new Date()
  }
  asJSON(){
    return {
      name: this.name,
      companyId: this.company.id
    }
  }
}


/*
export class CampaingsClass implements Campaigns {
    company: Company = new CompanyClass();
    createdAt: Date = new Date();
    id: string = '';
    name: string = '';

}

export class CompanyClass implements Company {
    id: number = 0;
    name: string = '';

}

export class PaginationClass implements Pagination {
    current_page: number = 0;
    page_size: number = 0;
    skip: number = 0;
    total_pages: number = 0;
    total_elements: number = 0;

}

export class MemberClass implements Member {
    bloked: boolean = false;
    firstName: string = "";
    id: number = 0;
    lastName: string = "";
    members: MemberInfo[] = [];
    motherLastName: string = "";
    role: Role = new RoleClass();

}
export class SurveyClass implements Survey{
    createdAt: Date = new Date();
    id: number = 0;
    name: string ='';
    questionsJson: QuestionsJson[] = [];
    surveyJson: SurveyJson = new SurveyJsonClass();
    version: number = 0;

}
class QuestionsJsonClass implements QuestionsJson{
    answers: [] = [];
    categoryId: number = 0;
    id: number = 0;
    instructions: string ="";
    noQuestion: number = 0;
    question: string = "";
    type: string ="";

}
class SurveyJsonClass implements SurveyJson{
    backcolor: string = '';
    description: string = '';
    fontColor: string = '';
    icon: string = '';
    id: number = 0;
    imageColor: string = '';
    name: string = '';

}
*/
