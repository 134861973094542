import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  InputAdornment,
  Collapse,
} from "@material-ui/core";
// @material-ui/icons
import {
  Edit,
  Done,
  Block,
  DeleteForever,
  Close,
  Search,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { Card, CardHeader, CardBody } from "components/Card";
import { notify } from "components/Snackbar/Notifier";
import Dialog from "components/Dialog/Dialog";
import Confirm from "components/AlertDialog/AlertDialog";
import Backdrop from "components/Backdrop/Backdrop";
import Tooltip from "components/Tooltip/Tooltip";
import Button from "components/CustomButtons/Button";
import UserService from "Services/UserService";
import { isSuper, isSuperAdmin } from "Services/Auth";
import UserForm from "./UserForm";
import User from "Class/User";
import Error from "../Error/404";
import CompanyFilter from "pages/Companies/CompanyFilter";
import AdminFilter from "./AdminFilter";

// @ts-ignore
export default function () {
  const formRef = useRef<any>(null);
  const searchRef = useRef<any>(null);
  const [users, setUsers] = useState(new Array<User>(0));
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(25);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(200);
  const [companyId, setCompanyId] = useState(0);
  const [adminId, setAdminId] = useState(0);
  const [onlyAdmins, setOnlyAdmins] = useState(false);

  let timer: null | NodeJS.Timeout = null;

  const initialDialog = {
    open: false,
    title: "",
    body: <div></div>,
  };
  const initialConfirm = {
    open: false,
    title: "",
    body: "",
    action: () => {},
  };
  const [dialog, setDialog] = useState(initialDialog);
  const [confirm, setConfirm] = useState(initialConfirm);

  useEffect(() => {
    getUsers(page, rows, search, companyId, adminId);
  }, [page, rows, search, companyId, adminId, onlyAdmins]);

  function handleOpenConfirm(confirmState: any) {
    setConfirm({ open: true, ...confirmState });
  }
  function handleOpenDialog(dialogState: any) {
    setDialog({ open: true, ...dialogState });
  }

  const handleSubmitForm = () => {
    if (formRef && formRef !== null) {
      formRef.current.formSubmit();
    }
  };

  const doSearch = () => {
    if (searchRef && searchRef !== null) {
      setSearch(searchRef.current.value);
      setPage(1);
    }
  };

  const clearSearch = () => {
    setSearch("");
    setPage(1);
    if (searchRef && searchRef !== null) {
      searchRef.current.value = "";
    }
  };

  const handleMouseDownSearch = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  function userForm(user: User) {
    return (
      <Container component="main" maxWidth="md">
        <UserForm ref={formRef} user={user} onSubmit={handleUserSave} />
      </Container>
    );
  }

  async function getUsers(
    page: number,
    elements: number,
    search: string,
    companyId: number,
    adminId: number
  ) {
    setLoading(true);
    try {
      const response = await UserService.getUsers(
        page,
        elements,
        search,
        companyId,
        adminId,
        onlyAdmins
      );
      let usersMap = response.data.map((item: any) => new User(item));
      setUsers(usersMap);
      setPage(+response.pagination.current_page);
      setTotal(+response.pagination.total_elements);
    } catch (e) {
      setStatus(e.status);
    }
    setLoading(false);
  }

  async function handleGetUser(id: number) {
    try {
      const response = await UserService.getUser(id);
      handleOpenDialog({ title: "Editando Usuario", body: userForm(response) });
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function handleUserSave(user: User) {
    try {
      await (user.id !== 0
        ? UserService.updateUser(user)
        : UserService.createUser(user));
      await getUsers(page, rows, search, companyId, adminId);
      setDialog(initialDialog);
      notify("success", "Usuario guardado con exito");
    } catch (e) {
      if (![400, 403].includes(e.status)) setStatus(e.status);
      else notify("danger", e.error);
    }
  }
  async function blockUser(userId: number, blocked: boolean) {
    try {
      console.log("changing block status");
      const message = await UserService.blockUser(userId, blocked);
      await getUsers(page, rows, search, companyId, adminId);
      notify("success", message);
    } catch (e) {
      console.log("error", e);
      if (![400, 403].includes(e.status)) setStatus(e.status);
      else notify("danger", e.error);
    } finally {
      setConfirm(initialConfirm);
    }
  }
  async function deleteUser(userId: number) {
    try {
      const message = await UserService.deleteUser(userId);
      setConfirm(initialConfirm);
      await getUsers(page, rows, search, companyId, adminId);
      notify("success", message);
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  function setUsersNumber(user: User, userId: number, val: number) {
    user.usersNumber = val;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(async () => {
      if (isNaN(val)) return;

      try {
        await UserService.setUsers(userId, val);

        setDialog(initialDialog);
        notify("success", "Usuarios asignados");
      } catch (e) {
        if (![400, 403].includes(e.status)) setStatus(e.status);
        else notify("danger", e.error);
      }
    }, 800);
  }

  function Row(props: { user: any }) {
    const { user } = props;
    const [open, setOpen] = useState(false);

    const showActions = (edit = false) => {
      switch (user.role.description) {
        case "SUPER":
          return isSuper();
        case "SUPERADMON":
          return isSuper() || isSuperAdmin();
        case "ADMON":
          return isSuper() || isSuperAdmin() || edit;
        default:
          return true;
      }
    };

    return (
      <React.Fragment>
        <TableRow hover={true} key={user.id}>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.fullName()}</TableCell>
          <TableCell>
            <span>{user.role.description}</span>
          </TableCell>
          <TableCell>
            {user.role.description === "ADMON" && (
              <TextField
                id="standard-number"
                label="Numero de usuarios"
                type="number"
                variant="outlined"
                defaultValue={user.usersNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(val) =>
                  setUsersNumber(
                    user,
                    user.id,
                    parseFloat(val.currentTarget.value)
                  )
                }
              />
            )}
          </TableCell>
          {isSuper() && <TableCell>{user.company?.name}</TableCell>}
          <TableCell>
            <Tooltip title="Mostrar Campañas">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            {showActions(true) && (
              <Tooltip title="Editar Usuario">
                <IconButton size="small" onClick={() => handleGetUser(user.id)}>
                  <Edit color="primary" style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            )}
            {showActions() && (
              <React.Fragment>
                <Tooltip
                  title={`${user.blocked ? "Desbloquear" : "Bloquear"} Usuario`}
                >
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleOpenConfirm({
                        title: `${
                          user.blocked ? "Desbloquear" : "Bloquear"
                        } Usuario`,
                        body: `¿Está seguro de ${
                          user.blocked ? "Desbloquear" : "Bloquear"
                        } este usuario?`,
                        action: () => blockUser(user.id, user.blocked),
                      })
                    }
                  >
                    {user.blocked ? (
                      <Block color="error" style={{ fontSize: 18 }} />
                    ) : (
                      <Done color="secondary" style={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar Usuario">
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleOpenConfirm({
                        title: "Eliminar Usuario",
                        body:
                          "Esta acción eliminará todas las encuestas realizadas anteriormente por el usuario. ¿Está seguro de eliminar este usuario?",
                        action: () => deleteUser(user.id),
                      })
                    }
                  >
                    <DeleteForever color="error" style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <p>Campañas asignadas:</p>
              <p>{user.currentCampaign?.name}</p>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  if (status !== 200) return <Error status={status} />;

  return (
    <Card>
      <CardHeader color="primary" stats>
        <h3>Usuarios</h3>
        <p>Lista de usuarios</p>
      </CardHeader>
      <CardBody>
        {isLoading && <Backdrop open={isLoading} />}
        <Dialog
          title={dialog.title}
          body={dialog.body}
          isOpen={dialog.open}
          onSubmit={handleSubmitForm}
          onClose={() => setDialog(initialDialog)}
        />
        <Confirm
          open={confirm.open}
          title={confirm.title}
          body={<p>{confirm.body}</p>}
          onClose={() => setConfirm(initialConfirm)}
          onSubmit={confirm.action}
        />

        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12} md={4}>
            <Button
              color="info"
              onClick={() =>
                handleOpenDialog({
                  title: "Nuevo Usuario",
                  body: userForm(new User()),
                })
              }
            >
              Nuevo Usuario
            </Button>
          </Grid>
          {isSuperAdmin() && (
            <Grid item xs={12} md={4}>
              <AdminFilter
                onChange={(id) => {
                  if (id >= 0) {
                    setAdminId(id);
                    setOnlyAdmins(false);
                  } else {
                    setAdminId(0);
                    setOnlyAdmins(true);
                  }
                }}
                isUsersView={true}
              />
            </Grid>
          )}
          {isSuper() && (
            <Grid item xs={12} md={4}>
              <CompanyFilter onChange={(id) => setCompanyId(id)} />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Busqueda"
              placeholder="Buscar por nombre o email"
              defaultValue={search}
              inputRef={searchRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search !== "" && (
                      <IconButton
                        onClick={clearSearch}
                        onMouseDown={handleMouseDownSearch}
                      >
                        <Close color="error" />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      onClick={doSearch}
                      onMouseDown={handleMouseDownSearch}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Correo Electrónico</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell>Usuarios</TableCell>
                {isSuper() && <TableCell>Empresa</TableCell>}
                <TableCell>Campañas</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <Row key={user.id} user={user} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          count={total}
          component="div"
          onChangePage={(event, value) => setPage(value + 1)}
          page={page - 1}
          labelRowsPerPage={"Usuarios por pagina"}
          rowsPerPage={rows}
          onChangeRowsPerPage={(event) => setRows(+event.target.value)}
        />
      </CardBody>
    </Card>
  );
}
