import React,{useState} from "react"
//import PropTypes from "prop-types"

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Checkbox, FormControl ,FormHelperText} from '@material-ui/core';

export default function TCheckBox(props) {
  const { answers, error} = props;
  const [checked, setChecked] = useState(false);

  const handleToggle = (value) => () => {
    answers.forEach(answer => {
      if(answer.inciso === value) answer.selected = !answer.selected
    });
    setChecked(!checked)
  };

  return (
    <FormControl component="fieldset" fullWidth error={error !== ''}>
      <List>
        {answers.map((answer) => {
          const labelId = `checkbox-list-label-${answer.inciso}`;
          return (
            <ListItem key={answer.inciso} role={undefined} dense button onClick={handleToggle(answer.inciso)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={answer.selected}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={answer.option} />
            </ListItem>
          );
        })}
      </List>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
/*
TCheckBox.propTypes = {
  setAnswer: PropTypes.func,
};
*/

