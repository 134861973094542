import React from 'react'
import qr_android from 'assets/img/qr_android.png'
import qr_ios from 'assets/img/qr_ios.png'

export default function Footer(){
  return (
    <footer id="contact" className="py-4 pt-md-6 pb-md-8 bg-primary text-white">
      <div className="container text-center">
        <h2 className="display-4 my-0 font-weight-light text-font-alt">
          Descarga la App
        </h2>
        <p className="mt-md-0 lead ">Disponible en tiendas de aplicaciones</p>
        <div className="row justify-content-around mb-6">
          <div className="col-md-4 mb-1 d-flex align-items-center justify-content-center">
            <img className="card-img-top" style={{maxWidth: 200}} src={qr_ios} alt="img" />
          </div>
          <div className="col-md-4 mb-1 d-flex align-items-center justify-content-center">
            <img className="card-img-top" style={{maxWidth: 200}} src={qr_android} alt="img" />
          </div>
        </div>
        <div className="text-center">
          <h6 className="font-weight-normal mb-2 mt-0 text-white text-uppercase text-letter-spacing">
            Redes sociales:
          </h6>
          <a href="https://www.youtube.com/channel/UC6VVxHYfxU2OBEN2XjNModQ" rel="noreferrer noopener" target="_blank" className="btn-icon btn btn-xlg text-white"><ion-icon name="logo-youtube" class="icon-2x"></ion-icon></a>
          <a href="https://es-la.facebook.com/KarimnotInc/" rel="noreferrer noopener" target="_blank" className="btn-icon btn btn-xlg text-white"><ion-icon name="logo-facebook" class="icon-2x"></ion-icon></a>
          <button  className="btn-icon btn btn-xlg text-white"><ion-icon name="logo-instagram" class="icon-2x"></ion-icon></button>
        </div>
        <div className="row justify-content-around mb-2 text-white">
          <div className="col-md-6 col-xs-12 align-items-center">
            <a href="/privacy" className="text-white" rel="noreferrer noopener"> Aviso de Privacidad </a>
          </div>
          <div className="col-md-6 col-xs-12 align-items-center">
            Copyright © <a href="https://karimnot.com/" className="text-white" rel="noreferrer noopener"> Karimnot Inc </a> {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  )
}