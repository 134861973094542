import React,{useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Radio, RadioGroup,FormControl, FormControlLabel,FormHelperText, TextField} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';

export default function TImage(props) {
  const { noQuestion, image, answers, setAnswers, required, handleNext} = props;
  const [error, setError] = useState('')
  const [selected, setSelected] = useState('')

  useEffect(() => {
    let s = answers.filter(answer => answer.selected)
    if(s.length > 0) setSelected(s[0].option)
  }, [answers])

  const changeSelected = (event) => {
    let value = event.target.value;
    setSelected(value);
    let option = answers.filter(answer => answer.option === value);
    console.log(option[0]);
    if(option[0].next) console.log(option.next);
  }

  const handleChange = () => {
    if(required && selected === '') {
      setError('Seleccione una respuesta');
      return;
    }
    let newAnswers = [];
    answers.forEach(answer => {
      if(answer.option === selected){
        newAnswers.push({inciso: answer.inciso, selected: true});
      }else{
        newAnswers.push({inciso: answer.inciso, selected: false})
      }
    })
    setAnswers(noQuestion, newAnswers)
    handleNext()
  }

  return (
    <React.Fragment>
      <div>
        <img style={{width: '100%', height: 'auto'}} src={image} alt="show_preview" />
      </div>
      <FormControl error={(error !== '')}>
        <RadioGroup aria-label="quiz" value={selected} onChange={changeSelected}>
          {answers.map(answer => 
            answer.specify 
            ? (<TextField key={answer.inciso} fullWidth />)
            : (<FormControlLabel key={answer.inciso} value={answer.option} control={<Radio />} label={answer.option} />)
            )}
          <FormHelperText>{error}</FormHelperText>
        </RadioGroup>
      </FormControl>
      <div >
        <div>
          <Button onClick={() => console.log('back')} >
            Pregunta Anterior
          </Button>
          <Button color="primary" onClick={handleChange} >
            Siguiente Pregunta
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

TImage.propTypes = {
  noQuestion: PropTypes.number,
  question: PropTypes.string,
  instrucctions: PropTypes.string,
  image: PropTypes.string,
  answers: PropTypes.array,
  setAnswers: PropTypes.func,
  required: PropTypes.bool,
  handleNext: PropTypes.func
};


