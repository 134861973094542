import { format } from "date-fns";
import secondsToStr from "variables/time_helper";

export default class Answer {
  id: number;
  answersJson: any;
  user: string;
  createdAt: Date;
  latitude: string;
  longitude: string;
  totalTime: string;
  constructor(properties: any = {}) {
    this.id = properties.id || 0;
    this.answersJson = this.answers(properties.answersJson) || {};
    this.user = this.fullName(properties.user);
    this.createdAt = properties.createdAt || new Date();
    this.latitude = properties.latitude || "";
    this.longitude = properties.longitude || "";
    this.totalTime = properties.totalTime || "";
    if (
      Object.keys(this.answersJson).length > 0 &&
      this.answersJson.constructor === Object
    ) {
      const totalAnswers = Object.keys(this.answersJson).length;
      // defines a 12 seconds default in case totalTime doesn't exist
      let timeInSeconds = totalAnswers * 12;

      if (properties.totalTime) {
        let [minutes, seconds] = properties.totalTime.trim().split(":");
        minutes = parseInt(minutes);
        seconds = parseInt(seconds);

        timeInSeconds = minutes * 6 + seconds;
      }

      this.answersJson["totalTime"] = this.timeRequestPercentage(
        timeInSeconds,
        totalAnswers
      );
      this.answersJson["createdAt"] = format(
        new Date(this.createdAt),
        "dd/MM/yyyy"
      );
      this.answersJson["user"] = this.user;
      if (this.latitude !== "" && this.longitude !== "") {
        let options = {
          lat: this.latitude,
          lng: this.longitude,
          userName: this.user,
        };
        this.answersJson["location"] = {
          value: "Ver Mapa",
          type: "location",
          link: "/admin/map/" + JSON.stringify(options),
        };
      } else this.answersJson["location"] = "";
    }
  }

  private fullName(user: any = {}) {
    if (!user) return "";
    return `${user.firstName} ${user.lastName} ${user.motherLastName}`;
  }

  location() {
    if (this.latitude !== "" && this.longitude !== "") return "Ver mapa";
    return "";
  }

  private timeRequestPercentage(seconds: number, totalQuestions: number) {
    let color = "success";
    const timePerQuestion = 10;
    const percentage = (seconds * 100) / (totalQuestions * timePerQuestion);
    switch (true) {
      case percentage > 50 && percentage < 100:
        color = "warning";
        break;
      case percentage <= 50:
        color = "danger";
        break;
    }
    return { color, time: secondsToStr(seconds) };
  }

  private answers(answers: Array<any>) {
    let rowjson: any = {};
    answers.forEach((item) => {
      switch (item.type) {
        case "TRadioButton":
        case "TCheckBox":
        case "TVideo":
        case "TImage":
        case "TOptionFilter":
          let result = item.answers
            .filter((item: any) => item.selected)
            .map((item: any) => item.option);
          rowjson[item.noQuestion] = result.join(", ");
          break;
        case "TSignature":
        case "TPhoto":
          rowjson[item.noQuestion] = {
            value: "Ver",
            type: "image",
            link: item.answers[0].selected,
          };
          break;
        case "TImageOption":
          let selecteds = item.answers
            .filter((ans: any) => ans.selected)
            .map((item: any) => item.inciso);
          rowjson[item.noQuestion] = selecteds.join(", ");
          break;
        case "TDate":
          const date = item.answers[0].selected;
          rowjson[item.noQuestion] =
            date && date.length > 0 ? format(new Date(date), "dd/MM/yyyy") : "";

          break;
        case "TCover":
          break;
        case "TLocation":
          let options = {
            lat: item.answers[0].selected.coords.latitude,
            lng: item.answers[0].selected.coords.longitude,
            userName: this.user,
          };
          if (options.lat !== "" && options.lng !== "")
            rowjson[item.noQuestion] = {
              value: "Ver Mapa",
              type: "location",
              link: "/admin/map/" + JSON.stringify(options),
            };
          else rowjson[item.noQuestion] = "";
          break;
        default:
          if (item.answers.length > 0)
            rowjson[item.noQuestion] = item.answers[0].selected;
          else rowjson[item.noQuestion] = item.answers.selected;
          break;
      }
    });
    return rowjson;
  }
}

export class AnswerProductivity {
  createdAt: Date = new Date();
  user: any = {};
}

export class AnswerLocation {
  lat: string;
  lng: string;
  userName: string;
  constructor(properties: any = {}) {
    this.lat = properties.lat || "";
    this.lng = properties.lng || "";
    this.userName = properties.userName || "";
  }
}
