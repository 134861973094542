import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { Card, CardHeader, CardBody } from "components/Card";
import Dialog from "components/Dialog/Dialog";
import Backdrop from "components/Backdrop/Backdrop";
import { notify } from "components/Snackbar/Notifier";
import Confirm from "components/AlertDialog/AlertDialog";
import Button from "components/CustomButtons/Button";
import Chip from "components/Chip/Chip";
import Tooltip from "components/Tooltip/Tooltip";
import {
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
// @material-ui/icons
import {
  Edit,
  Block,
  CheckCircleOutline,
  RemoveCircle,
} from "@material-ui/icons";
import CompanyService from "Services/CompanyService";
import Company from "Class/Company";
import CompanyForm from "./CompanyForm";
import Error from "../Error/404";

// @ts-ignore
export default function () {
  const formRef = useRef<any>(null);
  const [companies, setCompanies] = useState(new Array<Company>(0));
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(25);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(200);

  const initialDialog = {
    open: false,
    title: "",
    body: <div></div>,
  };

  const [dialog, setDialog] = useState(initialDialog);
  const initialConfirm = {
    open: false,
    title: "",
    body: "",
    action: () => {},
  };
  const [confirm, setConfirm] = useState(initialConfirm);

  useEffect(() => {
    getCompanies(page, rows);
  }, [page, rows]);

  function handleOpenConfirm(confirmState: any) {
    setConfirm({ open: true, ...confirmState });
  }
  function handleOpenDialog(dialogState: any) {
    setDialog({ open: true, ...dialogState });
  }

  const handleSubmitForm = () => {
    if (formRef && formRef !== null) {
      formRef.current.formSubmit();
    }
  };

  function companyForm(company: Company) {
    return (
      <Container component="main" maxWidth="md">
        <CompanyForm
          ref={formRef}
          company={company}
          onSubmit={handleCompanySave}
        />
      </Container>
    );
  }

  async function getCompanies(page: number, elements: number) {
    setLoading(true);
    try {
      const response = await CompanyService.listAllCompanies(page, elements);
      setCompanies(response.data);
      setPage(+response.pagination.current_page);
      setTotal(+response.pagination.total_elements);
    } catch (e) {
      setStatus(e.status);
    }
    setLoading(false);
  }

  async function handleGetCompany(id: number) {
    try {
      const response = await CompanyService.getCompany(id);
      handleOpenDialog({
        title: "Editando Empresa",
        body: companyForm(response),
      });
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  /*const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }*/
  async function handleCompanySave(company: Company) {
    try {
      await (company.id !== 0
        ? CompanyService.updateCompany(company)
        : CompanyService.createCompany(company));
      getCompanies(page, rows);
      setDialog(initialDialog);
      notify("success", "Empresa guardada con exito");
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function blockCompany(companyId: number, blocked: boolean) {
    try {
      const message = await CompanyService.blockOrUnlock(companyId, blocked);
      getCompanies(page, rows);
      setConfirm(initialConfirm);
      notify("success", message);
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function deleteCompany(companyId: number) {
    try {
      const message = await CompanyService.deleteCompany(companyId);
      getCompanies(page, rows);
      setConfirm(initialConfirm);
      notify("success", message);
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  const getValidityColor = (validityS: Date): string => {
    let validity = new Date(validityS);
    const oneMonthAfterValidity = new Date(
      validity.setMonth(validity.getMonth() + 1)
    );
    validity = new Date(validityS);
    const today = new Date();

    if (today.getTime() >= oneMonthAfterValidity.getTime()) {
      return "danger";
    } else if (today.getTime() >= validity.getTime()) {
      return "warning";
    }

    return "success";
  };

  function generateCells() {
    return companies.map((company) => {
      return (
        <TableRow hover={true} key={company.id}>
          <TableCell>{company.name}</TableCell>
          <TableCell>{company.usersNumber}</TableCell>
          <TableCell>
            <Chip
              size="small"
              color={getValidityColor(
                company.validity ? company.validity : new Date()
              )}
              label={
                company.validity !== null
                  ? format(new Date(company.validity), "dd/MM/yyyy HH:mm")
                  : ""
              }
            />
          </TableCell>
          <TableCell>
            {company.blocked ? (
              <Chip size="small" color="danger" label={"Bloqueada"} />
            ) : (
              <Chip size="small" color="info" label={"Activa"} />
            )}
          </TableCell>
          <TableCell>
            <Tooltip title="Editar Empresa">
              <IconButton onClick={() => handleGetCompany(company.id)}>
                <Edit color="primary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`${company.blocked ? "Desbloquear" : "Bloquear"} empresa`}
            >
              <IconButton
                onClick={() =>
                  handleOpenConfirm({
                    title: `${
                      company.blocked ? "Desbloquear" : "Bloquear"
                    } Empresa`,
                    body: `¿Estas seguro de ${
                      company.blocked ? "Desbloquear" : "Bloquear"
                    } esta Empresa?`,
                    action: () => blockCompany(company.id, company.blocked),
                  })
                }
              >
                {company.blocked ? (
                  <Block color="error" style={{ fontSize: 18 }} />
                ) : (
                  <CheckCircleOutline
                    color="secondary"
                    style={{ fontSize: 18 }}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar empresa">
              <IconButton
                onClick={() =>
                  handleOpenConfirm({
                    title: `Eliminar empresa`,
                    body: `¿Estas seguro de eliminar esta Empresa? (Todos sus datos seran eliminados)`,
                    action: () => deleteCompany(company.id),
                  })
                }
              >
                <RemoveCircle color="error" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  }

  if (status !== 200) {
    return <Error status={status} url="/admin/companies" />;
  }

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Grid>
        <Dialog
          title={dialog.title}
          body={dialog.body}
          isOpen={dialog.open}
          onSubmit={handleSubmitForm}
          onClose={() => setDialog(initialDialog)}
        />
        <Confirm
          open={confirm.open}
          title={confirm.title}
          body={<p>{confirm.body}</p>}
          onClose={() => setConfirm(initialConfirm)}
          onSubmit={confirm.action}
        />
      </Grid>
      <Card>
        <CardHeader color="primary" stats>
          <h3>Empresas</h3>
          <p>Lista de Empresas</p>
        </CardHeader>
        <CardBody>
          <Button
            color="info"
            onClick={() =>
              handleOpenDialog({
                title: "Nueva Empresa",
                body: companyForm(new Company()),
              })
            }
          >
            Nueva Empresa
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Num. Usuarios</TableCell>
                  <TableCell>Fecha de Vigencia</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{generateCells()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1)}
            page={page - 1}
            labelRowsPerPage={"Empresas por página"}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value)}
          />
        </CardBody>
      </Card>
    </Grid>
  );
}
