import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import styles from "../../assets/jss/material-dashboard-react/components/chipStyle.js";

const useStyles = makeStyles(styles);

export default function CustomChip(props) {
  const classes = useStyles();
  const { color, ...rest } = props;
  const chipClasses = classNames({
    [classes.chip]: true,
    [classes[color]]: color
  });
  return (
    <Chip {...rest} className={chipClasses} />
  );
}

CustomChip.defaultProps = {
  size: 'small'
}

CustomChip.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent"
  ]),
  label: PropTypes.string,
  size: PropTypes.string
};
