import React, {useRef, useImperativeHandle, forwardRef} from 'react'
import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import { Grid, TextField } from '@material-ui/core'

import Survey from '../../Class/Survey'

type SurveyProps = {
  survey: Survey,
  onSubmit: (survey: Survey) => void
}

const SurveyForm = forwardRef(({ survey, onSubmit }: SurveyProps, ref) => {

  const formRef = useRef<HTMLFormElement>(null);
  const { register, handleSubmit, errors } = useForm();

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  const onFormSubmit = (values: any) => {
    survey.name = values.name
    survey.version = values.version
    survey.surveyJson = validateJson('surveyJson',values.surveyJson)
    survey.questionsJson = validateJson('questionsJson',values.questionsJson)
    onSubmit(survey)
  };

  const validateJson = (name: string, json: any) =>{
    try{
      const newjson = JSON.parse(json.toString().replace(/\r?\n|\r/g, ''))
      return newjson
    } catch(e) {
      console.log('error validation: ', e)
    }
  }
  return (
    <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container>
        <Grid item md={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            name="name"
            label="Nombre de la Encuesta"
            defaultValue={survey.name}
            inputRef={register({ required: true })}
            error={errors.name ? true : false}
            helperText={errors.name ? 'Ingrese un nombre válido' : ''}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            name="version"
            label="Versión"
            defaultValue={survey.version}
            inputRef={register({ 
              validate:  {
                positiveNumber: value => parseFloat(value) > 0,
              }
            })}
            error={errors.version ? true : false}
            helperText={errors.version ? 'Ingrese un número válido.' : ''}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            name="surveyJson"
            label="JSON de la Encuesta"
            defaultValue={JSON.stringify(survey.surveyJson)}
            inputRef={register({ 
              required: true
            })}
            error={errors.surveyJson ? true : false}
            helperText={errors.surveyJson ? 'Ingrese un json válido.' : ''}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            multiline
            rows={8}
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            name="questionsJson"
            label="JSON de Preguntas"
            defaultValue={(survey.id !== 0) ? JSON.stringify(survey.questionsJson) : ''}
            inputRef={register({ required: true })}
            error={errors.questionsJson ? true : false}
            helperText={errors.questionsJson ? 'Ingrese un json válido.' : ''}
          />
        </Grid>
      </Grid>
    </form>
  );
});

SurveyForm.propTypes = {
  survey: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SurveyForm
