import React, {useEffect} from 'react'
import banner from 'assets/img/banner-1.jpg'
const Blazy = require('blazy')

// @ts-ignore
export default function Slide() {

  useEffect(() => {
    new Blazy({
      selector: '[data-bg-img]',
      loadInvisible: true,
      src: 'data-bg-img'
    });
  }, []);

  return (
    <div id="highlighted" className="bg-black pos-md-relative">
      <div className="overlay overlay-op-4 text-left bg-img blazy-bg" data-animate="fadeIn" data-bg-img={banner}>
        <div data-toggle="full-height" className="full-height container px-md-3 py-5 pt-md-6 py-md-5 flex-valign text-white ">
          <h2 className="display-3 font-weight-bold mt-lg-7 my-md-0">
            ¿Más fácil? ¡Imposible!
          </h2>
          <h2 className="display-4 font-weight-normal">
            No necesitas datos celulares
          </h2>
          <p className="lead my-md-4 w-md-50">Realiza encuestas por medio de celular o tableta, no necesita datos, no necesitas capturistas y asegurate que hacen los encuestadores.</p>
          <div>
            <a href="#about" data-toggle="scroll-link" data-animate-delay="0.6"><i className="icon-3x ion-ios-play-outline"></i></a>
          </div>
        </div>
      </div>
    </div>
  )
}