import Client from './Client'
import Campaign from 'Class/Campaign'
import {isSuper} from 'Services/Auth'

const resource = '/campaigns'

export default class CampaignService {
   public static async getSurveys( campaignId: number, page: number, size: number){
    try {
      const { data } = await Client.get(`${resource}/${campaignId}/surveys?page=${page}&page_size=${size}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public static async getCampaigns(page: number, size: number, companyId: number, adminId: number) {
    try {
      let url = `${resource}?page=${page}&page_size=${size}`
      if (companyId) url += `&companyId=${companyId}`
      if (adminId) url += `&adminId=${adminId}`
      const { data } = await Client.get(url)
      return data
    } catch (e) {
      throw e
    }
  }

  public static async getMembers(campaignId: number,page: number, size: number){
    try {
      const { data } = await Client.get(`${resource}/${campaignId}/members?page=${page}&page_size=${size}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public static async addMembers(campaignId: number, userIds: Array<number>){
    try {
      const { data } = await Client.post(`${resource}/${campaignId}/add_members`,{members: userIds})
      return data
    } catch (e) {
      throw e
    }
  }
  public static async getNonMembers(campaignId: number){
    try {
      const { data } = await Client.get(`${resource}/${campaignId}/non_members`)
      return data
    } catch (e) {
      throw e
    }
  }

  public static async getCampaign(campaignId: number){
    try {
      const { data } = await Client.get(`${resource}/${campaignId}`)
      return new Campaign(data.data)
    } catch (e){
      throw e
    }
  }

  public static async saveCampaign(campaign: Campaign){
    try {
      if(campaign.id === 0){
        await Client.post(`${resource}`, campaign.asJSON())
      } else{
        await Client.put(`${resource}/${campaign.id}`, campaign.asJSON())
        if(isSuper()){
          await Client.put(`${resource}/update_company`, {campaignId: campaign.id, companyId: campaign.company.id} )
        }
      }
    } catch (e){
      throw e
    }
  }

  public static async removeCampaign(campaignId: number){
    try {
      await Client.delete(`${resource}/${campaignId}`)
    } catch (e){
      throw e
    }
  }

}
