import React,{useEffect} from "react"
import {TextField} from '@material-ui/core'
import PropTypes from "prop-types"

export default function TNumber(props) {
  const { answers, getJson } = props
  const categoryId = 1
  const type = 'TNumber'

  useEffect(() => {
    if(answers.length <= 0){
      getJson({categoryId,type,answers: [{selected: ''}]})
    }
  },[answers, getJson])

  return (
    <TextField
      disabled
      type="text"
      label="1234"
    />
  );
}

TNumber.propTypes = {
  getJson: PropTypes.func,
};
