const months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

function getMonths(data, year) {
  const monthsAvailible = []
  data.forEach((item) => {
    if(year === +item.year) 
      monthsAvailible.push({id: +item.month, name: months[parseInt(item.month) - 1] })
  });
  monthsAvailible.unshift({id: 0, name: 'Todos'})
  return monthsAvailible
}

function generateDay(data,year, month) {
  let days = new Date(year,month,0).getDate();
  let days_array = []
  for (let i = 1; i <= days; i++) {
    let day = i < 10 ? '0'+i : i;
    let row = {label: `${day}-${months[month - 1].slice(0,3)}`, total: 0, prom: 0};
    days_array.push(row);
  }
  data.forEach(item => {
    let index = parseInt(item.created.slice(0,2)) - 1;
    days_array[index].total = parseInt(item.total);
    days_array[index].prom = parseInt(item.prom);
  })
  return days_array;
}

function generateMonth(data) {
  let months_array = [];
  for (let i = 0; i <= 11; i++) {
    months_array.push({label: months[i], total: 0, prom: 0});
  }
  data.forEach(item => {
    months_array[parseInt(item.month) - 1].total = parseInt(item.total)
    months_array[parseInt(item.month) - 1].prom = parseInt(item.prom)
  });
  return months_array;
}

function generateUsers(data,users) {
  let users_array = users.map((item) => {
    return {id: item.id, label: item.name, total: 0 }
  });
  users_array.shift();
  data.forEach(item => {
    let index = users_array.findIndex(el => el.id === item.user)
    if(index >= 0){
      users_array[index].total = item.total
    }
  });
  users_array.sort((a,b) => { return a.total - b.total } )
  return users_array
}

const Utils = {
  generateDay,
  generateMonth,
  getMonths,
  generateUsers
};

export default Utils;