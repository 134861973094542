import React from "react"
import Header from 'components/Public/Header'
//import Header from 'components/Public/Header2'
import Content from 'components/Public/Content'
import Services from 'components/Public/Services'
import Footer from 'components/Public/Footer'
//import Slide from 'components/Public/Slide'
import Slide from 'pages/Public/Slide'

export default function Public({...rest}) {

  return (
    // <div class="page-loader" data-toggle="page-loader"></div>
    <React.Fragment>
      <Header />
      <Slide />
      <Content />
      <Services />
      <Footer />
    </React.Fragment>
  )
}