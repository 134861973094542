import React from "react"

import Snackbar from './Snackbar'
import {AddAlert} from "@material-ui/icons"

let openSnackbarFn;

export default function Notifier(props) {

  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [notificationColor, setNotificationColor] = React.useState("success");
  const [notificationMessage, setNotificationMessage] = React.useState('');

  const showNotification = (color,message) => {
    if (notificationOpen === false) {
      setNotificationColor(color);
      setNotificationMessage(message);
      setNotificationOpen(true);
      setTimeout(function() {
        setNotificationOpen(false);
      }, 6000);
    }
  };

  openSnackbarFn = showNotification;

  return (
    <Snackbar
      place="br"
      color={notificationColor}
      icon={AddAlert}
      message={notificationMessage}
      open={notificationOpen}
      closeNotification={() => setNotificationOpen(false)}
      close
    />
  )
}

export function notify(color,message) {
  openSnackbarFn(color, message);
}