import React,{useRef, useState, useEffect} from "react"
import {Grid, IconButton} from '@material-ui/core'
import Tooltip from 'components/Tooltip/Tooltip'
import DeleteForever from '@material-ui/icons/DeleteForever'
export default function TSignature(props) {
  const { answers, required} = props;
  const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  const fileInputRef = useRef();
  const [preview, setPreview] = useState(answers[0].selected);
  const [error, setError] = useState('')

  useEffect(() => {
    if (required !== '') setError('Es obligario seleccionar una imagen')
  },[required])

  const preventDefault = (e) => {
    e.preventDefault();
  }

  const dragOver = (e) => {
    preventDefault(e);
  }

  const dragEnter = (e) => {
    preventDefault(e);
  }

  const dragLeave = (e) => {
    preventDefault(e);
  }

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      validateFile(files[0])
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      let file = fileInputRef.current.files[0];
      validateFile(file);
    }
  }

  const validateFile = (file) => {
    if (validTypes.indexOf(file.type) === -1)
      setError('Archivo no válido')
    else
      toBase64(file)
  } 

  const toBase64 = (file) => {
    let reader = new FileReader();
    reader.onloadend = function() {
      let result = reader.result;
      answers[0].selected = result;
      setPreview(result);
      setError('');
    }
    reader.readAsDataURL(file);
  }

  const fileInputClicked = () => {
    fileInputRef.current.click();
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <div className="drop-box"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          <h6>
            <p>Arrastre y suelte la imagen aquí o haga clic para seleccionar</p>  
            <p className="drop-message file-error-message">{error}</p> 
          </h6>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />
        </div>
      </Grid>
      <Grid item md={6}>
        {preview !== '' &&
          <Tooltip style={{position: 'absolute', right: '20px'}} title="Eliminar" >
            <IconButton onClick={() => setPreview('')}>
              <DeleteForever color="error"/>
            </IconButton>
          </Tooltip>
        }
        <div className="list-items">
          {preview !== '' &&
            <img style={{width: '100%', height: '100%'}} src={preview} alt="signature preview" />
          }
        </div>
      </Grid>
    </Grid>
  );
}
