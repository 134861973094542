import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import {
  Edit,
  Visibility,
  RemoveCircleOutline,
  Delete,
  Speed /*Email*/,
} from "@material-ui/icons";
import { notify } from "components/Snackbar/Notifier";
import { Card, CardHeader, CardBody } from "components/Card";
import Dialog from "components/Dialog/Dialog";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/CustomButtons/Button";
import Backdrop from "components/Backdrop/Backdrop";
import Tooltip from "components/Tooltip/Tooltip";
import Member from "Class/Member";
import Survey from "Class/Survey";
import CampaignService from "Services/CampaignService";
import SurveyService from "Services/SurveyService";
import UserService from "Services/UserService";
import { isSuper, isSuperAdmin } from "Services/Auth";
import SurveyForm from "../Surveys/SurveyForm";
import MembersForm from "./MembersForm";
import Error from "../Error/404";
import { LoginService } from "Services/LoginService";

// @ts-ignore
export default function () {
  const history = useHistory();
  const [members, setMembers] = useState(new Array<Member>(0));
  const [pageMember, setPageMember] = useState(1);
  const [rowsMember, setRowsMember] = useState(10);
  const [totalMember, setTotalMember] = useState(0);

  const [surveys, setSurveys] = useState(new Array<Survey>(0));
  const [pageSurvey, setPageSurvey] = useState(1);
  const [rowsSurvey, setRowsSurvey] = useState(10);
  const [totalSurvey, setTotalSurvey] = useState(0);

  const [nonMembers, setNonMembers] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(100);
  const formRef = useRef<any>(null);

  const user = LoginService.currentUser();

  const initialDialog = {
    open: false,
    title: "",
    body: <div></div>,
  };
  const [dialog, setDialog] = useState(initialDialog);

  const initialConfirm = {
    open: false,
    title: "",
    body: "",
    action: () => {},
  };
  const [confirm, setConfirm] = useState(initialConfirm);
  const { campaignId } = useParams();
  const [campaignName, setCampaignName] = useState("");

  useEffect(() => {
    async function getCampaign(id: number) {
      try {
        const response = await CampaignService.getCampaign(id);
        setCampaignName(response.name);
        setStatus(200);
        getNonMembers(id);
      } catch (e) {
        setStatus(e.status);
      }
    }
    getCampaign(campaignId);
  }, [campaignId]);

  useEffect(() => {
    if (status === 200) {
      getMembers(campaignId, pageMember, rowsMember);
    }
  }, [status, campaignId, pageMember, rowsMember]);

  useEffect(() => {
    if (status === 200) {
      getSurveys(campaignId, pageSurvey, rowsSurvey);
    }
  }, [status, campaignId, pageSurvey, rowsSurvey]);

  function surveyForm(survey: Survey) {
    return (
      <Container component="main" maxWidth="md">
        <SurveyForm ref={formRef} survey={survey} onSubmit={handleSurveySave} />
      </Container>
    );
  }

  function handleOpenConfirm(confirmState: any) {
    setConfirm({ open: true, ...confirmState });
  }

  function handleOpenDialog(dialogState: any) {
    setDialog({ open: true, ...dialogState });
  }

  const handleSubmitForm = () => {
    if (formRef && formRef !== null) {
      formRef.current.formSubmit();
    }
  };

  function membersList() {
    return (
      <Container component="main" maxWidth="sm">
        <MembersForm ref={formRef} users={nonMembers} onSubmit={addMembers} />
      </Container>
    );
  }

  async function addMembers(nonMembers: Array<number>) {
    try {
      if (nonMembers.length > 0) {
        await CampaignService.addMembers(campaignId, nonMembers);
        getMembers(campaignId, pageMember, rowsMember);
        getNonMembers(campaignId);
        notify("success", "Miembros agregados con exito");
      }
      setDialog(initialDialog);
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function removeMember(memberId: number) {
    try {
      await UserService.removeFromCampaign(campaignId, memberId);
      getMembers(campaignId, pageMember, rowsMember);
      getNonMembers(campaignId);
      setConfirm(initialConfirm);
      notify("success", "Usuario removido de la campaña con exito");
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function getMembers(
    campaignId: number,
    page: number,
    elements: number
  ) {
    try {
      const response = await CampaignService.getMembers(
        campaignId,
        page,
        elements
      );

      const members = response.data;

      setMembers(members);
      setPageMember(+response.pagination.current_page);
      setTotalMember(+response.pagination.total_elements);
    } catch (e) {
      if (e.status) setStatus(e.status);
    }
  }

  async function getSurveys(
    campaignId: number,
    page: number,
    elements: number
  ) {
    setLoading(true);
    try {
      const response = await CampaignService.getSurveys(
        campaignId,
        page,
        elements
      );
      setSurveys(response.data);
      setPageSurvey(+response.pagination.current_page);
      setTotalSurvey(+response.pagination.total_elements);
    } catch (e) {
      if (e.status) setStatus(e.status);
    }
    setLoading(false);
  }

  async function handleGetSurvey(id: number) {
    try {
      const response = await SurveyService.getSurvey(id);
      handleOpenDialog({
        title: "Editando Encuesta",
        body: surveyForm(response),
      });
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  async function getNonMembers(campaignId: number) {
    try {
      const response = await CampaignService.getNonMembers(campaignId);
      setNonMembers(response.data);
    } catch (e) {
      if (e.status) setStatus(e.status);
    }
  }

  async function handleSurveySave(survey: Survey) {
    setLoading(true);
    try {
      await SurveyService.saveSurvey(survey, campaignId);
      getSurveys(campaignId, pageSurvey, rowsSurvey);
      getMembers(campaignId, pageMember, rowsMember);
      setDialog(initialDialog);
      notify("success", "Encuesta guardada con exito");
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
    setLoading(false);
  }

  async function removeSurvey(surveyId: number) {
    try {
      await SurveyService.removeSurvey(surveyId);
      getSurveys(campaignId, pageSurvey, rowsSurvey);
      setConfirm(initialConfirm);
      notify("success", "Encuesta eliminada con exito");
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  function generateCellMembers() {
    return members.map((value) => {
      return (
        <TableRow hover={true} key={value.id}>
          <TableCell>{`${value.firstName} ${value.lastName} ${
            value.motherLastName !== null ? value.motherLastName : ""
          }`}</TableCell>
          <TableCell>{value.email}</TableCell>
          <TableCell>{value.role.description}</TableCell>
          <TableCell>
            <Tooltip title="Remover de la campaña">
              <IconButton
                onClick={() => {
                  handleOpenConfirm({
                    title: "Remover Usuario",
                    body: "¿Esta seguro de Remover al usuario de la Campaña?",
                    action: () => removeMember(value.id),
                  });
                }}
              >
                <RemoveCircleOutline color="error" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  }

  function generateCellSurveys() {
    return surveys.map((survey) => {
      return (
        <TableRow hover={true} key={survey.id}>
          <TableCell>{survey.name}</TableCell>
          <TableCell>
            {format(new Date(survey.createdAt), "dd/MM/yyyy")}
          </TableCell>
          <TableCell>{survey.questionsJson.length}</TableCell>
          <TableCell>{survey.version}</TableCell>
          <TableCell>
            <Tooltip title="Mostrar Respuestas">
              <IconButton
                onClick={() =>
                  history.push(`/admin/survey/${survey.id}/answers`)
                }
              >
                <Visibility color="secondary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar Encuesta">
              <IconButton
                onClick={() => history.push(`/admin/survey/${survey.id}`)}
              >
                <Edit color="primary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            {(isSuper() || isSuperAdmin()) && (
              <Tooltip title="Editar Encuesta en Modo avanzado">
                <IconButton onClick={() => handleGetSurvey(survey.id)}>
                  <Speed color="primary" style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            )}
            {/*
            <Tooltip title="Enviar por Correo" >
              <IconButton onClick={() => history.push(`/admin/survey/${survey.id}/emails`)}>
                <Email color="secondary" style={{fontSize: 18}}/>
              </IconButton>
            </Tooltip>
            */}
            <Tooltip title="Eliminar Encuesta">
              <IconButton
                onClick={() =>
                  handleOpenConfirm({
                    title: "Eliminar Encuesta",
                    body:
                      "Esta acción eliminara todas las encuestas contestadas así como archivos vinculados con esta encuesta. ¿Está seguro de eliminar esta encuesta?",
                    action: () => removeSurvey(survey.id),
                  })
                }
              >
                <Delete color="error" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  }

  function addAllUsuers() {
    const users = nonMembers.filter((el) => el.role_description === "USER");
    addMembers(users);
  }

  function addAllAdmins() {
    const admins = nonMembers.filter((el) => el.role_description === "ADMON");
    addMembers(admins);
  }

  if (![100, 200].includes(status))
    return <Error status={status} url={history} />;

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Card>
        <CardHeader color="primary" stats>
          <h3>Encuestas de Campaña {campaignName}</h3>
          <p>Lista de encuestas disponibles</p>
        </CardHeader>
        <CardBody>
          <Button
            color="info"
            onClick={() =>
              history.push(`/admin/campaigns/${campaignId}/addSurvey`)
            }
          >
            Agregar Encuesta
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha de creación</TableCell>
                  <TableCell>Número de preguntas</TableCell>
                  <TableCell>Versión</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{generateCellSurveys()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={totalSurvey}
            component="div"
            onChangePage={(event, value) => setPageSurvey(value + 1)}
            page={pageSurvey - 1}
            labelRowsPerPage={"Encuestas por pagina"}
            rowsPerPage={rowsSurvey}
            onChangeRowsPerPage={(event) => setRowsSurvey(+event.target.value)}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader color="primary" stats>
          <h3>Miembros de Campaña {campaignName}</h3>
          <p>Lista de miembros</p>
        </CardHeader>
        <CardBody>
          <Button
            color="info"
            onClick={() =>
              handleOpenDialog({
                title: "Agregar Miembros",
                body: membersList(),
              })
            }
          >
            Agregar Miembros
          </Button>

          {user?.isSuperAdmin() && (
            <Button color="rose" onClick={addAllAdmins}>
              Agregar a todos mis admins
            </Button>
          )}

          <Button color="success" onClick={addAllUsuers}>
            Agregar a todos mis usuarios
          </Button>
          <Dialog
            title={dialog.title}
            body={dialog.body}
            isOpen={dialog.open}
            onSubmit={handleSubmitForm}
            onClose={() => setDialog(initialDialog)}
          />
          <AlertDialog
            open={confirm.open}
            title={confirm.title}
            body={<p>{confirm.body}</p>}
            onClose={() => setConfirm(initialConfirm)}
            onSubmit={confirm.action}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Correo Electrónico</TableCell>
                  <TableCell>Rol</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{generateCellMembers()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={totalMember}
            component="div"
            onChangePage={(event, value) => setPageMember(value + 1)}
            page={pageMember - 1}
            labelRowsPerPage={"Miembros por pagina"}
            rowsPerPage={rowsMember}
            onChangeRowsPerPage={(event) => setRowsMember(+event.target.value)}
          />
        </CardBody>
      </Card>
    </Grid>
  );
}
