import Joi from '@hapi/joi';
const msg = {'string.empty': 'Este campo es obligatorio.'}

const attributes_create = {
  code: Joi.string().required().messages({...msg}),
  cost: Joi.number().min(1).messages({
    'number.base': 'Ingrese solo números enteros',
    'number.min': 'Ingrese números mayores a 0' 
  }),
  activations: Joi.number().integer().min(1).messages({
    'number.base': 'Ingrese solo números enteros',
    'number.integer': 'Ingrese solo números enteros',
    'number.min': 'Ingrese números mayores a 0' 
  }),
  usersNumber: Joi.number().integer().min(1).messages({
    'number.base': 'Ingrese solo números enteros',
    'number.integer': 'Ingrese solo números enteros',
    'number.min': 'Ingrese números mayores a 0' 
  }),
  validity: Joi.date().greater('now').messages({
    'date.base': 'Ingrese una fecha válida'
  })
}

const attributes_edit = {
  ...attributes_create
}
export const schema_create = Joi.object().keys(attributes_create)
export const schema_edit = Joi.object(attributes_edit)