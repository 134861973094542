import Client from './Client'
import User  from 'Class/User'

export class LoginService{
  public static async signIn(email: string, password: string){
    try {
      const { data } = await Client.post('/auth',{email, password, client: 'web'})
      const currentUser = new User(data.data.user)
      currentUser.token = data.data.token
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
    } catch (e){
      throw e;
    }
  }
  public static async signOut(dataRegister: any){
    try {
      await Client.post('/register',dataRegister)
    } catch (e){
      throw e;
    }
  }

  public static isAuthenticated(){
    let auth = !!localStorage.getItem("currentUser")
    return auth
  }

  public static currentUser() {
    let auth = localStorage.getItem("currentUser")
    if (auth){
      return new User(JSON.parse(auth))
    }
    return  null;
  }

    public static token(){
      let auth = localStorage.getItem("currentUser")
      if(auth){
        const currentUser = JSON.parse(auth)
        return currentUser.token
      } 
      return null
    }

    public static logout() {
      localStorage.removeItem('currentUser')
    }

}

