import Client from './Client'
import Payment from 'Class/Payment'

const resource = '/payments'

export default class PaymentService {
   public static async getPayments(page: number, size: number){
    try {
      const { data } = await Client.get(`${resource}?page=${page}&page_size=${size}`)
      return data
    } catch (e) {
      throw e
    }
  }
  public static async getPayment(paymentId: number) {
    try {
      const { data } = await Client.get(`${resource}/${paymentId}`)
      return new Payment(data.data)
    } catch (e) {
      throw e;
    }
  }
  public static async savePayment(payment: Payment){
    try {
      const { data } = await Client.post(`${resource}`, payment.asJSON())
      return data
    } catch (e) {
      throw e
    }
  }
}