import Client from './Client'
import Company from "Class/Company";

const resource = '/companies'

export default class CompanyService {
  public static async listAllCompanies(page: number, page_size: number) {
    try {
      let url  = `${resource}`
      if(page !== 0 && page_size !== 0)
        url = `${resource}?page=${page}&page_size=${page_size}`
      const { data } = await Client.get(url)
      return data
    } catch (e) {
      throw e;
    }
  }
  
  public static async getCompany(companyId: number) {
    try {
      const { data } = await Client.get(`${resource}/${companyId}`)
      const comp = new Company(data.data)
      return comp
    } catch (e) {
      throw e;
    }
  }

  public static async getDetails() {
    try {
      const { data } = await Client.get(`${resource}/details`)
      const comp = new Company(data.data)
      return comp
    } catch (e) {
      throw e;
    }
  }

  public static async getCompanyByName(name: string) {
    try {
      const { data } = await Client.get(`${resource}/search?name=${name}`)
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async createCompany(company: Company) {
    try {
      const { data } = await Client.post(`${resource}`, company.asJSON())
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async updateCompany(company: Company) {
    try {
      const { data } = await Client.put(`${resource}/${company.id}`, company.asJSON())
      return data
    } catch (e) {
      throw e;
    }
  }

  public static async blockOrUnlock(companyId: number, blocked: boolean) {
    try {
      if (blocked){
        await Client.put(`${resource}/${companyId}/unblock`)
        return 'Empresa desbloqueada con exito'
      }
      else{
        await Client.put(`${resource}/${companyId}/block`)
        return 'Empresa bloqueada con exito'
      }
    } catch (e) {
      throw e;
    }
  }

  public static async deleteCompany(companyId: number) {
    try {
      await Client.delete(`${resource}/${companyId}/remove`)
      return 'Empresa eliminada exitosamente'
    } catch (e) {
      throw e;
    }
  }
}