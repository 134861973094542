import React,{useRef, useImperativeHandle, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import Company from '../../Class/Company';
import { Grid, TextField } from '@material-ui/core'
import { joiResolver } from "@hookform/resolvers";
import {schema_create} from 'Validations/Companies'

import { DatePicker } from '@material-ui/pickers';

type CompanyProps = {
  company: Company,
  onSubmit: (company: Company) => void
}

type RefProp = {
  formSubmit: () => void
}

const CompanyForm = forwardRef(({ company, onSubmit }: CompanyProps, ref) => {
  
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema_create),
  });

  const formRef = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    company.validity
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const onFormSubmit = (values: any) => {
    values.validity = selectedDate
    onSubmit(new Company({ id: company.id, ...values }));
  };

  return (
      <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="text"
              name="name"
              label="Nombre"
              defaultValue={company.name}
              inputRef={register}
              helperText={errors.name?.message}
              error={errors.name ? true : false}
              placeholder="Ingrese el nombre de la empresa"
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="number"
              name="usersNumber"
              label="Número de Usuarios"
              defaultValue={company.usersNumber}
              inputRef={register}
              placeholder="Ingrese el numero de usuarios"
              helperText={errors.usersNumber?.message}
              error={errors.usersNumber ? true : false}
            />
          </Grid>
          <Grid item md={6}>
            <DatePicker 
              margin="normal"
              fullWidth
              label="Fecha de Vigencia"
              inputVariant="outlined"
              format="yyyy/MM/dd"
              name="validity"
              value={selectedDate}
              onChange={handleDateChange}
              inputRef={register({ required: true })}
              minDate={company.minValidity()}
              helperText={errors.validity?.message}
              error={errors.validity ? true : false}
            />
          </Grid>
        </Grid>
      </form>
  );
});

CompanyForm.propTypes = {
  company: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyForm
