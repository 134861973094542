import React,{useEffect, useState, useRef} from "react"
//import PropTypes from "prop-types"
import {Radio, RadioGroup, FormControlLabel, FormControl, FormHelperText, TextField} from '@material-ui/core';

export default function TRadioButton(props) {
  const specifyRef = useRef(null)
  const { answers, error } = props;
  const [selected, setSelected] = useState('')

  useEffect(() => {
    let s = answers.filter(answer => answer.selected)
    if(s.length > 0) setSelected(s[0].inciso)
  }, [answers])

  const changeSelected = (event) => {
    let value = event.target.value;
    answers.forEach(answer => {
      if (answer.inciso === value) {
        answer.selected = true;
      }else{
        answer.selected = false;
      }
    });
    if(specifyRef !== null && specifyRef.current !== null) {
      specifyRef.current.value = '';
    }
    setSelected(value);
  }

  const changeSpecify = (event) => {
    let value = event.target.value;
    answers.forEach(answer => {
      if(answer.specify){
        answer.option = value;
        answer.selected = true;
      }else{
        answer.selected = false;
      }
    });
    setSelected(value)
  }

  const resetSelected = (event) => {
    answers.forEach(answer => answer.selected = false);
    setSelected('');
  }

  return (
    <FormControl component="fieldset" fullWidth error={error !== ''}>
      <RadioGroup value={selected} onChange={changeSelected}>
        {answers.map(answer => 
          answer.specify 
          ? (<TextField key={answer.inciso}
              defaultValue={answer.option !== 'Otro...' ? answer.option : ''} 
              inputRef={specifyRef} 
              placeholder="Especificar ..." 
              fullWidth variant="outlined" 
              InputProps={{onBlur: changeSpecify, onFocus: resetSelected}} />)
          : (<FormControlLabel key={answer.inciso} 
              value={answer.inciso} control={<Radio />} 
              label={answer.option} />)
          )}
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
/*
TRadioButton.propTypes = {
  setAnswer: PropTypes.func,
};
*/

