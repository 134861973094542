
export default class Survey {
    id: number
    name: string
    description: string
    questionsJson: Array<any>
    surveyJson: any = {}
    version: number = 1
    createdAt: Date
    campaignId: number | null = null
    headers: Array<any>
    constructor(properties: any = {}){
      this.id = properties.id || 0
      this.name = properties.name || ''
      this.description = properties.description || ''
      this.questionsJson = properties.questionsJson || []
      this.headers = this.generateHeaders()
      this.surveyJson = this.adaptSurveyJson(properties.surveyJson || {})
      this.version = properties.version || 1
      this.createdAt = properties.createdAt || new Date()
      if(properties.campaign) this.campaignId = properties.campaign.id
    }
    asJSON(){
     let attributes: any =  {
        name: this.name,
        description: this.description,
        questionsJson: this.questionsJson,
        surveyJson: this.surveyJson,
        version: this.version
      }
      if(this.campaignId !== null) attributes["campaignId"] = this.campaignId
      return attributes
    }
    private generateHeaders(){
      const allQuestions = new Array<Object>(0)
      this.questionsJson.forEach(val => {
        if(val.type !== 'TCover') allQuestions.push(Object.assign({}, val))
      })
      if(this.questionsJson.length > 0) {
        allQuestions.push({noQuestion: 'createdAt', question: 'Fecha'})
        allQuestions.push({noQuestion: 'user',question: 'Usuario'})
        allQuestions.push({noQuestion: 'location', question: 'Localización'})
        allQuestions.unshift({noQuestion: 'totalTime', question: 'Tiempo en Responder Encuesta'})
      }
      return allQuestions
    }
    private adaptSurveyJson(surveyJson: any){
      return {
        icon: surveyJson.icon || 'pencil',
        backColor: surveyJson.backColor || '#0707f8',
        fontColor: surveyJson.fontColor || '#c7c4c4',
        imageColor: surveyJson.imageColor || '#c7c4c4'
      }
    }

    filesToDownload() {
      let download = false
      this.questionsJson.forEach(item => {
        if(item.type === 'TPhoto' || item.type === 'TSignature') {
          download = true
          return
        }
      })
      return download
    }
}