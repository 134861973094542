import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import { Grid, TextField, MenuItem } from '@material-ui/core'

import { joiResolver } from "@hookform/resolvers";

import {schema_create, schema_edit} from 'Validations/User'
import User from 'Class/User'
import Role from 'Class/Rol'
import Company from 'Class/Company'
import CompanyService from 'Services/CompanyService'
import {isSuper, isSuperAdmin} from 'Services/Auth'

type UserProps = {
  user: User,
  onSubmit: (user: User) => void
}

const UserForm = forwardRef(({ user, onSubmit }: UserProps, ref) => {

  const formRef = useRef<HTMLFormElement>(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(user.id !== 0 ? schema_edit : schema_create ),
  });

  const options = [
    { value: 1, label: "Usuario" }
  ];
  if(isSuperAdmin()) {
    options.push({value: 3, label: 'Administrador'})
    options.push({value: 4, label: 'Super Administrador'})
  }
  if(isSuper()) {
    options.push({value: 3, label: 'Administrador'})
    options.push({value: 4, label: 'Super Administrador'})
    options.push({ value: 2, label: "Super" })
  } 

  const CompanyClass: Company[] = []
  const [companies, setCompanies] = useState(CompanyClass)
  const [companyId, setCompanyId] = useState(user.company.id)
  const [companyError, setCompanyError] = useState("")
  const [role, setRole] = useState(user.role.priority)

  useEffect(() => {
    if(isSuper()) getCompanies()
  }, [])

  useEffect(() => {
    if(companyId > 0) setCompanyError("")
  },[companyId])

  async function getCompanies() {
    try {
      const response = await CompanyService.listAllCompanies(0,0)
      setCompanies(response.data)
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  useImperativeHandle(ref, () => ({
    formSubmit: () => {
      let el = formRef.current;
      if(el && el !== null){
        el.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }));

  const onFormSubmit = (values: any) => {
    if(isSuper() && companyId === 0){
      setCompanyError("Seleccione una Empresa")
      return
    }
    const userRole = new Role({priority: role})
    const company = new Company({id: companyId})
    const newUser = new User({id: user.id, company: company, role: userRole, ...values})
    onSubmit(newUser);
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container spacing={3} >
        <Grid item md={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            name="firstName"
            label="Nombre(s)"
            defaultValue={user.firstName}
            inputRef={register}
            helperText={errors.firstName?.message}
            error={errors.firstName ? true : false}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            name="lastName"
            label="Apellido Paterno"
            defaultValue={user.lastName}
            inputRef={register}
            helperText={errors.lastName?.message}
            error={errors.lastName ? true : false}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            name="motherLastName"
            label="Apellido Materno"
            defaultValue={user.motherLastName}
            inputRef={register}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            name="email"
            label="Correo Electrónico"
            autoComplete="email"
            defaultValue={user.email}
            inputRef={register}
            helperText={errors.email?.message}
            error={errors.email ? true : false}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="password"
            name="password"
            label="Contraseña"
            autoComplete="password"
            defaultValue={''}
            inputRef={register()}
            helperText={errors.password?.message}
            error={errors.password ? true : false}
          />
        </Grid>
        { isSuper() && ( 
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label="Empresa a la que pertence"
              name="companyId"
              value={companyId > 0 ? companyId : ""}
              onChange={(event) => setCompanyId(+event.target.value)}
              variant="outlined"
              error={companyError !== "" ? true : false}
              helperText={companyError}
            >
              {companies.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {(isSuper() || isSuperAdmin()) && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label="Rol de Usuario"
              name="role"
              value={role}
              variant="outlined"
              onChange={(event) => setRole(+event.target.value)}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>
    </form>
  );
});

UserForm.propTypes = {
  user: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UserForm;
