import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {Grid, Typography, GridList, GridListTile} from '@material-ui/core'
import Popover from '@material-ui/core/Popover';
import ColorPicker from 'components/ColorPicker/ColorPicker'
import Button from 'components/CustomButtons/Button';
import MButton from '@material-ui/core/Button';
import iconList from 'variables/iconList';

export default function IconPicker(props) {
  const {title, iconJson, onChange,} = props;

  const [state, setState] = useState(iconJson);
  const [anchorEl, setAnchorEl] = useState(null);
  const icons = iconList.slice(0,100);

  useEffect(() => {
    setState(iconJson)
  },[iconJson])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (target,value) => {
    let newState = {...state};
    newState[target]= value;
    setState(newState);
    onChange(newState);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={3}
      direction="row"
      justify="flex-start"
      alignItems="center">
      <Grid item md={6}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <MButton aria-describedby={id}
              variant="outlined"
              color="primary"
              onClick={handleClick}
              startIcon={<i className={"fa fa-" + state.icon} color="primary" ></i>}>
              Icono
            </MButton>
             <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
              <div style={{maxWidth: '800px', maxHeigth: '300px'}}>
                <GridList cellHeight={60} cols={10}>
                    {icons.map((icon, index) => {
                      return (
                        <GridListTile key={index} cols={2} onClick={() => { handleChange('icon',icon); handleClose(); }}>
                          <i className={"fa fa-"+ icon + " fa-2x fa-border"} color="primary" ></i>
                        </GridListTile>
                      )
                      })
                    }
                </GridList>
              </div>
              </Popover>
          </Grid>
          <Grid item md={12}>
            <ColorPicker 
              label="Color de Fondo"
              color={state.backColor}
              onChange={ (color) => handleChange('backColor',color) } />
          </Grid>
          <Grid item md={12}>
            <ColorPicker
              label="Color de Fuente"
              color={state.fontColor}
              onChange={ (color) => handleChange('fontColor',color) } />
          </Grid>
          <Grid item md={12}>
            <ColorPicker
              label="Color de Icono"
              color={state.imageColor}
              onChange={ (color) => handleChange('imageColor',color) } />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" gutterBottom>
          Previsualizar
        </Typography>
        <Button
          variant="contained"
          style={{color: state.fontColor, backgroundColor: state.backColor}}
          startIcon={<i style={{ color: state.imageColor }} className={"fa fa-" + state.icon} ></i>}
        >
          {(title.length > 0 )? title : 'Encuesta sin nombre'}
        </Button>
      </Grid>
    </Grid>
  )
}

IconPicker.propTypes = {
  title: PropTypes.string,
  iconJson: PropTypes.any,
  onChange: PropTypes.func
}
