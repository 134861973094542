import React from "react";
import {makeStyles, Collapse, Tooltip} from '@material-ui/core';

import tooltipStyle from "../../assets/jss/material-dashboard-react/tooltipStyle";

const useStyles = makeStyles(tooltipStyle);
export default function Header() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div id="header">
      <div className="header py-2 border-light border-op-2 header-transparent header-dark">
        <div className="header-inner container">
          <div className="header-brand">
            <a className="header-brand-text" href="/" title="Home">
              <h1 className="h3 text-white font-weight-normal">
                Encuesta
              </h1>
            </a>
            <div className="header-divider d-none d-md-inline-block header-divider-sm"></div>
            <div className="header-slogan font-weight-bold text-letter-spacing text-uppercase">App Movil</div>
          </div>
          <div className="header-block d-flex order-12 align-items-center">
            <a href="/login" data-toggle="scroll-link" className="d-none d-block btn btn-primary btn-flat btn-sm btn-bold text-uppercase text-letter-spacing rounded-0 px-3 py-2">Iniciar Sesión</a>
          </div>
          <div className="navbar">
            <div className="navbar-main pos-fixed pos-zindex-fixed pos-t-10 pos-lg-vcenter pos-r py-2 px-3 bg-dark">
              <span onClick={handleExpandClick}> <i className="fa fa-bars text-white fa-lg"></i> </span>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className="collapse show" id="navbar-collapse">
                  <ul className="nav navbar-nav flex-column align-items-center">
                    <li className="nav-item"></li>
                    <li className="nav-item">
                      <Tooltip
                        title="Inicio"
                        placement="left"
                        classes={{tooltip:classes.tooltip}}>
                        <a href="/#highlighted" data-toggle="scroll-link" data-dimiss="jpanel-menu" data-scroll-link-nooffset={true} data-active-class="text-primary" className="nav-link text-capitalize"><i className="fa fa-dot-circle-o"></i></a>
                      </Tooltip>
                    </li>
                    <li className="nav-item">
                      <Tooltip
                        title="Características"
                        placement="left"
                        classes={{tooltip:classes.tooltip}}>
                        <a href="/#about" data-toggle="scroll-link" data-dimiss="jpanel-menu" data-scroll-link-nooffset={true} data-active-class="text-primary" className="nav-link text-capitalize"> <i className="fa fa-dot-circle-o" data-toggle="tooltip" title="About" data-placement="left"></i></a>
                      </Tooltip>
                    </li>
                    <li className="nav-item">
                      <Tooltip
                        title="Servicios"
                        placement="left"
                        classes={{tooltip:classes.tooltip}}>
                        <a href="/#services" data-toggle="scroll-link" data-dimiss="jpanel-menu" data-scroll-link-nooffset={true} data-active-class="text-primary" className="nav-link text-capitalize"> <i className="fa fa-dot-circle-o" data-toggle="tooltip" title="Services" ></i></a>
                      </Tooltip>
                    </li>
                    <li className="nav-item">
                      <Tooltip
                        title="Contacto"
                        placement="left"
                        classes={{tooltip:classes.tooltip}}>
                        <a href="/#contact" data-toggle="scroll-link" data-dimiss="jpanel-menu" data-scroll-link-nooffset={true} data-active-class="text-primary" className="nav-link text-capitalize"> <i className="fa fa-dot-circle-o" data-toggle="tooltip" ></i></a>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
