import Joi from '@hapi/joi';
const msg = {'string.empty': 'Este campo es obligatorio.'}

const attributes_create = {
  name: Joi.string().required().messages({...msg}),
  usersNumber: Joi.number().integer().min(1).messages({
    'number.base': 'Ingrese solo números enteros',
    'number.integer': 'Ingrese solo números enteros',
    'number.min': 'Ingrese números mayores a 0'
  }),
  validity: Joi.date().greater('now').messages({
    'date.base': 'Ingrese una fecha valida',
    'date.greater': 'La fecha debe ser mayor a la de hoy'
  })
}

const attributes_edit = {
  ...attributes_create
}
export const schema_create = Joi.object(attributes_create)
export const schema_edit = Joi.object(attributes_edit)