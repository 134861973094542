import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {LoginService} from '../Services/LoginService'

const PrivateRoute = ({component, ...rest}: any) => {
    const routeComponent = (props: any) => (
        LoginService.isAuthenticated()
            ? React.createElement(component, props)
            : <Redirect to="/login" />
    );
    return <Route {...rest} render={routeComponent}/>;
};

export default PrivateRoute