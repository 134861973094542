import React from "react"
import {TextField} from '@material-ui/core'
//import PropTypes from "prop-types"
import {useForm} from 'react-hook-form';

import Button from 'components/CustomButtons/Button';

export default function TLocation(props) {
  const { noQuestion, answer, setAnswer, required, handleNext} = props;
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (values) => {
    let coord_object = {
      coords: {
        latitude: '',
        longitude: ''
      }
    }
    setAnswer(noQuestion,[{selected: coord_object}])
    handleNext()
  }

  return (
    <React.Fragment>
      <form>
        <TextField
          variant="outlined"
          fullWidth
          type="text"
          name={`q_${noQuestion}`}
          error={errors[`q_${noQuestion}`] ? true : false}
          helperText={errors[`q_${noQuestion}`] ? 'Ingrese una respuesta' : ''}
          defaultValue={answer}
          inputRef={register({ required: required })}
        />
        <div >
          <div>
            <Button
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {'Siguiente Pregunta'}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
/*
TLocation.propTypes = {
  setAnswer: PropTypes.func,
};
*/

