export default class Role {
  id: number
  description: 'ADMON' | 'SUPER' | 'USER' | 'SUPERADMON'
  priority: number
  constructor(properties: any = {}){
    this.id = properties.id || 0
    this.description = properties.description || 'USER'
    this.priority = properties.priority || 1
   }
}
