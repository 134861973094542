import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  dangerColor,
  warningColor,
  whiteColor
} from "../../material-dashboard-react";

const chipStyle = {
  chip: {
    color: whiteColor
  },
  gray: {
    backgroundColor: grayColor[0]
  },
  success: {
    backgroundColor: successColor[0]
  },
  primary: {
    backgroundColor: primaryColor[2]
  },
  info: {
    backgroundColor: infoColor[0]
  },
  danger: {
    backgroundColor: dangerColor[2]
  },
  warning: {
    backgroundColor: warningColor[2]
  }
}

export default chipStyle