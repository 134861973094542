import React, {useState, useEffect, useRef } from 'react'
import {useParams} from 'react-router-dom'
import { Container, TableContainer, Table, TableHead, TableBody,
         TablePagination, TableRow, TableCell, IconButton } from '@material-ui/core'
import Backdrop from 'components/Backdrop/Backdrop'
import { Card, CardHeader, CardBody } from 'components/Card'
import Button from 'components/CustomButtons/Button'
import Dialog from 'components/Dialog/Dialog'
import Chip from 'components/Chip/Chip'
import Tooltip from 'components/Tooltip/Tooltip'
import {Send, Delete} from "@material-ui/icons"
import SurveyService from 'Services/SurveyService'
import SendEmailForm from './SendEmailForm'
import {notify} from 'components/Snackbar/Notifier'
import Error from '../Error/404'

// @ts-ignore
export default function () {
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(10)
  const [total, setTotal] = useState(0)
  const [surveyEmails, setSurveyEmails] = useState(new Array<any>(0))
  const {surveyId} = useParams()
  const [isLoading, setLoading] = useState(false);
  const initialDialog = {
    open: false,
    title: '',
    body: <div></div>
  }
  const [dialog, setDialog] = useState(initialDialog)
  const [membersEmail, setMembersEmail] = useState([])
  const [status, setStatus] = useState(200)
  const formRef = useRef<any>(null);

  useEffect(() => {
    getMembersEmail(surveyId)
  },[surveyId])

  useEffect(() => {
    getEmailsBySurvey(surveyId,page,rows)
  },[surveyId,page, rows])

  const handleOpenDialog = (dialogState: any) => {
    setDialog({open: true, ...dialogState})
  }

  const handleSubmitForm = () => {
    if(formRef && formRef !== null){
      formRef.current.formSubmit();
    }
  }
  async function getEmailsBySurvey(surveyId: number, page: number, rows: number) {
    setLoading(true)
    try {
      const response = await SurveyService.getSurveyEmails(surveyId,page,rows);
      setSurveyEmails(response.data)
      setPage(response.pagination.current_page)
      setTotal(response.pagination.total_elements)
    } catch (e) {
      setStatus(e.status)
    }
    setLoading(false)
  }

  async function getMembersEmail(surveyId: number) {
    try {
      const response = await SurveyService.getMembersEmail(surveyId)
      setMembersEmail(response.data)
    } catch (e) {
      setStatus(e.status)
    }
  }

  async function sendEmails(surveyId: number, message: string, emails: Array<string>){
    try{
      const response = await SurveyService.sendEmails(surveyId,message,emails)
      console.log(response)
      getMembersEmail(surveyId);
      setDialog(initialDialog)
      notify('success','Correos enviados con exito')
    }catch(e) {
      if(e.status !== 400) setStatus(e.status)
      else notify('danger', 'Error al envíar el correo electrónico')
    }
  }

  function userList(){
    return (
      <Container component="main" maxWidth="sm">
        <SendEmailForm
          ref={formRef}
          surveyId={surveyId}
          users={membersEmail}
          onSubmit={sendEmails}
        />
      </Container>
    )
  }

  if(status !== 200) return <Error status={status} url="/admin/campaigns" />

  return (
    <Card>
      {isLoading && <Backdrop open={isLoading} />}
        <CardHeader color="primary" stats>
          <h3>Encuesta</h3>
          <p>Lista de correos enviados</p>
        </CardHeader>
        <CardBody>
          <Dialog
            title={dialog.title}
            body={dialog.body}
            isOpen={dialog.open}
            onSubmit={handleSubmitForm}
            onClose={() => setDialog(initialDialog)}
          />
          <Button color="info" onClick={() => handleOpenDialog({title:'Enviar Encuesta por Correo',body: userList()})}>Enviar</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Correo</TableCell>
                  <TableCell>status</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {surveyEmails.map(survey_email => {
                  return <TableRow hover={true} key={survey_email.id}>
                           <TableCell>{survey_email.email}</TableCell>
                           <TableCell>
                            {survey_email.sended ? <Chip color="primary" label="Enviado" size="small" /> : <Chip color="danger" label="No Enviado" size="small" /> }
                            {survey_email.answered && <Chip color="success" label="Contestado" size="small" />}
                           </TableCell>
                           <TableCell>
                           {!survey_email.sended && (
                              <Tooltip title="Enviar">
                                <IconButton aria-label="Edit">
                                  <Send color="primary" style={{ fontSize: 18 }}/>
                                </IconButton>
                              </Tooltip>
                            )}
                              <Tooltip title="Eliminar">
                                <IconButton aria-label="Edit" >
                                  <Delete color="error" style={{ fontSize: 18 }} />
                                </IconButton>
                              </Tooltip>
                           </TableCell>
                         </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1)}
            page={page - 1}
            labelRowsPerPage={'Correos por pagina'}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value)}/>
        </CardBody>
      </Card>
  )
}