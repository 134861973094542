import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { format } from "date-fns";
import FileDownload from "js-file-download";
import { notify } from "components/Snackbar/Notifier";

import {
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  IconButton,
  Fab,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import {
  List,
  FilterList,
  TrendingUp,
  Public,
  Delete,
  GetApp,
  Description,
  BarChart,
  Close,
} from "@material-ui/icons";

import Confirm from "components/AlertDialog/AlertDialog";
import Dialog from "components/Dialog/Dialog";
import Backdrop from "components/Backdrop/Backdrop";

import { Card, CardHeader, CardBody } from "components/Card";
import Button from "components/CustomButtons/Button";
import Tooltip from "components/Tooltip/Tooltip";
import Chip from "components/Chip/Chip";

import AnswersService from "Services/AnswersService";
import SurveyService from "Services/SurveyService";

import Answer from "Class/Answer";
import Survey from "Class/Survey";
import Error from "../Error/404";

// @ts-ignore
export default function () {
  const history = useHistory();
  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  //filters
  const [filters, setFilters] = useState<{
    startDate: Date | null;
    endDate: Date | null;
    userId: number;
  }>({ startDate: null, endDate: null, userId: 0 });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [userId, setUserId] = useState(0);
  const [users, setUsers] = useState([{ id: 0, name: "Todos" }]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);

  const [answers, setAnswers] = useState(new Array<Answer>(0));
  const [survey, setSurvey] = useState(new Survey());
  const { surveyId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(100);
  const [anchorMenuCsv, setAnchorMenuCsv] = useState<null | HTMLElement>(null);
  const [anchorMenuFiles, setAnchorMenuFiles] = useState<null | HTMLElement>(
    null
  );

  const initialDialog = {
    open: false,
    title: "",
    body: <div></div>,
  };
  const [dialog, setDialog] = useState(initialDialog);
  const [answerId, setAnswerId] = useState(0);
  const [isAlertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await AnswersService.getUsers(surveyId);
        response.data.unshift({ id: 0, name: "Todos" });
        setUsers(response.data);
      } catch (e) {
        setStatus(e.status);
      }
    }
    async function getSurvey() {
      try {
        const response = await SurveyService.getSurvey(surveyId);
        setSurvey(response);
        getUsers();
        setStatus(200);
      } catch (e) {
        setStatus(e.status);
      }
    }
    getSurvey();
  }, [surveyId]);

  useEffect(() => {
    if (status === 200) getAnswers(surveyId, page, rows, filters);
  }, [status, surveyId, page, rows, filters]);

  const handleStartDate = (date: Date | null) => {
    console.log("filter");
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const handleUser = (event: any) => {
    setUserId(event.target.value);
  };

  const handleFilter = () => {
    setFilters({
      startDate: startDate ? startDate : new Date(2000, 1),
      endDate: endDate ? endDate : new Date(),
      userId,
    });
  };

  const handleResetFilter = () => {
    setUserId(0);
    setStartDate(null);
    setEndDate(null);
    setFilters({ startDate: null, endDate: null, userId: 0 });
  };

  const openImageModal = (file: string) => {
    let el_modal = modalRef.current;
    let el_modal_image = modalImageRef.current;

    if (
      el_modal &&
      el_modal !== null &&
      el_modal_image &&
      el_modal_image !== null
    ) {
      el_modal.style.display = "block";
      el_modal_image.style.backgroundImage = `url(${file})`;
    }
  };

  const closeModal = () => {
    let el_modal = modalRef.current;
    let el_modal_image = modalImageRef.current;

    if (
      el_modal &&
      el_modal !== null &&
      el_modal_image &&
      el_modal_image !== null
    ) {
      el_modal.style.display = "none";
      el_modal_image.style.backgroundImage = "none";
    }
  };

  const handleMenuClose = () => {
    setAnchorMenuCsv(null);
    setAnchorMenuFiles(null);
  };

  const gotoMap = () => {
    let optionsSurvey = {
      surveyId: surveyId,
      filters: {
        startDate:
          startDate !== null ? format(startDate, "yyyy-MM-dd") : startDate,
        endDate: endDate !== null ? format(endDate, "yyyy-MM-dd") : endDate,
        userId,
      },
    };
    history.push(`/admin/map/${JSON.stringify(optionsSurvey)}`);
  };

  async function getAnswers(
    surveyId: number,
    page: number,
    elements: number,
    filters: any
  ) {
    console.log("getting answers");
    console.log("filters", filters);
    setLoading(true);
    try {
      const response = await AnswersService.getAnswers(
        surveyId,
        page,
        elements,
        filters
      );
      const answers = response.data.map((item: any) => new Answer(item));
      setAnswers(answers);
      setPage(+response.pagination.current_page);
      setTotal(+response.pagination.total_elements);
    } catch (e) {
      setStatus(e.status);
    }
    setLoading(false);
  }
  async function deleteAnswer() {
    try {
      await AnswersService.deleteAnswer(answerId);
      setAnswerId(0);
      setAlertOpen(false);
      getAnswers(surveyId, page, rows, { startDate, endDate, userId });
      notify("success", "Respuesta eliminada con exito");
    } catch (e) {
      if (e.status) setStatus(e.status);
      else notify("danger", e.error);
    }
  }

  const handleMenuFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuFiles(event.currentTarget);
  };

  const handleMenuCsv = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (endDate || startDate || userId > 0)
      setAnchorMenuCsv(event.currentTarget);
    else exportCSV(false);
  };

  async function exportCSV(filters: boolean) {
    setLoading(true);
    try {
      handleMenuClose();
      const response = filters
        ? await SurveyService.exportCSV(surveyId, {
            startDate: startDate ? startDate : new Date(2000, 1),
            endDate: endDate ? endDate : new Date(),
            userId,
          })
        : await SurveyService.exportCSV(surveyId);
      FileDownload(response, survey.name.replace(/ /g, "_") + ".csv");
    } catch (e) {
      let utf8decoder = new TextDecoder();
      let error = JSON.parse(utf8decoder.decode(e));
      if (error.status !== 400) setStatus(error.status);
      else notify("danger", error.error);
    }
    setLoading(false);
  }

  async function download(filters: boolean) {
    setLoading(true);
    try {
      handleMenuClose();
      if (answers.length > 0 && survey.filesToDownload()) {
        const response = filters
          ? await SurveyService.download(surveyId, {
              startDate: startDate ? startDate : new Date(2000, 1),
              endDate: endDate ? endDate : new Date(),
              userId,
            })
          : await SurveyService.download(surveyId);
        FileDownload(response, survey.name.replace(/ /g, "_") + ".zip");
      } else
        notify(
          "warning",
          "Esta encuesta aun no cuenta con archivos para descargar."
        );
    } catch (e) {
      let utf8decoder = new TextDecoder();
      let error = JSON.parse(utf8decoder.decode(e));
      if (error.status !== 400) setStatus(error.status);
      else notify("danger", error.error);
    }
    setLoading(false);
  }

  async function showImage(path: string) {
    setLoading(true);
    try {
      const data = await AnswersService.getImage(path);
      let image = `data:image/jpeg;base64,${data}`;
      openImageModal(image);
    } catch (e) {
      if (e.status !== 400) setStatus(e.status);
      else notify("danger", e.error);
    }
    setLoading(false);
  }

  function generateHeaders() {
    return (
      <TableRow>
        {survey.headers.map((value: any, index: number) => {
          return <TableCell key={index}>{value.question}</TableCell>;
        })}
        <TableCell>Acciones</TableCell>
      </TableRow>
    );
  }

  function generateBody() {
    return answers.map((answer, index) => {
      return (
        <TableRow hover tabIndex={-1} key={index}>
          {survey.headers.map((item) => {
            const value = answer.answersJson[item.noQuestion];
            return (
              <TableCell key={item.noQuestion}>
                {value ? getValueAnswers(value) : ""}
              </TableCell>
            );
          })}
          <TableCell>
            <Tooltip title="Eliminar Respuesta">
              <IconButton
                onClick={() => {
                  setAnswerId(answer.id);
                  setAlertOpen(true);
                }}
              >
                <Delete color="error" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  }

  function getValueAnswers(value: any) {
    if (value.type === "location")
      return (
        <Button
          color="success"
          size="sm"
          onClick={() => history.push(value.link)}
        >
          {value.value}
        </Button>
      );
    if (value.type === "image")
      return (
        <Button color="success" size="sm" onClick={() => showImage(value.link)}>
          {value.value}
        </Button>
      );
    if (value.time) return <Chip color={value.color} label={value.time} />;
    return value;
  }

  if (![100, 200].includes(status))
    return <Error status={status} url={history} />;

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Dialog
        title={dialog.title}
        body={dialog.body}
        isOpen={dialog.open}
        onClose={() => setDialog(initialDialog)}
      />
      <Confirm
        open={isAlertOpen}
        title={"Eliminar Respuesta"}
        body={<p>¿Esta seguro de eliminar esta respuesta?</p>}
        onClose={() => {
          setAlertOpen(false);
        }}
        onSubmit={() => {
          deleteAnswer();
        }}
      />
      <Card>
        <CardHeader color="primary" stats>
          <h3>{survey?.name.toUpperCase()}</h3>
          <p>Lista de respuestas</p>
        </CardHeader>
        <CardBody>
          <Grid container spacing={2}>
            <Grid item md={2} xs={6}>
              <DatePicker
                fullWidth
                label="Fecha de inicio"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                name="startDate"
                value={startDate}
                onChange={handleStartDate}
                maxDate={endDate !== null ? endDate : new Date()}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <DatePicker
                fullWidth
                label="Fecha de Termino"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                name="endDate"
                value={endDate}
                onChange={handleEndDate}
                maxDate={new Date()}
                minDate={startDate}
              />
            </Grid>
            <Grid item md={3} xs={10}>
              <TextField
                id="filled-select-currency"
                fullWidth
                select
                label="Usuario"
                value={userId}
                onChange={handleUser}
                helperText="Seleccionar un usuario para filtro"
                variant="outlined"
              >
                {users.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={2} xs={2}>
              {(startDate || endDate || userId > 0) && (
                <Tooltip title="Eliminar Filtro">
                  <Fab className="m-1" size="small" onClick={handleResetFilter}>
                    <Close color="error" />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip title="Aplicar Filtro">
                <Fab size="small" color="primary" onClick={handleFilter}>
                  <FilterList />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item md={3} xs={12}>
              <Fab
                className="m-1"
                size="small"
                disabled={!(answers.length > 0)}
                color="secondary"
                onClick={gotoMap}
              >
                <Tooltip title="Ver Mapa">
                  <Public />
                </Tooltip>
              </Fab>
              <Fab
                className="m-1"
                size="small"
                disabled={!(answers.length > 0)}
                color="primary"
                onClick={() =>
                  history.push(`/admin/survey/${surveyId}/reports`)
                }
              >
                <Tooltip title="Reporte de Productividad">
                  <TrendingUp />
                </Tooltip>
              </Fab>
              <Fab
                className="m-1"
                size="small"
                disabled={!(answers.length > 0)}
                color="primary"
                onClick={() =>
                  history.push(`/admin/survey/${surveyId}/reports/day`)
                }
              >
                <Tooltip title="Reporte de Productividad por Día">
                  <BarChart />
                </Tooltip>
              </Fab>
              <Fab
                className="m-1"
                size="small"
                disabled={!(answers.length > 0)}
                color="secondary"
                onClick={handleMenuCsv}
              >
                <Tooltip title="Exportar como CSV">
                  <Description />
                </Tooltip>
              </Fab>
              <Menu
                anchorEl={anchorMenuCsv}
                keepMounted
                open={Boolean(anchorMenuCsv)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => exportCSV(false)}>
                  <ListItemIcon>
                    <List />
                  </ListItemIcon>
                  Todas las respuestas
                </MenuItem>
                <MenuItem onClick={() => exportCSV(true)}>
                  <ListItemIcon>
                    <FilterList />
                  </ListItemIcon>
                  Solo respuestas filtradas
                </MenuItem>
              </Menu>
              <Fab
                className="m-1"
                size="small"
                disabled={!(answers.length > 0 && survey.filesToDownload())}
                color="secondary"
                onClick={handleMenuFiles}
              >
                <Tooltip title="Descargar Archivos Adjuntos">
                  <GetApp />
                </Tooltip>
              </Fab>
              <Menu
                anchorEl={anchorMenuFiles}
                keepMounted
                open={Boolean(anchorMenuFiles)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => download(false)}>
                  <ListItemIcon>
                    <List />
                  </ListItemIcon>
                  Todos las archivos Adjuntos
                </MenuItem>
                {(endDate || startDate || userId > 0) && (
                  <MenuItem onClick={() => download(true)}>
                    <ListItemIcon>
                      <FilterList />
                    </ListItemIcon>
                    Solo Archivos de respuestas filtradas
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
          <TableContainer style={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>{generateHeaders()}</TableHead>
              <TableBody>{generateBody()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1)}
            page={page - 1}
            labelRowsPerPage={"Respuestas por pagina"}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value)}
          />
          <div className="modal" ref={modalRef}>
            <div className="overlay"></div>
            <Button
              className="close"
              justIcon
              round
              color="info"
              onClick={() => closeModal()}
            >
              <Close />
            </Button>
            <div className="modal-image" ref={modalImageRef}></div>
          </div>
        </CardBody>
      </Card>
    </Grid>
  );
}
