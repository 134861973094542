import React from 'react'

export default function(){
  return (
    <div id="services" className="py-4 pt-md-6 py-md-5 bg-primary-faded bg-op-1">
      <div className="container text-center w-100 w-lg-70 ">
        <h2 className="h1 font-weight-bold my-0 text-primary roboto-thin ">
          Servicios de la App
        </h2>
        <div className="row mt-3 mt-md-5 ">
          <div className=" col-md-4 pr-md-0 d-sm-flex align-content-center ">
            <div className="card border-0 w-100">
              <div className="px-4 py-4">
                <ion-icon name="people-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Productividad por usuario
                </h4>
                <p className="op-7">Recive graficas de la productividad diaria de encuestadores</p>
              </div>
            </div>
          </div>
          <div className=" col-md-4 d-sm-flex align-content-center ">
            <div className="card border-0 w-100">
              <div className="px-4 py-4">
                <ion-icon name="location-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Localización
                </h4>
                <p className="op-7">Observa en un mapa los lugares exactos en donde los cuestionarios han sido realizados</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 pl-md-0 d-sm-flex align-content-center ">
            <div className="card border-0 w-100">
              <div className="px-4 py-4">
                <ion-icon name="time-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Hora exacta
                </h4>
                <p className="op-7">Verifica la hora en la que el encuestador ha realizado cada cuestionario</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-4 pr-md-0 d-sm-flex align-content-center ">
            <div className="card border-0 w-100">
              <div className="px-4 py-4">
                <ion-icon name="cloud-download-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Descarga la información recabada
                </h4>
                <p className="op-7">Descarga el concentrado de la información en formato abierto</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-sm-flex align-content-center">
            <div className="card border-0 w-100">
              <div className="px-4 py-4">
                <ion-icon name="newspaper-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Crea nuevas Encuestas
                </h4>
                <p className="op-7">Realiza tantos cuestionarios como necesites</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 pl-md-0 d-sm-flex align-content-center">
            <div className="card border-0 w-100 ">
              <div className="px-4 py-4">
                <ion-icon name="phone-portrait-outline" class="icon-3x text-primary"></ion-icon>
                <h4 className="mt-2">
                  Disponible en tiendas
                </h4>
                <p className="mt-3 op-7 ">Los encuestadores podran descargar gratis la app en las tiendas de aplicación iOS y Android</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}