import User from 'Class/User'

export function isSuper(){
  const auth = localStorage.getItem("currentUser")
  if (auth){
    const currentUser = new User(JSON.parse(auth))
    return currentUser.isSuper()
  }
  return false
}

export function isSuperAdmin(){
  const auth = localStorage.getItem("currentUser")
  if (auth){
    const currentUser = new User(JSON.parse(auth))
    return currentUser.isSuperAdmin()
  }
  return false
}

export function currentUser() {
  let auth = localStorage.getItem("currentUser")
  if (auth){
    return new User(JSON.parse(auth))
  }
  return  null;
}