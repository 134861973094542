import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { IUser } from "Class/User";
import { isSuper, isSuperAdmin } from "Services/Auth";
import UserService from "Services/UserService";

type AdminFilterProps = {
  onChange: (id: number) => void;
  isUsersView: boolean;
};

const AdminFilter = ({ onChange, isUsersView }: AdminFilterProps) => {
  const [adminId, setAdminId] = useState(0);
  const [admins, setAdmins] = useState(
    isUsersView
      ? [
          { id: 0, name: "Todos los usuarios" },
          { id: -1, name: "Solo admins" },
        ]
      : [{ id: 0, name: "Todos los usuarios" }]
  );

  useEffect(() => {
    if (isSuperAdmin()) getAdmins();
  }, []);

  const getAdmins = async () => {
    try {
      const response = await UserService.getAdmins();
      const admins = response.map((admin: any) => {
        return { id: admin.id, name: admin.email };
      });
      admins.unshift({ id: 0, name: "Todos" });
      if (isUsersView) admins.unshift({ id: -1, name: "Solo admins" });

      setAdmins(admins);
    } catch (e) {
      console.log("Error getting admins: ", e);
    }
  };

  const handleChangeAdmin = (id: number) => {
    setAdminId(id);
    onChange(id);
  };

  return (
    <TextField
      fullWidth
      select
      label="Filtrar"
      value={adminId}
      onChange={(event) => handleChangeAdmin(+event.target.value)}
      variant="outlined"
    >
      {admins.map((admin) => (
        <MenuItem key={admin.id} value={admin.id}>
          {admin.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AdminFilter;
