import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
//const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

import { Card, CardHeader, CardBody }from 'components/Card'
import { Grid } from '@material-ui/core'
import Backdrop from 'components/Backdrop/Backdrop'
import Error from 'pages/Error/404'

import AnswersService from 'Services/AnswersService'
import {AnswerLocation} from 'Class/Answer'

const CustomSkinMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.center}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "off" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "off" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "off" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }]
          }
        ]
      }}
    >

      {props.markers.map((answer, index) => {
        return (
          <Marker key={index} position={{ lat: parseFloat(answer.lat), lng: parseFloat(answer.lng) }} title={answer.userName} onClick={() => props.handleToggleOpen(index)} >
            {
            (props.isOpen === index) &&
             <InfoWindow onCloseClick={props.handleInfoClose}>
                 <span>{answer.userName}</span>
             </InfoWindow>
            }
          </Marker>
        )})
      }
    </GoogleMap>
  ))
);

export default function Maps() {
  const { options } = useParams()

  const [markers, setMarkers] = useState([])
  const [marker, setMarker] = useState(null)
  const [center, setCenter] = useState({ lat: 17.059013, lng:  -96.727092 })
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(200)
  useEffect(() => {
    function buildMarkers(){
      if(options){
        const jsonOptions = JSON.parse(options);
        if(jsonOptions.lat && jsonOptions.lng){
          setMarkers([new AnswerLocation(jsonOptions)]);
          setCenter({ lat: parseFloat(jsonOptions.lat), lng:  parseFloat(jsonOptions.lng) })
        }else{
          getMarkers(jsonOptions.surveyId, jsonOptions.filters)
        }
      }
    }
    buildMarkers()
  },[options])

  const handleToggleOpen = (markerId) => {
    setMarker(markerId)
  }
  const handleInfoClose = () => {
    setMarker(null)
  }

  async function getMarkers(surveyId,filters){
    setLoading(true)
    try{
      const response = await AnswersService.getMarkers(surveyId,filters)
      let answersMarkers = response.data.map(answer => new AnswerLocation(answer))
      if(answersMarkers.length > 0){
        setMarkers(answersMarkers)
        setCenter({ lat: parseFloat(answersMarkers[0].lat), lng:  parseFloat(answersMarkers[0].lng) })
      }
    } catch (e) {
      setStatus(e.status)
    }
    setLoading(false)
  }

  if(status !== 200) return <Error status={status} />

  return (
    <Grid>
      {isLoading && <Backdrop open={isLoading} />}
      <Card>
        <CardHeader color="primary" stats>
          <h3>Mapa de Respuestas</h3>
          <p>Total de Respuestas: {markers.length}</p>
        </CardHeader>
        <CardBody>
          <CustomSkinMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBoktBhWGLw64r3A5N6pImj3sG0aoCjI-w"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            markers={markers}
            handleToggleOpen={handleToggleOpen}
            handleInfoClose={handleInfoClose}
            isOpen={marker}
            center={center}
          />
        </CardBody>
      </Card>
    </Grid>
    
  );
  
  
}
