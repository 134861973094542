export interface  ICompany {
  id: number,
  name: string
}

export default class Company {
  id: number
  name: string
  usersNumber: number
  validity: Date | null
  blocked: boolean
  constructor(properties: any = {}){
    this.id = properties.id || 0
    this.name = properties.name || ''
    this.blocked = properties.blocked || false
    this.usersNumber = properties.usersNumber || 0
    this.validity = properties.validity || null
  }
  asJSON(){
    return {
      name: this.name,
      usersNumber: this.usersNumber,
      validity: this.validity
    }
  }
  minValidity(){
    let currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 1)
    return currentDate
  }
}

export const CompanyHeaders = [
  {field: 'name', title: 'Nombre de Campaña'},
  {field: 'id', title: 'Acciones'}
]