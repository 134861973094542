export default class Coupon {
  id: number
  code: string
  activations: number
  validity: Date | null
  usersNumber: number
  cost: number = 0
  constructor(properties: any = {}){
    this.id = properties.id || 0
    this.code = properties.code?.toUpperCase() || ''
    this.activations = properties.activations || 0
    this.usersNumber = properties.usersNumber || 1
    this.validity = properties.validity || null
    if(properties.cost) {
      let parseCost = properties.cost;
      if(typeof(parseCost) === 'string')
        parseCost = parseCost.replace(',','').replace('$','');
      this.cost = parseFloat( parseCost ); 
    }
  }
  asJSON(){
    return {
      code: this.code.toUpperCase(),
      activations: this.activations,
      usersNumber: this.usersNumber,
      validity: this.validity,
      cost: this.cost
    }
  }
  minValidity(){
    let currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 1)
    return currentDate
  }
}