import Joi from '@hapi/joi';
const msg = {'string.empty': 'Este campo es obligatorio.'}

const attributes_create = {
	companyName: Joi.string().max(140).required().messages({...msg}),
  firstName: Joi.string().required().messages({...msg}),
  lastName: Joi.string().required().messages({...msg}),
  motherLastName: Joi.string().empty(''),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false }}).required().messages({
    ...msg,
    'string.email': 'Correo electrónico inválido'
  }),
  password: Joi.string().min(6).max(140).messages({
    ...msg,
    'string.min': 'La contraseña debe contener mínimo 6 caracteres.',
    'string.max': 'La contraseña debe contener máximo 140 caracteres.',
  }),
  confirmPassword: Joi.ref('password'),
  usersNumber: Joi.number().integer().min(1).messages({
    'number.base': 'Ingrese solo números enteros',
    'number.integer': 'Ingrese solo números enteros',
    'number.min': 'Ingrese números mayores a 0' 
  })
}

export const schema_create = Joi.object(attributes_create)