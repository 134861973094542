import React,{useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import { joiResolver } from "@hookform/resolvers";

import { useHistory } from "react-router-dom";
import { format, add } from 'date-fns'

import PaypalButton from 'components/Paypal/PaypalButton'
import Notifier, {notify} from 'components/Snackbar/Notifier';

import Container from '@material-ui/core/Container';
import {Grid, TextField, MenuItem} from '@material-ui/core'

import Button from 'components/CustomButtons/Button'
import { Card, CardHeader, CardBody, CardFooter } from 'components/Card'
import CouponPreview from 'components/Coupons/CouponPreview'

import Header from 'components/Public/Header'
import Footer from 'components/Public/Footer'
import CouponService from 'Services/CouponService'
import {LoginService} from 'Services/LoginService'
import CompanyService from 'Services/CompanyService'
import UserService from 'Services/UserService'
import Coupon from 'Class/Coupon'
import {schema_create} from 'Validations/Register'

// @ts-ignore
export default function Register() {

  const history = useHistory();
  const { register, errors, setError, handleSubmit } = useForm({
    resolver: joiResolver(schema_create)
  });
  const formRef = useRef<HTMLFormElement>(null);

  const [showPaypal, setShowPaypal] = useState(false)
  const [totalAmount, setTotalAmount] = useState(200)
  const [month, setMonth] = useState(1)
  const [coupon, setCoupon] = useState(new Coupon())
  const [couponCode, setCouponCode] = useState('')
  const [couponError, setCouponError] = useState('')
  const [dataRegister, setDataRegister] = useState<any>({})
  const [usersNumber, setUsersNumber] = useState(1)
  const months = [1,2,3,4,5,6,7,8,9,10,11,12];

  const onFormSubmit = async (values: any) => {
    try {
      const dataCompany = await CompanyService.getCompanyByName(values.companyName);
      if(dataCompany.company) {
        setError('companyName',{message: 'Este nombre ya se encuentra registrado', type: 'string.empty'});
        return;
      }
      const dataEmail = await UserService.getUserByEmail(values.email);
      if(dataEmail.user) {
        setError('email', {message: 'Este correo ya existe', type: 'string.email'});
        return;
      }
      let company = {
        name: values.companyName,
        usersNumber: (coupon.id > 0) ? coupon.usersNumber : values.usersNumber,
        validity: (coupon.id > 0) ? coupon.validity : getValidity(true)
      }
      let user = {
        firstName: values.firstName,
        lastName: values.lastName,
        motherLastName: values.motherLastName,
        email: values.email,
        password: values.password,
      }
      let jsonRegister = {}
      if(coupon.id > 0) jsonRegister = {company, user, couponId: coupon.id}
      else jsonRegister = {company, user}

      setDataRegister(jsonRegister)
      setShowPaypal(true)
    } catch(e) {
      console.log('error: ', e);
    }
  };

  const onSubmit = () => {
    let el = formRef.current;
    if(el && el !== null){
      el.dispatchEvent(new Event('submit', { cancelable: true }))
    }
  }

  const handleChangeMonth = (value: number) => {
    setMonth(value)
    setTotalAmount(value * usersNumber * 200)
  }
  const handleChangeUsers = (value: number) => {
    setUsersNumber(value)
    setTotalAmount(value  * month * 200)
  }

  async function handleGetCoupon() {
    try {
      if(couponCode.trim().length > 0) {
        const response = await CouponService.searchCoupon(couponCode.toUpperCase())
        setCouponError('')
        setUsersNumber(response.usersNumber)
        setCoupon(response)
        setTotalAmount(response.cost)
      }
    } catch (e) {
      if(e.status === 400) setCouponError(e.error)
      setCouponCode('')
    }
  }

  const handleResetCoupon = () => {
    setCoupon(new Coupon())
    setCouponCode('')
    setTotalAmount(200)
    setUsersNumber(1)
    setMonth(1)
  }

  const getValidity = (forSave = false) => {
    let today = add(new Date(),{months: month})
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);

    return forSave ? today : format(today, 'dd/MM/yyyy')
  }

  const handleRegister = async (approved: boolean, orderId: string) => {
    if(approved){
      try {
        let dataSend = {...dataRegister, orderId: orderId};
        await LoginService.signOut(dataSend);
        if(dataSend.user){
          await LoginService.signIn(dataSend.user.email, dataSend.user.password)
          notify('success', 'Su registro se realizó con exito')
          history.push("/admin");
        }
      }catch (e) {
        notify('danger', 'Ocurrio un error al realizar el registro')
      }
    }else {
      notify('danger','Ocurrio un error al procesar el pago, intente de nuevo')
    }
  }

  return (
    <React.Fragment>
    <Notifier />
    <Header />
    <div className="pt-6 bg-black bg-op-3"></div>
    <div className="pt-2 pb-md-2 bg-primary-faded bg-op-1">
      <Container maxWidth="md">
        <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)}>
        <Card>
          <CardHeader>
            <h2 className="text-primary">Regístrate</h2>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="companyName"
                  label="Nombre de la Empresa o Razón Social"
                  inputRef={register}
                  placeholder="Ingrese el nombre de la empresa o razón social"
                  helperText={errors.companyName?.message || ' '}
                  error={errors.companyName ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="firstName"
                  label="Nombre(s)"
                  inputRef={register}
                  helperText={errors.firstName?.message || ' '}
                  error={errors.firstName ? true : false}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="lastName"
                  label="Apellido Paterno"
                  inputRef={register}
                  helperText={errors.lastName?.message || ' '}
                  error={errors.lastName ? true : false}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="motherLastName"
                  label="Apellido Materno"
                  inputRef={register}
                  helperText={errors.motherLastName?.message || ' '}
                  error={errors.motherLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="email"
                  label="Correo Electrónico"
                  autoComplete="email"
                  inputRef={register}
                  helperText={errors.email?.message || ' '}
                  error={errors.email ? true : false}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="password"
                  name="password"
                  label="Contraseña"
                  autoComplete="password"
                  inputRef={register}
                  helperText={errors.password?.message || ' '}
                  error={errors.password}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  label="Confirmar Contraseña"
                  autoComplete="password"
                  inputRef={register}
                  helperText={errors.confirmPassword ? 'Las constraseñas no coinciden' : ' ' }
                  error={errors.confirmPassword}
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Grid container spacing={3} alignItems="center">
              <Grid item container spacing={3} xs={12}>
                <Grid item md={6} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    label="Código de cupón"
                    value={couponCode}
                    onChange={(event) => setCouponCode(event.target.value) }
                    inputProps={{className: "uppercase"}}
                    error={(couponError.length > 0)}
                    helperText={couponError}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  {coupon.id > 0 
                    ? (<Button color="danger" onClick={handleResetCoupon}>Cancelar Cupón</Button>) 
                    : (<Button color="primary" disabled={!(couponCode.trim().length > 0)} onClick={handleGetCoupon}>Aplicar Cupón</Button>)
                  }
                </Grid>
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  inputProps={{readOnly: (coupon.id > 0)}}
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="usersNumber"
                  label="Número de Usuarios"
                  value={usersNumber}
                  onChange={(event) => handleChangeUsers(+event.target.value)}
                  inputRef={register}
                  placeholder="Ingrese la Cantidad de usuarios"
                  helperText={errors.usersNumber?.message}
                  error={errors.usersNumber}
                />
              </Grid>
              {(coupon.id > 0)
                ? (<CouponPreview coupon={coupon}/>)
                : (
                   <React.Fragment>
                    <Grid item md={3} xs={6}>
                      <TextField
                        variant="outlined"
                        select
                        fullWidth
                        name="monthsNumber"
                        label="Cantidad de Meses"
                        value={month}
                        inputRef={register}
                        placeholder="Ingrese la cantidad de meses"
                        onChange={(event) => handleChangeMonth(+event.target.value)}>
                        {months.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        inputProps={{readOnly: true}}
                        variant="outlined"
                        fullWidth
                        type="text"
                        label="Vigencia"
                        value={getValidity()}
                        placeholder="Vigencia"
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        inputProps={{readOnly: true}}
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="cost"
                        label="Costo"
                        value={totalAmount}
                        placeholder="Costo total"
                      />
                    </Grid>
                   </React.Fragment>
                   )
                }
            </Grid>
          </CardBody>
        </Card>
        </form>
        <Card>
          <CardBody>
            {showPaypal && (
              <Grid container spacing={3} justify="center">
                <Grid item md={6}>
                  <PaypalButton amount={totalAmount} onRegister={handleRegister}/>
                </Grid>
              </Grid>
            )}
          </CardBody>
          <CardFooter>
            <Grid container justify="flex-end">
              {!showPaypal && (
                <Button
                  color="info"
                  type="submit"
                  onClick={onSubmit}>
                    Confirmar
                </Button>
                )}
              <Button onClick={() => setShowPaypal(false)}>
                Cancelar
              </Button>
            </Grid>
          </CardFooter>
        </Card>
      </Container>
    </div>
    <Footer />
    </React.Fragment>
  );
}