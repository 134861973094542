import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9c27b0',
    },
    secondary: {
      main: '#00ADBB',
      contrastText: '#fff'
    },
    neutral: {
      main: '#5c6ac4',
    },
    text: {
      secondary: {
        main: '#fff'
      }
    }
  },
});

export default Theme;