import React, {useState} from "react"
import {SketchPicker} from 'react-color'
import {TextField, InputAdornment} from '@material-ui/core'
import ColorLens from '@material-ui/icons/ColorLens'

export default function(props) {
  const {label, color, onChange } = props; 
  const [open, setOpen] = useState(false);

  function handleChangeColor(color){
    onChange(color.hex);
  }
  function handleClose(){
    setOpen(false)
  }

  return (
    <React.Fragment>
      <TextField
        variant="outlined"
        label={label}
        value={color}
        onChange={(event) => onChange(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ColorLens
                style={{color: color}}
                onClick={() => setOpen(true)} />
            </InputAdornment>
          )
        }}
        />
      {open && (
        <div style={{ position: 'absolute', zIndex: '10'}} >
          <div style={{ position: 'fixed',top: '0px',right: '0px',bottom: '0px',left: '0px', }} onClick={handleClose}/>
          <SketchPicker
            color={color}
            onChange={handleChangeColor}
          />
        </div>
      )}
    </React.Fragment>
  )
}