import React from "react";
import {Grid, TextField} from '@material-ui/core'
import { format } from 'date-fns'

export default function CouponPreview(props) {

  function getMonths(validity){
    let end_date = new Date(validity)
    let start_date = new Date()
    let total_months = (end_date.getFullYear() - start_date.getFullYear())*12 + (end_date.getMonth() - start_date.getMonth())
    return total_months;
  }

  return (
    <>
      <Grid item md={3}>
        <TextField
          inputProps={{readOnly: true}}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Número de Meses"
          value={getMonths(props.coupon.validity)}
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Vigencia"
          value={format(new Date(props.coupon.validity), 'dd/MM/yyyy')}
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Costo"
          value={props.coupon.cost}
        />
      </Grid>
    </>
  )
}