import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import tooltipStyle from "assets/jss/material-dashboard-react/tooltipStyle.js";

const useStyles = makeStyles(tooltipStyle);
export default function CustomTooltip(props) {
  const { placement, ...rest } = props;
  const classes = useStyles();
  return (
    <Tooltip placement={placement} classes={classes} {...rest} />
  );
}

CustomTooltip.defaultProps = {
  placement: 'top'
};
