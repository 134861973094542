import React, {useState, useEffect, createRef } from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import { Grid, TextField } from '@material-ui/core'
import { Card, CardHeader, CardBody } from 'components/Card'
import Backdrop from 'components/Backdrop/Backdrop'
import QuestionsFactory from 'components/SurveyComponents/Factory'
import IconPicker from 'components/IconPicker/IconPicker'
import Button from 'components/CustomButtons/Button.js'
import {notify} from 'components/Snackbar/Notifier'
import Survey from 'Class/Survey'
import Resource from 'Class/Resource'
import SurveyService from 'Services/SurveyService'
import ResourceService from 'Services/ResourceService'
import { joiResolver } from '@hookform/resolvers'
import {schema_create} from 'Validations/Surveys'
import Error from '../Error/404'
// @ts-ignore
export default function () {
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema_create),
  });
  const [status, setStatus] = useState(200)
  const [loading, setLoading] = useState(false)
  const formSurvey = createRef<HTMLFormElement>();
  const { surveyId } = useParams()
  const { campaignId } = useParams()
  const [survey, setSurvey] = useState(new Survey())
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [version, setVersion] = useState(1)
  const [iconJson, setIconJson] = useState(survey.surveyJson)
  const [questions, setQuestions] = useState(new Array<any>(0))
  const [noQuestions, setNoQuestions] = useState(new Array<number>(0))
  const [campaign, setCampaignId] = useState(campaignId)
  const [questionsFilter, setQuestionsFilter] = useState(new Array<number>(0))
  const [images, setImages] = useState(new Array<Resource>(0))
  const [videos, setVideos] = useState(new Array<Resource>(0))

  useEffect(() => {
    if(survey.id) {
      setQuestions(survey.questionsJson)
      let newNoQuestions = new Array<number>(0);
      let newQuestionsFilter = new Array<number>(0);
      survey.questionsJson.forEach((item) => {
        newNoQuestions.push(item.noQuestion);
        if(item.type === 'TOptionFilter') newQuestionsFilter.push(item.noQuestion);
      })
      setNoQuestions(newNoQuestions)
      setQuestionsFilter(newQuestionsFilter)
      setIconJson(survey.surveyJson)
      setName(survey.name)
      setDescription(survey.description)
      setVersion(survey.version)
    }
    if(survey.campaignId !== null) setCampaignId(survey.campaignId)
  },[survey])

  useEffect(() => {
    const getSurvey = async (surveyId: number) => {
      setLoading(true)
      try {
        const response = await SurveyService.getSurvey(surveyId)
        setSurvey(response)
        if (response.campaignId) await getResources(+response.campaignId)
      } catch (e) {
        setStatus(e.status)
      }
      setLoading(false)
    }
    if(campaignId)
      getResources(campaignId)
    else{
      getSurvey(surveyId)
    }
  }, [surveyId, campaignId])

  useEffect(() => {
    let filters = questions.filter((item: any) => item.type === 'TRadioButton')
                          .map((item: any) => item.noQuestion)
    setQuestionsFilter(filters)
  },[questions])

  function handleChangeIcon(iconJson: any) {
    setIconJson(iconJson);
  }

  function moveQuestion(id: number, move: number) {
    let newQuestions = [...questions]
    let newNoQuestions = new Array<number>(0);
    let indexQuestionA = id - 1 ;
    let indexQuestionB = move - 1;
    let a = newQuestions[indexQuestionA]; //el que se mueve
    newQuestions.splice(indexQuestionA,1)
    newQuestions.splice(indexQuestionB,0,a)

    newQuestions.forEach((item, index) => {
      item.id = index + 1;
      item.noQuestion = index + 1;
      newNoQuestions.push(item.noQuestion);
    });

    newQuestions.forEach((item, index) => {
      item.answers.map((answer: any) => {
        if(answer.next){
          if(answer.next === id) answer.next = move;
          else if(answer.next === move) answer.next = id;
          if(answer.next <= item.id) delete answer.next;
        }
        return answer;
      })
      if(item.predecessor){
        if(item.predecessor === id) item.predecessor = move;
        else if(item.predecessor === move) item.predecessor = id;
        if(item.predecessor >= item.id) {
          item.predecessor = '';
          item.answers.map((answer: any) => {
            answer.predecessor = '';
            return answer;
          })
        }
      }
    });
    setNoQuestions(newNoQuestions);
    setQuestions(newQuestions);
  }
  function removeQuestion(id: number) {
    let newQuestions = [...questions];
    let newNoQuestions = new Array<number>(0);
    let indexQuestion = id - 1 ;

    newQuestions.forEach((item, index) => {
      if(index < indexQuestion) {
        item.answers.map((answer: any) => {
          if(answer.next){
            if(answer.next === id) delete answer.next
            if(answer.next > id) answer.next -= 1
          }
          return answer;
        })
      }
      if(index > indexQuestion && item.predecessor) {
        item.predecessor = ''
        item.answers.map((answer: any) => {
          answer.predecessor = '';
          return answer;
        })
      }
    });
    newQuestions.splice(indexQuestion,1)
    
    newQuestions.forEach((item, index) => {
      item.id = index + 1;
      item.noQuestion = index + 1;
      newNoQuestions.push(item.noQuestion);
    });
    setNoQuestions(newNoQuestions);
    setQuestions(newQuestions);
  }

  const addQuestion = (questionJson: any) => {
    if(!questionJson.id) {
      questionJson['id'] = questions.length + 1;
      questionJson['required'] = true;
      questionJson['noQuestion'] = questions.length + 1;
      questionJson['answers'] = [{selected: ''}];
    }
    setQuestions([...questions, questionJson]);
    setNoQuestions([...noQuestions, questionJson.noQuestion]);
  }
  const onFormSubmit = (values: any) => {
    if(survey.id === 0) survey.campaignId = +campaignId
    survey.name = name
    survey.description = description
    survey.version = version
    survey.surveyJson = iconJson
    survey.questionsJson = questions
    saveSurvey(survey);
  };

  async function getResources(campaignId: number) {
    try {
      const response_images = await ResourceService.listAll(campaignId, 'image')
      const response_videos = await ResourceService.listAll(campaignId, 'video')
      setImages(response_images.data)
      setVideos(response_videos.data)
    } catch(e) {
      setStatus(e.status)
    }
  }

  async function saveSurvey(survey: Survey) {
    setLoading(true)
    try {
      await SurveyService.saveSurvey(survey,campaignId);
      notify('success', 'Encuesta guardada con exito')
      history.push(`/admin/campaigns/${campaign}`)
    } catch (e) {
      if(e.detail) notify('danger', e.detail)
      else notify('danger', e.error)
    }
  setLoading(false)
  }

  const getOptionsFilter = (id: number) => {
    let question = questions[id - 1];
    return question.answers.map((item: any) => item.option)
  }

  const updateJson = (id: number, value: any) => {
    let newQuestions = [...questions]
    let question = newQuestions[id - 1];
    if(value.type && (value.type !== 'TVideo' || value.type !== 'TImage')){
      delete question['source']
    }
    newQuestions[id - 1] = Object.assign(question, value)
    setQuestions(newQuestions)
  }

  if(status !== 200) return <Error status={status} />

  return (
    <Card>
      <CardHeader color="primary">
        <h3>{`${(survey.id === 0) ? 'Nueva' : 'Editando'} Encuesta`}</h3>
      </CardHeader>
      <CardBody>
        {loading && <Backdrop open={loading} />}
        <form ref={formSurvey} onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                name="name"
                label="Nombre de la Encuesta"
                value={name}
                inputRef={register({ required: true })}
                onChange={(event) => setName(event.target.value)}
                error={errors.name ? true : false}
                helperText={errors.name?.message}
                />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                type="text"
                name="description"
                label="Descripción"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="number"
                name="version"
                label="Versión"
                value={version}
                inputRef={register({ required: true })}
                error={errors.version ? true : false}
                helperText={errors.version?.message}
                />
            </Grid>
            <Grid item md={12}>
              <IconPicker 
                title={name}
                iconJson={iconJson}
                onChange={handleChangeIcon}/>
            </Grid>
            <Grid item md={12}>
              {questions.map((questionJson, index) => {
                let tFilters = questionsFilter.filter(item => item < questionJson.id)
                return (
                  <QuestionsFactory 
                    key={index}
                    videos={videos}
                    images={images}
                    questionJson={questionJson}
                    noQuestions={noQuestions}
                    questionsFilter={tFilters}
                    getOptionsFilter={getOptionsFilter}
                    removeQuestion={removeQuestion}
                    moveQuestion={moveQuestion}
                    updateJson={updateJson}/>)
              })}
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item >
              <Button 
                color="primary" 
                onClick={() => addQuestion({type: 'TTextField', question: '', instructions: ''})} >
                Agregar pregunta
              </Button>
            </Grid>
            <Grid item >
              <Button
                color="info"
                type="submit"
                onClick={() => {
                  let el = formSurvey.current;
                  if(el && el !== null)
                    el.dispatchEvent(new Event('submit', { cancelable: true }))
                  }}>
                  Guardar
              </Button>
              <Button onClick={() => history.push(`/admin/campaigns/${campaign}`)}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};
