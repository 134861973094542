import Company from './Company'
import User from './User'

export default class Payment {
  id: number
  dataPaypal: any
  amount: number = 0
  status: string
  createdAt: Date
  company: Company
  user: User | null = null
  orderId: number | null
  constructor(properties: any = {}){
    this.id = properties.id || 0
    this.dataPaypal = properties.dataPaypal || {}
    this.status = properties.status || ''
    this.createdAt = properties.createdAt || new Date()
    this.company = properties.company || new Company()
    if(properties.user !== null){
      this.user =  new User(properties.user)
    }
    if(properties.amount){
      let parseAmount = properties.amount.replace(',','').replace('$','').replace(/\s/g, '');
      this.amount = parseFloat( parseAmount );
    }
    this.orderId = properties.orderId || null
  }
  asJSON(){
    return {
      company: this.company,
      orderId: this.orderId
    }
  }
}