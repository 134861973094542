import React,{useEffect, useState} from "react"
import PropTypes from "prop-types"
import {FormControl, FormHelperText} from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Button from 'components/CustomButtons/Button';

export default function TImageOption(props) {
  const { noQuestion, answers, setAnswers, required, handleNext} = props;
  const [error, setError] = useState('')
  const [selected, setSelected] = useState('')

  useEffect(() => {
    let s = answers.filter(answer => answer.selected)
    if(s.length > 0) setSelected(s[0].inciso)
  }, [answers])

  const handleChange = () => {
    let newAnswers = [];
    answers.forEach(answer => {
      if(answer.inciso === selected){
        newAnswers.push({inciso: answer.inciso, selected: true})
      }else{
        newAnswers.push({inciso: answer.inciso, selected: false})
      }
    })
    if(required && selected === '') {
      setError('Seleccione una respuesta');
      return;
    }
    setAnswers(noQuestion, newAnswers)
    handleNext()
  }

  const handleChangeImage = (value) => {
    setSelected(value)
  }

  return (
    <React.Fragment>
      <div>
        <FormControl error={(error !== '')}>
          <GridList cellHeight={180}>
            {answers.map((answer) => (
              <GridListTile key={answer.inciso} onClick={() => handleChangeImage(answer.inciso)}>
                <img src={answer.option} alt={answer.inciso} />
                <GridListTileBar
                  title={answer.inciso}
                  actionIcon={
                    <IconButton >
                      {answer.inciso === selected && <CheckCircle color="error" />}
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      </div>
      <div >
        <div>
          <Button
            color="primary"
            onClick={handleChange}
          >
            {'Siguiente Pregunta'}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

TImageOption.propTypes = {
  noQuestion: PropTypes.number,
  answers: PropTypes.array,
  setAnswers: PropTypes.func,
  required: PropTypes.bool,
  handleNext: PropTypes.func
};


