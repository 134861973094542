import React from "react"
import {TextField} from '@material-ui/core'
//import PropTypes from "prop-types"

export default function TTextField(props) {
  const { answers, required, register, errors} = props;

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      name="answer"
      error={errors.answer ? true : false}
      helperText={errors.answer ? 'Ingrese una respuesta' : '' }
      defaultValue={answers[0].selected}
      inputRef={register({ required: required })}
    />
  );
}
/*
TTextField.propTypes = {
  setAnswer: PropTypes.func,
};
*/

