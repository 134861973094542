import React from "react"
import {TextField} from '@material-ui/core'
//import PropTypes from "prop-types"
import Joi from '@hapi/joi';

let number_schema;

export default function TNumber(props) {
  const { answers, required, register, errors} = props;
  
  number_schema = Joi.object({
    answer: required ? Joi.number().required().messages({
      'number.base': 'Ingrese un número valido',
      'number.unsafe': 'Ingrese un número valido',
    }) : Joi.number().allow('') ,
  });

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      name="answer"
      error={errors.answer ? true : false}
      helperText={errors.answer?.message}
      defaultValue={answers[0].selected}
      inputRef={register}
    />
  );
}

export function numberSchema() {
  return number_schema;
}
/*
TNumber.propTypes = {
  setAnswer: PropTypes.func,
};
*/

