import React, {useEffect, useState} from 'react'
import { Grid, GridList, GridListTile, Checkbox,
        TextField, MenuItem, TablePagination, IconButton } from "@material-ui/core";
import { DeleteForever } from '@material-ui/icons'
import {notify} from 'components/Snackbar/Notifier'
import Dropzone from 'components/Dropzone/Dropzone'
import { Card, CardHeader, CardBody } from 'components/Card'
import Confirm from 'components/AlertDialog/AlertDialog'
import Backdrop from 'components/Backdrop/Backdrop'
import Tooltip from 'components/Tooltip/Tooltip'
import ResourceService from 'Services/ResourceService'
import CompanyService from 'Services/CompanyService'
import {isSuper} from 'Services/Auth'
import Resource from "Class/Resource"
import Company from 'Class/Company'
import Error from '../Error/404'

// @ts-ignore
export default function () {
  const [companies, setCompanies] = useState(new Array<Company>(0))
  const [companyId, setCompanyId] = useState<number | string>("")
  const [resources, setResources] = useState(new Array<Resource>(0))
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(10)
  const [checked, setChecked] = useState(new Array<number>(0))
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(200)

  const initialConfirm = {
    open: false,
    title:'',
    body:'',
    action: () =>{}
  }
  const [confirm,setConfirm] = useState(initialConfirm)

  useEffect(() => {
    if(isSuper()) getCompanies()
  }, [])

  useEffect(() => {
    if(isSuper()){
      if(typeof(companyId) !== 'string') getResources(companyId, page, rows)
      setChecked([])
    }else{
      getResources(0,page,rows)
    }
  }, [companyId, page, rows])

  function handleOpenConfirm(confirmState: any){
    setConfirm({open: true,...confirmState})
  }

  async function getCompanies() {
    try {
      const response = await CompanyService.listAllCompanies(0,0)
      setCompanies(response.data)
    } catch (e) {
      setStatus(e.status)
    }
  }

  async function getResources(companyId: number, page: number, elements: number) {
    setLoading(true)
    try{
      const response = await ResourceService.list(page,elements,companyId);
      setResources(response.data)
      setPage(+response.pagination.current_page)
      setTotal(+response.pagination.total_elements)
    }catch(e){
      setStatus(e.status)
    }
    setLoading(false)
  }

  function handleFinishUpload(status: boolean){
    if(isSuper() && status && typeof(companyId) !== 'string')
      getResources(companyId, page, rows)
    else if(status) 
      getResources(0, page, rows)
  }

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const deleteFiles = async() => {
    try{
      const response = await ResourceService.deleteResources(checked)
      setConfirm(initialConfirm)
      handleFinishUpload(true)
      setChecked([])
      notify('success',response.data)
    }catch(e){
      if(e.status !== 400) setStatus(e.status)
      else notify('danger',e.error)
    }
  }

  if(status !== 200) return <Error status={status} />

  return (
    <Grid container>
      {isLoading && <Backdrop open={isLoading} />}
      <Card>
        <CardHeader color="primary" stats>
          <h3>Biblioteca de Recursos</h3>
          <p></p>
        </CardHeader>
        <CardBody>
          <Grid container spacing={2}>
          {isSuper() && (
            <Grid item md={12}>
              <TextField
                fullWidth
                select
                label="Empresa"
                value={companyId}
                onChange={(event) => setCompanyId(+event.target.value)}
                helperText="Seleccionar una Empresa"
                variant="outlined"
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
            <Grid item md={12} >
              <Dropzone companyId={companyId} onFinish={handleFinishUpload} />
            </Grid>
          </Grid>
        </CardBody>
      </Card>
      <Card>
        <CardHeader color="primary" stats>
          <h4>Archivos</h4><p></p>
        </CardHeader>
        <CardBody>
          <Confirm
            open={confirm.open}
            title={confirm.title}
            body={<p>{confirm.body}</p>}
            onClose={() => setConfirm(initialConfirm) }
            onSubmit={confirm.action}
          />
          <Grid container justify="space-between">
            <Grid item ></Grid>
            <Grid item >
            {checked.length > 0 && (
              <Tooltip title="Eliminar Archivos" >
                <IconButton onClick={() => handleOpenConfirm({title: "Eliminar Archivos",
                                                 body: `¿Esta seguro de eliminar ${checked.length > 1 ? "los Archivos seleccionados" : "el archivo seleccionado" }?`,
                                                 action: () => deleteFiles()})  }>
                  <DeleteForever color="error"/>
                </IconButton>
              </Tooltip>
            )}
            </Grid>
          </Grid>
          <GridList cellHeight={200} cols={3}>
            {resources.map((resource, index) => (
              <GridListTile key={resource.id} cols={1}>
                <Tooltip title="Seleccionar archivo" >
                  <Checkbox
                    onChange={handleToggle(resource.id)}
                    checked={checked.indexOf(resource.id) !== -1}
                    color="secondary"
                  />
                </Tooltip>
              {resource.resource_type.includes('image') && 
                ( <img style={{width: '100%', height: '100%'}} src={resource.source} alt={resource.name} />)
              }
              {resource.resource_type.includes('video') && 
                (
                 <video controls style={{width: '100%', height: '100%'}}>
                  <source src={resource.source} type={resource.resource_type} />
                  Tu navegador no implementa el elemento <code>video</code>.
                </video>
                )
              }
              </GridListTile>
            ))}
          </GridList>
          <TablePagination
            count={total}
            component="div"
            onChangePage={(event, value) => setPage(value + 1)}
            page={page - 1}
            labelRowsPerPage={'Archivos por página'}
            rowsPerPage={rows}
            onChangeRowsPerPage={(event) => setRows(+event.target.value) }/>
        </CardBody>
      </Card>
    </Grid>
  )
}
